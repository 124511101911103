import React, { Component } from "react"
import { connect } from "react-redux"
import {
  logScreenEnterance,
  openAndListenToSpecificLockerLock,
  LogoutUser,
} from "../../../../actions"
import { FixedFooter, FixedHeader, LockerCells } from "../../../reduxified"
import "./style.css"

class UnlockLockersScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isClickWaiting: false,
    }
  }

  componentDidMount() {
    const { authenticated } = this.props
    if (!authenticated) {
      this.props.LogoutUser()
      this.props.history.push("")
    }
    this.props.logScreenEnterance("DRIVER_UNLOCK_LOCKERS_SCREEN")
  }

  onCancelClick() {
    this.props.history.goBack()
  }

  checkIsWaiting() {
    if (this.state.isCellClickWaiting) {
      console.log("Check click - isWaiting")
      return true
    }
    console.log("Check click  - GO")
    return false
  }

  isWaiting(num, wait) {
    if (wait && this.state.isCellClickWaiting) {
      console.log("return - isWaiting")
      return true
    }

    let { timeO } = this.state

    timeO = wait
      ? setTimeout((num) => {
          console.log("Start timeout function!")
          this.isWaiting(num, false)
        }, 2000)
      : clearTimeout(timeO)

    this.setState({
      ...this.state,
      isCellClickWaiting: wait,
      timeO: timeO,
    })
    console.log(wait ? num + " click is disabled " : "  Click released")

    return wait
  }

  onCellClick(cellNum, lockerData) {
    if (lockerData == undefined) {
      return
    }

    const { order, disabled } = lockerData

    if (disabled) {
      return
    }
    const { role } = this.props

    if (
      order != null &&
      (order.orderStatus === 0 || order.orderStatus === 13) &&
      role !== "Manager"
    ) {
      console.log(
        "driver is not supposed to pickup an order from unlock lockers screen."
      )
      return
    }

    if (order != null && order.orderStatus === 4 && role === "DHL") {
      console.log(
        "DHL driver is not supposed to pickup an order from unlock lockers screen."
      )
      return
    }

    if (this.checkIsWaiting()) return

    this.props.openAndListenToSpecificLockerLock(cellNum)

    this.isWaiting(cellNum, true)
  }

  render() {
    const {
      blinkers,
      errLockers,
      driver_menu_lockers_cells_opening,
      driver_lockers_cells_opening_subtitle,
    } = this.props

    return (
      <div className="ls-wrapper">
        <FixedHeader buttonsFlag="unlockLockersScreen" />
        <div className="unlock-screen-title">
          {driver_menu_lockers_cells_opening}
        </div>
        <div className="unlock-screen-subtitle">
          {driver_lockers_cells_opening_subtitle}
        </div>

        <div className="ddocs-inner-wrapper">
          <LockerCells
            onCellClick={this.onCellClick.bind(this)}
            blinkers={blinkers}
            errLockers={errLockers}
          />
        </div>

        <FixedFooter history={this.props.history} />
      </div>
    )
  }
}

const mapStateToProps = ({ userLogin, lockersCells, translate }) => {
  const { authenticated, role } = userLogin
  const { openedLockersArr, errorLockersArr } = lockersCells
  const {
    driver_menu_lockers_cells_opening,
    driver_lockers_cells_opening_subtitle,
  } = translate
  const blinkers = {}
  openedLockersArr.map((lockNum) => (blinkers[lockNum] = lockNum))
  const errLockers = {}
  errorLockersArr.map((lockNum) => (errLockers[lockNum] = lockNum))

  return {
    authenticated,
    role,
    blinkers,
    errLockers,

    driver_menu_lockers_cells_opening,
    driver_lockers_cells_opening_subtitle,
  }
}

export default connect(mapStateToProps, {
  openAndListenToSpecificLockerLock,
  logScreenEnterance,
  LogoutUser,
})(UnlockLockersScreen)
