import React, { Component } from "react"
import { Provider } from "react-redux"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"

import Store from "./store"

import AutoLogouter from "./components/AutoLogouter"
import { Environment, AppVersion, initAxiosLogger, isProdEnv } from "./utils"

import ServiceMenuAppQRScreen from "./components/screens/UserScreens/ServiceMenuAppQRScreen"
//import ServiceMenuScreen from "./components/screens/UserScreens/ServiceMenuScreen"
import LoginScreen from "./components/screens/UserScreens/LoginScreen"
import SignUpStep1Screen from "./components/screens/UserScreens/SignUpScreens/Step1Screen"
import SignUpStep2Screen from "./components/screens/UserScreens/SignUpScreens/Step2Screen"
import SignUpStep3Screen from "./components/screens/UserScreens/SignUpScreens/Step3Screen"
import UserMenuScreen from "./components/screens/UserScreens/UserMenuScreen"
import NewOrderScreen from "./components/screens/UserScreens/NewOrderScreen"
import NewPurchaseScreen from "./components/screens/UserScreens/NewPurchaseScreen"
import OrderItemsDepositScreen from "./components/screens/UserScreens/OrderItemsDepositScreen"
import CollectOrderScreen from "./components/screens/UserScreens/CollectOrderScreen"
import ReviewOrderList from "./components/screens/UserScreens/ReviewOrderList"
import DriverMenuScreen from "./components/screens/DriverScreens/DriverMenuScreen"
import UnlockLockersScreen from "./components/screens/DriverScreens/UnlockLockersScreen"
import CollectOrdersScreen from "./components/screens/DriverScreens/CollectOrdersScreen"
import LoadCleanOrdersScreen from "./components/screens/DriverScreens/LoadCleanOrdersScreen"
import ThankYouScreen from "./components/screens/UserScreens/ThankYouScreen"
import RecreatePasswordScreen from "./components/screens/UserScreens/RecreatePasswordScreen"
import MaintenanceScreen from "./components/screens/MaintenanceScreen"
import SmsLoginScreen from "./components/screens/UserScreens/SmsLoginScreen"
import AboutRental from "./components/screens/UserScreens/AboutRental"
import RestoreDeliveryCodeScreen from "./components/screens/UserScreens/RestoreDeliveryCodeScreen"
import UserLoginScreen from "./components/screens/UserScreens/UserLoginScreen"
import SimpleServiceMenuScreen from "./components/screens/UserScreens/SimpleServiceMenuScreen"
import CompleteUserDetails from "./components/screens/UserScreens/RegistrationScreens/CompleteUserDetails"
import PasswordUpdateScreen from "./components/screens/UserScreens/RegistrationScreens/PasswordUpdateScreen"
import RentLockerMenuScreen from "./components/screens/UserScreens/RentLockerMenuScreen"
import RentLockerScreen from "./components/screens/UserScreens/RentLockerScreen"
import LockerToLockerDeliveryScreen from "./components/screens/UserScreens/LockerToLockerDeliveryScreen"
import LockerToLockerDeliveryInfo from "./components/screens/UserScreens/LockerToLockerDeliveryScreen/LockerToLockerDeliveryInfo"
import AddresseeDetails from "./components/screens/UserScreens/LockerToLockerDeliveryScreen/AddresseeDetails"
import SelectStation from "./components/screens/UserScreens/LockerToLockerDeliveryScreen/SelectStation"
import PayOrder from "./components/screens/UserScreens/LockerToLockerDeliveryScreen/PayOrder"
import SelectLocker from "./components/screens/UserScreens/LockerToLockerDeliveryScreen/SelectLocker"
import UserLoginWithDoneLoginScreen from "./components/screens/UserScreens/UserLoginWithDoneLoginScreen"
import DoneLoginScreen from "./components/screens/UserScreens/DoneLoginScreen"
import LockerToLockerDeliveryMenu from "./components/screens/UserScreens/LockerToLockerDeliveryScreen/LockerToLockerDeliveryMenu"

import Modal from "react-modal"

import beep from "./assets/audio/beep.mp3"

import "./App.css"
import PingAPI from "./components/PingAPI"

Modal.setAppElement("#root")
class App extends Component {
  constructor(props) {
    super(props)

    this.autoLogouterTrigger = null
  }
  // async pingAPI() {}

  componentWillMount() {
    // document.oncontextmenu = (ev => ev.preventDefault()); // uncomment to prevent mouse right click!
    // TODO - STOPPED HERE - BG OFFLINE SYNC - UNCCOMENT TO CONTINUE..! ::
    initAxiosLogger()
  }

  componentDidMount() {
    document.addEventListener("click", () => {
      if (isProdEnv()) {
        new Audio(beep).play() //! UNCOMMENT TO GET SOUND!
      }
      this.autoLogouterTrigger(new Date().getMilliseconds())
    })
  }

  triggerCallbackSetter(triggerFunc, cntx) {
    this.autoLogouterTrigger = (clickTime) => triggerFunc(cntx, clickTime)
  }

  render() {
    return (
      <Provider store={Store}>
        <Router>
          <PingAPI />
          <AutoLogouter
            triggerCallbackSetter={(func, cntx) =>
              this.triggerCallbackSetter(func, cntx)
            }
          />
          <Switch>
            <Route path="/" exact component={ServiceMenuAppQRScreen} />
            <Route
              path="/ServiceMenuAppQRScreen"
              component={ServiceMenuAppQRScreen}
            />
            <Route path="/UserMenuScreen" component={UserMenuScreen} />
            <Route
              path="/SimpleServiceMenuScreen"
              component={SimpleServiceMenuScreen}
            />
            <Route
              path="/RentLockerMenuScreen"
              component={RentLockerMenuScreen}
            />
            <Route path="/RentLockerScreen" component={RentLockerScreen} />
            <Route
              path="/LockerToLockerDeliveryScreen"
              component={LockerToLockerDeliveryScreen}
            >
              <Route
                path="/LockerToLockerDeliveryScreen/Info"
                component={LockerToLockerDeliveryInfo}
              />
              <Route
                path="/LockerToLockerDeliveryScreen/SelectStation"
                component={SelectStation}
              />
              <Route
                path="/LockerToLockerDeliveryScreen/AddresseeDetails"
                component={AddresseeDetails}
              />
              <Route
                path="/LockerToLockerDeliveryScreen/PayOrder"
                component={PayOrder}
              />
              <Route
                path="/LockerToLockerDeliveryScreen/SelectLocker"
                component={SelectLocker}
              />
              <Route
                path="/LockerToLockerDeliveryScreen/Menu"
                component={LockerToLockerDeliveryMenu}
              />
              
            </Route>
            <Route path="/SignUpStep1Screen" component={SignUpStep1Screen} />
            <Route path="/SignUpStep2Screen" component={SignUpStep2Screen} />
            <Route path="/SignUpStep3Screen" component={SignUpStep3Screen} />
            <Route path="/NewOrderScreen" component={NewOrderScreen} />
            <Route path="/NewPurchaseScreen" component={NewPurchaseScreen} />
            <Route path="/AboutRental" component={AboutRental} />
            <Route
              path="/OrderItemsDepositScreen"
              component={OrderItemsDepositScreen}
            />
            <Route path="/CollectOrderScreen" component={CollectOrderScreen} />
            <Route path="/DriverMenuScreen" component={DriverMenuScreen} />
            <Route
              path="/UnlockLockersScreen"
              component={UnlockLockersScreen}
            />
            <Route
              path="/CollectOrdersScreen"
              component={CollectOrdersScreen}
            />
            <Route
              path="/LoadCleanOrdersScreen"
              component={LoadCleanOrdersScreen}
            />
            <Route path="/ReviewOrderList" component={ReviewOrderList} />
            <Route path="/ThankYouScreen" component={ThankYouScreen} />
            <Route
              path="/RecreatePasswordScreen"
              component={RecreatePasswordScreen}
            />
            <Route path="/SmsLoginScreen" component={SmsLoginScreen} />
            <Route
              path="/RestoreDeliveryCodeScreen"
              component={RestoreDeliveryCodeScreen}
            />
            <Route path="/LoginScreen" component={LoginScreen} />
            <Route path="/UserLoginScreen" component={UserLoginScreen} />
            <Route
              path="/UserLoginWithDoneLoginScreen"
              component={UserLoginWithDoneLoginScreen}
            />
            <Route path="/DoneLoginScreen" component={DoneLoginScreen} />
            <Route
              path="/CompleteUserDetails"
              component={CompleteUserDetails}
            />
            <Route
              path="/PasswordUpdateScreen"
              component={PasswordUpdateScreen}
            />
            <Route path="/MaintenanceScreen" component={MaintenanceScreen} />
          </Switch>
        </Router>
      </Provider>
    )
  }
}

export default App
