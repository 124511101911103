import React from "react"

import { OrangeButton } from "../OrangeButton"
import SuccessFailBtn from "./SuccessFailBtn"
import PendingBtn from "./PendingBtn"
import error from "../../../assets/svg/error-sign.svg"
import success from "../../../assets/svg/success.svg"
import "./style.css"
import Loader from "../Loader"

// STATUS -> -1 => ERROR, 0 => PENDING, 1 => OK

const PendingSuccessFailBtn = ({ status, onBtnClick }) => {
  switch (status) {
    case -1:
      return <img src={error} alt="_X_" />
    case 0:
      return <Loader />
    case 1:
      return <img src={success} alt="_V_" />
    case 2:
      return (
        <OrangeButton
          extraClass="pensuccfail-btn-extra"
          onClick={() => onBtnClick()}
        >
          אישור
        </OrangeButton>
      )
    default:
      return <Loader />
  }
}

export { PendingSuccessFailBtn }
