import React, { Component } from "react"
import { connect } from "react-redux"
import {
  createNewOrder,
  findNextAvailableCell,
  LogoutUser,
  logScreenEnterance,
  openAndListenToSpecificLockerLock,
  reportSuspiciousBrokenLockers,
  setThankYouSubTitle,
  confirmCreateOrder,
} from "../../../../actions"
import ErrorInfoModal from "../../../Modals/ErrorInfoModal"
import LoadingInfoModal from "../../../Modals/LoadingInfoModal"
import WaitingForLockerLock from "../../../Modals/WaitingForLockerLock"
import { FixedHeader, LockerCells } from "../../../reduxified"
import { getOrderItems } from "../../../../utils/OrderConvert"
import "./style.css"

class OrderItemsDepositScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showWaitingForLockModal: false,
      showDoorOpenErrorModal: false,
      suspiciousLockersArr: [],
    }
  }

  componentDidMount() {
    let { lockerNum } = this.props

    this.openAndListenToLocker(lockerNum)

    this.props.logScreenEnterance("USER_ORDER_ITEM_DEPOSIT_SCREEN")
  }

  async componentWillReceiveProps(newProps) {
    const {
      openingErrorOccurred,
      openAttemptsNum,
      newOrderItems,
      nextLockerId,
      lockerNum,
      openingDoorSuccess,
      nextAvailableCellIndex,
      doorWasClosed,
      userId,
      stationId,
      couponId,
      orderForConfirmCreate,
      order_was_received,
      //newCreatedOrder,
    } = newProps

    const rentLocker = this.props.history.location.state === "rentLocker"
    if (!lockerNum) {
      this.setState({ showDoorOpenErrorModal: true })
      this.reportAboutSuspiciousBrokenLockers()
      return
    }

    if (doorWasClosed) {
      this.props.setThankYouSubTitle(order_was_received)
      this.props.history.push("ThankYouScreen")
      return
    }

    if (openingDoorSuccess && rentLocker) {
      console.log("create RENT order")
      await this.props.createNewOrder(
        this.props.lockersData[lockerNum - 1].lockerId,
        userId,
        stationId,
        [
          {
            amount: 1,
            icon: "Locker rental",
          },
        ],
        "",
        4
      )
      this.reportAboutSuspiciousBrokenLockers()
      return
    }

    if (openingDoorSuccess && orderForConfirmCreate) {
      console.log("create LtoL order")
      orderForConfirmCreate.locationId = nextLockerId
      orderForConfirmCreate.couponId = couponId
      orderForConfirmCreate.orderItems = getOrderItems(newOrderItems)

      await this.props.confirmCreateOrder(orderForConfirmCreate)
      this.reportAboutSuspiciousBrokenLockers()
      return
    }

    if (openingDoorSuccess && !rentLocker && !orderForConfirmCreate) {
      console.log("create LAUNDRY order")
      await this.props.createNewOrder(
        nextLockerId,
        userId,
        stationId,
        newOrderItems,
        couponId
      )
      this.reportAboutSuspiciousBrokenLockers()
      return
    }

    if (
      openingErrorOccurred ||
      nextAvailableCellIndex !== this.props.nextAvailableCellIndex
    ) {
      if (openAttemptsNum < 4) {
        this.openAndListenToLocker(lockerNum, 5)
      } else if (rentLocker) {
        //TODO open error modal
        console.log("LOCKER STUCK")
      } else {
        this.props.findNextAvailableCell()
        this.addSuspiciousBrokenLocker(lockerNum)
      }
    }
  }

  openAndListenToLocker(lockerNum, attempts) {
    this.props.openAndListenToSpecificLockerLock(lockerNum, attempts)
  }

  openWaitingForLockModal() {
    this.setState({ showWaitingForLockModal: true })
  }

  closeWaitingForLockModal() {
    this.setState({ showWaitingForLockModal: false })
  }

  onDoorOpenErrModalClose() {
    const { see_you_again } = this.props

    this.props.setThankYouSubTitle(see_you_again)
    this.props.history.push("ThankYouScreen")
  }

  reportAboutSuspiciousBrokenLockers() {
    const { stationId } = this.props
    const { suspiciousLockersArr } = this.state

    if (suspiciousLockersArr.length > 0) {
      this.props.reportSuspiciousBrokenLockers(stationId, suspiciousLockersArr)
    }
  }

  addSuspiciousBrokenLocker(lockerNum) {
    const { suspiciousLockersArr } = this.state

    this.setState({
      suspiciousLockersArr: [...suspiciousLockersArr, lockerNum],
    })
  }

  render() {
    const {
      openAttemptsNum,
      openingDoorSuccess,
      dirRtl,
      opening_free_locker_title,
      please_wait,
      close_text,
      deposit_order_insturct_1,
      deposit_order_insturct_2,
      deposit_order_insturct_3,
      error_while_opening_door_modal_title,
      error_while_opening_door_modal_body,
      waiting_for_locker_lock_modal_title,
      waiting_for_locker_lock_modal_body,
    } = this.props
    let { lockerNum } = this.props
    const { showWaitingForLockModal, showDoorOpenErrorModal } = this.state

    return (
      <div className="ls-wrapper">
        <FixedHeader />

        <div
          className="oids-inner-wrapper"
          onClick={this.openWaitingForLockModal.bind(this)}
        >
          <LockerCells hideInfo blinkers={{ [lockerNum]: lockerNum }} />

          <div className="oids-text-instructions-wrapper">
            {deposit_order_insturct_1}
            <br />
            {deposit_order_insturct_2.replace("{lockerNum}", lockerNum)}
            <br />
            {deposit_order_insturct_3}
          </div>
        </div>

        <LoadingInfoModal
          dirRtl={dirRtl}
          show={
            openAttemptsNum > 0 &&
            !showDoorOpenErrorModal &&
            !openingDoorSuccess
          }
          title={opening_free_locker_title}
          text={please_wait}
        />

        <ErrorInfoModal
          dirRtl={dirRtl}
          show={showDoorOpenErrorModal}
          title={error_while_opening_door_modal_title}
          text={error_while_opening_door_modal_body}
          onModalCloseClick={() => this.onDoorOpenErrModalClose()}
          closeTrnsl={close_text}
        />

        <WaitingForLockerLock
          dirRtl={dirRtl}
          title={waiting_for_locker_lock_modal_title}
          subTitle={waiting_for_locker_lock_modal_body}
          show={showWaitingForLockModal}
          onCloseClicked={() => this.closeWaitingForLockModal()}
        />
      </div>
    )
  }
}

const mapStateToProps = ({
  lockersCells,
  readyOrders,
  userLogin,
  bgService,
  translate,
}) => {
  const {
    lockersData,
    nextAllAvailableCells,
    openedLockersArr,
    errorLockersArr,
    openingAttempsMap,
    nextAvailableCellNumber,
    nextAvailableCellIndex,
    recentClosedLockersArr,
    chosenCell,
  } = lockersCells

  const { userId } = userLogin
  const { stationId } = bgService

  const {
    newCreatedOrderItems: newOrderItems,
    newCreatedOrderCoupon: couponId,
    orderForConfirmCreate,
  } = readyOrders

  const lockerNum = chosenCell || nextAvailableCellNumber

  const openingDoorSuccess = openedLockersArr.includes(lockerNum)
  const openingErrorOccurred = errorLockersArr.includes(lockerNum)
  const doorWasClosed = recentClosedLockersArr.includes(lockerNum)

  const openAttemptsNum = openingAttempsMap[lockerNum] || 0

  const blinkers = !openingErrorOccurred ? { [lockerNum]: lockerNum } : {}
  const errLockers = openingErrorOccurred ? { [lockerNum]: lockerNum } : {}
  //Exeption on the line
  //const nextLocker = nextAllAvailableCells[nextAvailableCellIndex] || undefined;

  const nextLocker = nextAllAvailableCells
    ? nextAllAvailableCells[nextAvailableCellIndex] || undefined
    : undefined
  const nextLockerId = nextLocker ? nextLocker.lockerId : undefined

  const {
    dirRtl,
    opening_free_locker_title,
    please_wait,
    order_was_received,
    see_you_again,
    error_while_opening_door_modal_title,
    error_while_opening_door_modal_body,
    deposit_order_insturct_1,
    deposit_order_insturct_2,
    deposit_order_insturct_3,
    waiting_for_locker_lock_modal_title,
    waiting_for_locker_lock_modal_body,
    close_text,
  } = translate

  return {
    lockersData,
    userId,
    stationId,
    couponId,
    nextLockerId,
    openingDoorSuccess,
    openingErrorOccurred,
    doorWasClosed,
    newOrderItems,
    openAttemptsNum,
    lockerNum,
    nextAvailableCellIndex,
    dirRtl,
    opening_free_locker_title,
    please_wait,
    order_was_received,
    close_text,
    see_you_again,
    error_while_opening_door_modal_title,
    error_while_opening_door_modal_body,
    deposit_order_insturct_1,
    deposit_order_insturct_2,
    deposit_order_insturct_3,
    waiting_for_locker_lock_modal_title,
    waiting_for_locker_lock_modal_body,
    orderForConfirmCreate,
  }
}

export default connect(mapStateToProps, {
  openAndListenToSpecificLockerLock,
  findNextAvailableCell,
  createNewOrder,
  LogoutUser,
  setThankYouSubTitle,
  reportSuspiciousBrokenLockers,
  logScreenEnterance,
  confirmCreateOrder,
})(OrderItemsDepositScreen)
