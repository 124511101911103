import React from "react"
import Modal from "react-modal"

import "./style.css"
import Loader from "../../common/Loader"

const LoadingInfoModal = ({ show, title, text, onModalCloseClick, dirRtl }) => {
  const wrapperClassName = `${dirRtl ? "dirrtl" : "dirltr"}`

  return (
    <Modal
      isOpen={show}
      onRequestClose={onModalCloseClick}
      className={`react-modal-content ${wrapperClassName}`}
      overlayClassName="modal-overlay"
    >
      <div
        extraClass="loadinfmodal-label-extra-class"
        dir={dirRtl ? "rtl" : "ltr"}
      >
        {title}
      </div>
      <div
        extraClass="loadinfmodal-label-extra-class loadinfmodal-label-extra-class2"
        dir={dirRtl ? "rtl" : "ltr"}
      >
        {text}
      </div>
      <Loader />
    </Modal>
  )
}

export default LoadingInfoModal
