import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { FixedFooter, FixedHeader, LockerCells } from "../../../../reduxified"
import ErrorInfoModal from "../../../../Modals/ErrorInfoModal"
import LoadingInfoModal from "../../../../Modals/LoadingInfoModal"
import WaitingForLockerLock from "../../../../Modals/WaitingForLockerLock"
import {
  LogoutUser,
  reportFBEvent,
  logScreenEnterance,
  setLockerToLockerDeliveryOrder,
  confirmCreateOrder,
  openAndListenToSpecificLockerLock,
  resetLockersErrorsAttemptsOpened,
  setThankYouSubTitle,
  getOrderForPay,
  setLockerToLockerDeliveryOrderLoading,
} from "../../../../../actions"
import "./style.css"

class SelectLocker extends Component {
  constructor(props) {
    super(props)
    this.INIT_STATE = {
      isClickWaiting: false,
      showYesNoInfoModal: false,
      isOpenedDoor: false,
    }
    this.state = this.INIT_STATE
  }

  componentDidMount() {
    const { authenticated } = this.props
    if (!authenticated) {
      this.props.LogoutUser()
      this.props.history.push("")
    }
    this.props.logScreenEnterance("LOCKER_TO_LOCKER_DELIVERY SELECT_LOCKER")
  }

  componentWillReceiveProps(newProps) {
    const { authenticated } = newProps

    if (!authenticated) {
      //add report
      this.props.reportFBEvent(
        "LOCKER_TO_LOCKER SELECT_LOCKER",
        "Not authenticated. Redirect to homepage."
      )
      this.props.LogoutUser()
      this.props.history.push("")
    }

    const {
      orderWasLoaded,
      openingErrorOccurred,
      openingDoorSuccess,
      openAttemptsNum,
      lockerNum,
      see_you_again,
      payedOrder,
      createdOrder,
      isLoading,
      isOrderConfirmed,
    } = newProps

    if (payedOrder == null && !isOrderConfirmed) {
      this.props.reportFBEvent(
        "LockerToLocker SelectLocker",
        "The payedOrder is null. "
      )
      if (createdOrder) {
        this.props.getOrderForPay(createdOrder.orderId)
      }
      return
    }

    if (openingDoorSuccess) {
      if (this.state.isOpenedDoor) {
        return
      }

      console.log("Locker " + payedOrder.lockerNum + " is opened.")
      this.setState({ isOpenedDoor: true })
      this.props.setLockerToLockerDeliveryOrderLoading()
      this.props.confirmCreateOrder(payedOrder)
      return
    }

    if (orderWasLoaded) {
      console.log("Locker " + payedOrder.lockerNum + "is closed.")
      this.props.setThankYouSubTitle(see_you_again)
      this.props.history.push("/ThankYouScreen")
      return
    }

    if (openingErrorOccurred && !this.state.isOpenedDoor) {
      console.log("openingErrorOccurred - ", openingErrorOccurred, "!!!")
      this.setState({ showDoorOpenErrorModal: true })
    }
  }

  checkIsWaiting() {
    if (this.state.isCellClickWaiting) {
      console.log("Check click - isWaiting")
      return true
    }
    console.log("Check click  - GO")
    return false
  }

  isWaiting(num, wait) {
    if (wait && this.state.isCellClickWaiting) {
      console.log("return - isWaiting")
      return true
    }

    let { timeO } = this.state

    timeO = wait
      ? setTimeout((num) => {
          console.log("Start timeout function!")
          this.isWaiting(num, false)
        }, 2000)
      : clearTimeout(timeO)

    this.setState({
      ...this.state,
      isCellClickWaiting: wait,
      timeO: timeO,
    })

    console.log(wait ? num + " click is disabled " : "  Click released")

    return wait
  }

  onCellClick(cellNum, lockerData) {
    if (lockerData == undefined) {
      return
    }

    const { disabled } = lockerData
    if (disabled) {
      return
    }
    const { isLoading, isOrderConfirmed } = this.props
    if (isLoading || isOrderConfirmed) {
      return
    }

    if (this.checkIsWaiting()) return

    let { payedOrder, createdOrder } = this.props
    if (!payedOrder) {
      this.props.reportFBEvent(
        "LockerToLocker SelectLocker",
        "The payedOrder is null. "
      )
      if (!createdOrder) {
        this.props.reportFBEvent(
          "LockerToLocker SelectLocker",
          "The payedOrder and createdOrder is null. Redirect homepage."
        )
        this.props.LogoutUser()
        this.props.history.push("")
      }
      return
    }
    payedOrder.lockerNum = cellNum
    payedOrder.locationId = lockerData.lockerId

    this.props.setLockerToLockerDeliveryOrder(payedOrder)
    this.props.openAndListenToSpecificLockerLock(cellNum)
    this.isWaiting(cellNum, true)
  }

  onDoorOpenErrModalClose() {
    this.setState({
      showDoorOpenErrorModal: false,
      clickedLocker: null,
      isCellClickWaiting: false,
    })
    this.props.resetLockersErrorsAttemptsOpened()
  }

  render() {
    const {
      blinkers,
      errLockers,
      opening_locker_number,
      waitingForLockerLockTitle,
      pls_close_door,
      err_while_opening_door,
      try_another_locker_or_contact_support,
      close_text,
      please_wait,
      openingDoorSuccess,
      openAttemptsNum,
      dirRtl,
      payedOrder,
    } = this.props

    const { showDoorOpenErrorModal } = this.state

    let { lockerNum } = 11111
    if (
      payedOrder &&
      payedOrder.lockerNum != undefined &&
      payedOrder.lockerNum != null
    ) {
      lockerNum = payedOrder.lockerNum
    }

    console.log("lockerNum:" + lockerNum)

    return (
      <>
        <div className="ls-wrapper ">
          <FixedHeader buttonsFlag="unlockLockersScreen" />
          <div className="rent-locker-title">נא לבחור לוקר למידותיך</div>

          <div className="ddocs-inner-wrapper">
            <LockerCells
              onCellClick={this.onCellClick.bind(this)}
              blinkers={blinkers}
              errLockers={errLockers}
              hideInfo={true}
              lockerToLockerDelivery={true}
            />
          </div>
          <FixedFooter history={this.props.history} />
        </div>

        <LoadingInfoModal
          dirRtl={dirRtl}
          show={openAttemptsNum > 0 && !showDoorOpenErrorModal}
          title={opening_locker_number.replace("{XYZ}", lockerNum)}
          text={please_wait}
        />

        <WaitingForLockerLock
          dirRtl={dirRtl}
          show={openingDoorSuccess}
          title={waitingForLockerLockTitle.replace("{XYZ}", lockerNum)}
          subTitle={pls_close_door}
        />

        <ErrorInfoModal
          dirRtl={dirRtl}
          show={showDoorOpenErrorModal}
          title={err_while_opening_door}
          text={try_another_locker_or_contact_support.replace(
            "{XYZ}",
            lockerNum
          )}
          onModalCloseClick={() => this.onDoorOpenErrModalClose()}
          closeTrnsl={close_text}
        />
      </>
    )
  }
}

const mapStateToProps = ({
  userLogin,
  lockersCells,
  translate,
  bgService,
  lockerToLockerDelivery,
}) => {
  const { authenticated, role, userId } = userLogin
  const {
    openedLockersArr,
    errorLockersArr,
    openingAttempsMap,
    recentClosedLockersArr,
  } = lockersCells
  const {
    dirRtl,
    driver_menu_lockers_cells_opening,
    driver_lockers_cells_opening_subtitle,
    please_wait,
    opening_locker_number,
    on_order_collection_finish,
    pls_close_door,
    try_another_locker_or_contact_support,
    err_while_opening_door,
    close_text,
  } = translate

  const waitingForLockerLockTitle = `${opening_locker_number}. ${on_order_collection_finish}`

  const { stationId } = bgService
  const { payedOrder, createdOrder, isLoading, isOrderConfirmed } =
    lockerToLockerDelivery

  const { lockerNum } = payedOrder || {}
  const openingDoorSuccess = openedLockersArr.includes(lockerNum)
  const openingErrorOccurred = errorLockersArr.includes(lockerNum)
  const orderWasLoaded = recentClosedLockersArr.includes(lockerNum)
  const openAttemptsNum = openingAttempsMap[lockerNum] || 0
  const blinkers = !openingErrorOccurred ? { [lockerNum]: lockerNum } : {}
  const errLockers = openingErrorOccurred ? { [lockerNum]: lockerNum } : {}

  return {
    authenticated,
    role,
    userId,
    blinkers,
    errLockers,
    openingDoorSuccess,
    openingErrorOccurred,
    orderWasLoaded,
    openAttemptsNum,
    driver_menu_lockers_cells_opening,
    driver_lockers_cells_opening_subtitle,
    opening_locker_number,
    stationId,
    payedOrder,
    createdOrder,

    dirRtl,
    please_wait,
    waitingForLockerLockTitle,
    pls_close_door,
    try_another_locker_or_contact_support,
    err_while_opening_door,
    close_text,

    isLoading,
    isOrderConfirmed,
  }
}

export default connect(mapStateToProps, {
  LogoutUser,
  reportFBEvent,
  logScreenEnterance,
  setLockerToLockerDeliveryOrder,
  confirmCreateOrder,
  openAndListenToSpecificLockerLock,
  resetLockersErrorsAttemptsOpened,
  setThankYouSubTitle,
  getOrderForPay,
  setLockerToLockerDeliveryOrderLoading,
})(withRouter(SelectLocker))
