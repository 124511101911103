import React, { Component } from "react"
import { connect } from "react-redux"
import Modal from "react-modal"
import {
  payWithPelecard,
  payWithPelecardToken,
  resetPaymentToTryAgain,
  saveCreditTokenInServer,
} from "../../../actions"
import {
  OrangeButton,
  PendingSuccessFailBtn,
  WhiteCheckBox,
  WhiteLabel,
} from "../../common"
import PaymentInput2 from "../../common/PaymentInput2"
import SvgMenuButton from "../../common/SvgMenuButton"
import { CardManuallyInputs } from "../../reduxified"
import "./style.css"

class PaymentModal2 extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      enterCardDataManually: false,
      showChoiceBtns: true,
    }

    this.state = this.INIT_STATE
  }

  getTitleFromReqState() {
    const { showChoiceBtns, enterCardDataManually } = this.state
    const {
      loadingRequest,
      paymentFailed,
      paymentSuccess,
      pay_modal_swipe_title,
      pay_modal_pls_wait_while_loading,
      payment_error,
      payment_success,
      payment_pick_your_option,
      payment_payment_details,
    } = this.props

    if (loadingRequest) {
      return pay_modal_pls_wait_while_loading
    }

    if (paymentFailed) {
      // return err_title_text;
      return payment_error
    }

    if (paymentSuccess) {
      return payment_success
    }

    if (showChoiceBtns) {
      return payment_pick_your_option
    }

    if (!enterCardDataManually) {
      //return pay_modal_swipe_title;
      return ""
    }

    if (enterCardDataManually) {
      return payment_payment_details
    }
  }

  renderCloseBtn(show) {
    if (!show) {
      return
    }

    const { close_text } = this.props

    return (
      <OrangeButton
        extraClass="payment-modal-close-btn"
        onClick={() => {
          this.props.onCloseModalClick()
          this.onTryAgainClicked()
        }}
      >
        {close_text}
      </OrangeButton>
    )
  }

  renderPayWithExistingTokenBtn(show) {
    if (!show) {
      return
    }

    const { userTokenInServer, payment_pay_with_last_payment_details } =
      this.props

    if (!userTokenInServer) {
      return
    }

    return (
      <OrangeButton
        // extraClass="paymdl2-enter-carddate-manually-btn"
        onClick={(e) => this.onPayWithSavedTokenClick()}
      >
        {payment_pay_with_last_payment_details}
      </OrangeButton>
    )
  }

  renderPaymentInputMagnetOrManualButtons(show) {
    // show = !loadingRequest && !paymentFailed && !paymentSuccess;
    if (!show) {
      return
    }

    const { showChoiceBtns } = this.state

    if (showChoiceBtns) {
      return this.renderPaymentMenu(show)
    }

    return this.renderPaymentInputMagnetOrManual(show)
  }

  renderPaymentMenu(show) {
    const { payment_swipe_a_card, payment_for_manual_input } = this.props
    const { userTokenInServer, payment_pay_with_last_payment_details } =
      this.props
    const showSavedCardPayment = show && userTokenInServer
    return (
      <div className="payment-btns">
        <SvgMenuButton
          show={showSavedCardPayment}
          imgCss="saved-card-payment"
          imageName={"menuSavedCardPayment"}
          text={payment_pay_with_last_payment_details}
          onClick={(e) => this.onPayWithSavedTokenClick()}
        />
        <SvgMenuButton
          show={true}
          imgCss="card-payment"
          imageName={"menuCardPayment"}
          text={payment_swipe_a_card}
          onClick={(e) =>
            this.setState({
              showChoiceBtns: false,
              enterCardDataManually: false,
            })
          }
        />
        <SvgMenuButton
          show={true}
          imgCss="manual-payment"
          imageName={"menuManualPayment"}
          text={payment_for_manual_input}
          onClick={(e) =>
            this.setState({
              showChoiceBtns: false,
              enterCardDataManually: true,
            })
          }
        />
      </div>
    )
  }

  renderPaymentInputMagnetOrManual(show) {
    if (!show) {
      return
    }

    const { enterCardDataManually } = this.state
    const { payment_for_manual_input_click_here } = this.props

    if (!enterCardDataManually) {
      return (
        <div className="paymdl2-payinput-wrapper">
          <PaymentInput2
            onPaymentDataReady={this.startPayingProcess.bind(this)}
          />
        </div>
      )
    }

    return this.renderManuallyInputs()
  }

  // closeEnterCardDataManually() {
  //   this.setState({ enterCardDataManually: false })
  //   // this.props.show = false
  // }

  renderManuallyInputs() {
    return (
      <CardManuallyInputs
        show={this.state.enterCardDataManually}
        startPayingProcess={(creditCardData, dateMMYY) =>
          this.startPayingProcess(creditCardData, dateMMYY)
        }
        closeEnterCardDataManually={() => {
          this.props.onCloseModalClick()
          this.onTryAgainClicked()
        }}
        renderCloseBtn={() => this.renderCloseBtn()}
      />
    )
  }

  startPayingProcess(creditCardData, dateMMYY) {
    const { total, orderId } = this.props
    this.props.payWithPelecard(
      creditCardData,
      dateMMYY,
      "id",
      "cvv",
      total,
      orderId
    )
  }

  onPayWithSavedTokenClick() {
    const { userTokenInServer, total, orderId } = this.props
    this.props.payWithPelecardToken(userTokenInServer, total, orderId)
  }

  renderRequestStateSpinnerAndResultButtons(show) {
    if (!show) {
      return
    }

    const {
      loadingRequest,
      paymentFailed,
      paymentSuccess,
      showTryAgain,
      showConnectionErrMsg,
      ok_text,
    } = this.props

    const currOkBtnStatus = loadingRequest
      ? 0
      : paymentFailed
      ? -1
      : paymentSuccess
      ? 1
      : 0

    return (
      <div className="error-or-success-content">
        <PendingSuccessFailBtn
          status={currOkBtnStatus}
          onBtnClick={() => this.onOkClicked()}
        >
          {ok_text}
        </PendingSuccessFailBtn>

        {this.renderTryAgainBtn(showTryAgain)}
        {this.renderConnectionErrMsg(showConnectionErrMsg)}
        {this.renderSuccessSaveTokenCheckboxContinueBtn(paymentSuccess)}
      </div>
    )
  }

  renderSuccessSaveTokenCheckboxContinueBtn(show) {
    if (!show) {
      return
    }

    const {
      userTokenInServer,
      pelecardToken,
      payment_press_for_locker_opening,
    } = this.props

    return (
      <div className="error-or-success-content">
        {this.renderSaveMyNewTokenCheckBox(userTokenInServer !== pelecardToken)}

        <OrangeButton
          activable
          extraClass="paymdl2-fade-in paymdl2-err-btn"
          onClick={() => this.onSuccessfulPaymentContinueBtnClick()}
        >
          {payment_press_for_locker_opening}
        </OrangeButton>
      </div>
    )
  }

  renderSaveMyNewTokenCheckBox(show) {
    if (!show) {
      return
    }

    const { saveCardPeleToken } = this.state
    const { payment_save_payment_details_for_future_use, dirRtl } = this.props

    return (
      <div className="paymdl2-fade-in paymdl2-save-token-wrap paymdl2-err-msg">
        <WhiteCheckBox
          checked={saveCardPeleToken}
          onChange={(e) =>
            this.setState({ saveCardPeleToken: e.target.checked })
          }
        />

        <WhiteLabel dirRtl={dirRtl}>
          {payment_save_payment_details_for_future_use}
        </WhiteLabel>
      </div>
    )
  }

  renderTryAgainBtn(show) {
    if (!show) {
      return
    }

    const { pay_modal_click_to_retry, payment_input_error, dirRtl } = this.props

    return (
      <div className="error-or-success-content">
        <WhiteLabel
          extraClass="paymdl2-fade-in paymdl2-err-msg"
          dirRtl={dirRtl}
        >
          {payment_input_error}
        </WhiteLabel>

        <OrangeButton
          activable
          extraClass="paymdl2-fade-in paymdl2-err-btn"
          onClick={() => this.onTryAgainClicked()}
        >
          {pay_modal_click_to_retry}
        </OrangeButton>
      </div>
    )
  }

  onTryAgainClicked() {
    this.setState({ ...this.INIT_STATE })
    this.props.resetPaymentToTryAgain()
  }

  renderConnectionErrMsg(show) {
    if (!show) {
      return
    }

    const { pay_modal_network_err, pay_modal_click_to_retry, dirRtl } =
      this.props

    return (
      <div className="error-or-success-content">
        <WhiteLabel
          extraClass="paymdl2-fade-in paymdl2-err-msg"
          dirRtl={dirRtl}
        >
          {pay_modal_network_err}
        </WhiteLabel>
        <OrangeButton
          onClick={() => this.onTryAgainClicked()}
          extraClass="paymdl2-fade-in paymdl2-err-btn"
        >
          {pay_modal_click_to_retry}
        </OrangeButton>
      </div>
    )
  }

  onContinueBtnClick() {
    this.props.onPaymentNetworkFail()
  }

  onSuccessfulPaymentContinueBtnClick() {
    const { saveCardPeleToken } = this.state
    const { userId, pelecardToken } = this.props

    if (saveCardPeleToken && pelecardToken) {
      this.props.saveCreditTokenInServer(userId, pelecardToken)
    }

    this.props.onUserPaymentSuccess()
  }

  render() {
    const { show } = this.props

    if (!show) {
      return <noscript />
    }
    const { enterCardDataManually } = this.state
    const {
      loadingRequest,
      paymentFailed,
      paymentSuccess,
      dirRtl,
      onCloseModalClick,
    } = this.props

    const showPaymentInputs =
      !loadingRequest && !paymentFailed && !paymentSuccess

    const wrapperClassName = `paymdl2-modal-wrap ${
      dirRtl ? "dirrtl" : "dirltr"
    }`

    return (
      <Modal
        isOpen={show}
        onRequestClose={onCloseModalClick}
        className={`react-modal-content medium-react-modal ${
          enterCardDataManually && "payment-modal-move-up"
        }`}
        overlayClassName="modal-overlay"
      >
        <div className="payment-modal-title" dir={dirRtl ? "rtl" : "ltr"}>
          {this.getTitleFromReqState()}
        </div>
        {this.renderPaymentInputMagnetOrManualButtons(showPaymentInputs)}
        {this.renderRequestStateSpinnerAndResultButtons(!showPaymentInputs)}
        {this.renderCloseBtn(
          !this.state.enterCardDataManually & !loadingRequest &&
            !paymentSuccess &&
            !this.props.showTryAgain
        )}
      </Modal>
    )
  }
}

const mapStateToProps = ({ userLogin, paymentModal, translate }) => {
  const { userId, creditCardToken, authenticated } = userLogin
  const {
    loadingRequest,
    paymentFailed,
    paymentSuccess,
    showTryAgain,
    showConnectionErrMsg,
    pelecardToken,
  } = paymentModal

  const {
    dirRtl,
    ok_text,
    close_text,
    pay_modal_swipe_title,
    pay_modal_pls_wait_while_loading,
    pay_modal_click_to_retry,
    pay_modal_network_err,
    continue_text,
    payment_error,
    payment_success,
    payment_pick_your_option,
    payment_payment_details,
    payment_pay_with_last_payment_details,
    payment_swipe_a_card,
    payment_for_manual_input,
    payment_for_manual_input_click_here,
    payment_credit_card_number,
    payment_month,
    payment_year,
    payment_approval,
    payment_swipe_a_card_click_here,
    payment_press_for_locker_opening,
    payment_save_payment_details_for_future_use,
    payment_input_error,
  } = translate

  return {
    userId,
    loadingRequest,
    paymentFailed,
    paymentSuccess,
    showTryAgain,
    showConnectionErrMsg,
    pelecardToken,
    userTokenInServer: creditCardToken,
    authenticated,

    dirRtl,
    ok_text,
    close_text,
    pay_modal_swipe_title,
    pay_modal_pls_wait_while_loading,
    pay_modal_click_to_retry,
    pay_modal_network_err,
    continue_text,
    payment_error,
    payment_success,
    payment_pick_your_option,
    payment_payment_details,
    payment_pay_with_last_payment_details,
    payment_swipe_a_card,
    payment_for_manual_input,
    payment_for_manual_input_click_here,
    payment_credit_card_number,
    payment_month,
    payment_year,
    payment_approval,
    payment_swipe_a_card_click_here,
    payment_press_for_locker_opening,
    payment_save_payment_details_for_future_use,
    payment_input_error,
  }
}

export default connect(mapStateToProps, {
  payWithPelecard,
  payWithPelecardToken,
  resetPaymentToTryAgain,
  saveCreditTokenInServer,
})(PaymentModal2)
