import React, { Component } from "react"
import { connect } from "react-redux"
import BarcodeReader from "react-barcode-reader"
import WarningInfoModal from "../../Modals/WarningInfoModal"
import BarcodeReaderInfoModal from "../../Modals/BarcodeReaderInfoModal"
import "./style.css"

class OrderBarcodeReader extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      showInfo: false,
      showPackageNotFound: false,
      notFoundBarcode: "",
      showScanError: false,
    }

    this.state = this.INIT_STATE
    this.onBarcodeChange = this.onBarcodeChange.bind(this)
    this.handleError = this.handleError.bind(this)
  }

  componentDidMount() {
    this.setState({ showInfo: true })
    this.resetSelfStateIn(5)
  }

  componentWillUnmount() {
    this.state = this.INIT_STATE
  }

  resetSelfStateIn(secs) {
    setTimeout(() => this.setState({ ...this.INIT_STATE }), secs * 1000)
  }

  handleError(res) {
    console.log("Scan barcode error:" + res)

    this.setState({
      notFoundBarcode: "",
      showPackageNotFound: false,
      showScanError: true,
    })
    this.resetSelfStateIn(5)
  }

  onBarcodeChange(barcodeText) {
    console.log("barcodeText:" + barcodeText)
    const { readyOrdersList, role } = this.props
    console.log(barcodeText)
    const barcodeTextLower = barcodeText.toString().toLowerCase()
    console.log("barcodeTextLower: " + barcodeTextLower)

    const matchList = readyOrdersList.filter((o) => {
      const isPackageNumber =
        o.packageNumber &&
        o.packageNumber.length > 0 &&
        o.packageNumber.toString().toLowerCase() === barcodeTextLower
      const isOrderNumber =
        o.orderNumber && o.orderNumber.toString() === barcodeText
      let isExternalOrderNumber = false

      if (role === "DHL") {
        isExternalOrderNumber =
          o.externalOrderNumber &&
          o.externalOrderNumber.toString().toLowerCase() === barcodeTextLower
      }

      return isPackageNumber || isOrderNumber || isExternalOrderNumber
    })
    if (matchList && matchList.length > 0) {
      this.onOrderExist(matchList, barcodeText)
    } else {
      this.onNotFoundOrder(barcodeText)
      this.resetSelfStateIn(5)
    }
  }

  onOrderExist(foundOrdersList, barcodeText) {
    console.log("Barcode found!!! " + barcodeText)

    const order = foundOrdersList[0]
    this.props.onOrderFound(order.orderId, order)
  }

  onNotFoundOrder(barcodeText) {
    this.setState({
      notFoundBarcode: barcodeText,
      showPackageNotFound: true,
      showScanError: false,
    })

    console.log("Barcode not found: " + barcodeText)
  }

  render() {
    const { isStationHaveBarcodeReader } = this.props

    if (!isStationHaveBarcodeReader) {
      return <></>
    }

    //TODO  REMOVE barcode_scanning
    const { extraClass, close_text } = this.props
    const { showPackageNotFound, notFoundBarcode, showScanError, showInfo } =
      this.state

    return (
      <div className={"barcode-reader-wrapper " + extraClass}>
        <div>
          <BarcodeReader
            minLength={5}
            onError={this.handleError}
            onScan={this.onBarcodeChange}
          />
        </div>

        <BarcodeReaderInfoModal
          show={showInfo}
          title={"העבר חבילה מול הסורק"}
          text=""
        />
        <WarningInfoModal
          show={showPackageNotFound}
          title={" החבילה  לא נמצאה "}
          text={
            "אנא נסה לסרוק ברקוד שוב או בחר הזמנה מהרשימה \n  מספר חבילה " +
            notFoundBarcode
          }
          btnText={close_text}
          onModalCloseClick={() =>
            this.setState({ showPackageNotFound: false })
          }
        />
        <WarningInfoModal
          show={showScanError}
          title={" החבילה  לא נמצאה "}
          text={"אנא נסה לסרוק ברקוד שוב או בחר הזמנה מהרשימה. \n  מספר חבילה"}
          btnText={close_text}
          onModalCloseClick={() => this.setState({ showScanError: false })}
        />
      </div>
    )
  }
}

const mapStateToProps = ({
  readyOrders,
  userLogin,
  translate,
  stationServiceTypes,
}) => {
  const { authenticated, role } = userLogin
  const { readyOrdersArr } = readyOrders
  const { isStationHaveBarcodeReader } = stationServiceTypes
  const { barcode_scanning, close_text } = translate

  return {
    authenticated,
    readyOrdersList: readyOrdersArr,
    barcode_scanning,
    isStationHaveBarcodeReader,
    close_text,
    role,
  }
}

export default connect(mapStateToProps)(OrderBarcodeReader)
