import React from "react"

import "./style.css"

const WhiteSquare = ({
  icon,
  title,
  onWhiteSquareClick,
  item,
  extraClass,
  ...props
}) => {
  let iconSrc = require(`../../../assets/images/${icon}.png`)

  if (item) {
    const { imageId, imgUrl } = item

    if (imageId > -1) {
      iconSrc = imgUrl
    }
  }

  return (
    <div
      className={`whsq-wrapper ${extraClass}`}
      onClick={() => onWhiteSquareClick && onWhiteSquareClick(icon, title)}
      {...props}
    >
      <div className="whsq-img">
        <img src={iconSrc} alt="iconSrc" />
      </div>
      <div className="whsq-title">{title}</div>
    </div>
  )
}

export { WhiteSquare }
