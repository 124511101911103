import axios from "axios";
import api from "../api";

import { axiosPostRetry, axiosGetRetry } from "../utils";
import { DYNAMIC_ITEMS_FETCH_SUCCESS } from "./types";

import { reportFBEvent } from "./FBEventsActions";

export const createNewOrder = (
  lockerId,
  customerId,
  stationId,
  items,
  couponId,
  orderType,
  orderId
) => {
  return (dispatch) => {
    // axiosPostRetry(
    axios
      .post(
        api.createNewOrder,
        api.createNewOrderBody(
          lockerId,
          customerId,
          stationId,
          items,
          couponId,
          orderType,
          orderId
        )
      )
      .then(() => {
        dispatch(reportFBEvent("New_Order", "New Order Has Been Created!"));
      })
      .catch((err) => console.log(err));
  };
};

export const createNewDynamicProductOrder = (customerId, stationId, items) => {
  return (dispatch) => {
    console.log(
      api.createNewDynamicProductOrderBody(customerId, stationId, items)
    );

    axiosPostRetry(
      api.createNewOrder,
      api.createNewDynamicProductOrderBody(customerId, stationId, items)
    )
      .then((res) => {
        console.log(res.data);
        dispatch(
          reportFBEvent(
            "New_Dynamic_Order",
            "New Dynamic Item Order Has Been Created!"
          )
        );
      })
      .catch((err) => console.log(err));
  };
};

export const getAllDynamicProducts = (stationId, lang) => {
  return (dispatch) => {
    axiosGetRetry(api.getAllDynamicProducts(stationId, lang))
      .then((res) => {
        dispatch({
          type: DYNAMIC_ITEMS_FETCH_SUCCESS,
          payload: api.getDynamicProductsResponse(res.data),
        });
      })
      .catch((err) => console.log(err));
  };
};
