import React, { Component } from "react"
import { connect } from "react-redux"
import {
  fetchAllLockersLayout,
  fetchAllLockersStatuses,
  fetchUserActiveOrders,
  getAllDynamicProducts,
  LogoutUser,
  getOrdersRequests,
  getOrderForPay,
  logScreenEnterance,
  resetLockersErrorsAttemptsOpened,
  clearOrderForConfirmCreate,
} from "../../../../actions"
import { MenuOrangeButton } from "../../../common"
import Loader from "../../../common/Loader"
import WarningInfoModal from "../../../Modals/WarningInfoModal"
import { FixedHeader } from "../../../reduxified"
import "./style.css"

class UserMenuScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showOutOfLockersModal: false,
    }
  }

  componentDidMount() {
    this.props.logScreenEnterance("USER_MENU_SCREEN")
    const { authenticated } = this.props
    if (!authenticated) {
      this.props.LogoutUser()
      this.props.history.push("")
    }
    const { userId, stationId, langName } = this.props

    this.props.resetLockersErrorsAttemptsOpened()
    this.props.fetchUserActiveOrders(userId, stationId)
    this.props.getOrdersRequests(stationId, userId, 1)
    this.props.fetchAllLockersStatuses(stationId)
    this.props.fetchAllLockersLayout(stationId)
    this.props.getAllDynamicProducts(stationId, langName)
  }

  onNewOrderClick() {
    //TODO CLEAR PRE ORDER
    this.props.clearOrderForConfirmCreate()
    this.redirectToNewOrderIfHaveEmptyLockers()
  }

  redirectToNewOrderIfHaveEmptyLockers() {
    const { isOutOfAvailableLockers } = this.props
    if (isOutOfAvailableLockers) {
      this.setState({ showOutOfLockersModal: true })
      return
    }
    this.props.history.push("NewOrderScreen")
  }

  onNewPurchaseClick() {
    this.props.history.push("NewPurchaseScreen")
  }

  onReviewOrderList() {
    this.props.history.push("ReviewOrderList")
  }

  onCollectOrderClick() {
    this.props.history.push("CollectOrderScreen")
  }

  onDriverMenuClick() {
    this.props.history.push("DriverMenuScreen")
  }

  closeOutOfLockersModal() {
    this.setState({ showOutOfLockersModal: false })
  }

  onContinueRequestClick(request) {
    //SET ORDER to CONTINUE
    this.props.getOrderForPay(request.orderId)
    this.redirectToNewOrderIfHaveEmptyLockers()
  }

  renderLoader() {
    const { isLoading } = this.props
    if (!isLoading) {
      return <></>
    }
    return <Loader />
  }

  render() {
    const {
      hideCollectItemsBtn,
      hideDriverInterfaceBtn,
      create_new_order,
      collect_order,
      driver_interface,
      all_lockers_full_err_title,
      all_lockers_full_err_body,
      close_text,
      firstName,
      hello_title_text,
      user_menu_what_sub_title_text,
      dirRtl,
      ordersRequestsList,
      continue_order,
      isLoading,
    } = this.props
    const { showOutOfLockersModal } = this.state

    return (
      <div className="user-menu">
        <FixedHeader buttonsFlag="userMenuScreen" />
        <div
          className="ums-title"
          dir={dirRtl ? "rtl" : "ltr"}
        >{`${hello_title_text}, ${firstName}`}</div>
        <div className="ums-subtitle" dir={dirRtl ? "rtl" : "ltr"}>
          {user_menu_what_sub_title_text}
        </div>

        <div className="ums-wrapper">
          <MenuOrangeButton onClick={(e) => this.onNewOrderClick()}>
            {create_new_order}
          </MenuOrangeButton>
          <div className="requests-list">
            {this.renderLoader()}
            {!isLoading &&
              ordersRequestsList.map((request) => {
                return (
                  <MenuOrangeButton
                    dir={dirRtl ? "rtl" : "ltr"}
                    onClick={() => this.onContinueRequestClick(request)}
                    key={request.orderId}
                    textStyle="finish-rent-text"
                  >{`${continue_order} ${request.orderNumber}`}</MenuOrangeButton>
                )
              })}
          </div>
          <MenuOrangeButton
            onClick={() => this.onCollectOrderClick()}
            disabled={hideCollectItemsBtn}
          >
            {collect_order}
          </MenuOrangeButton>

          <MenuOrangeButton
            onClick={() => this.onDriverMenuClick()}
            hide={hideDriverInterfaceBtn}
          >
            {driver_interface}
          </MenuOrangeButton>
        </div>

        <WarningInfoModal
          show={showOutOfLockersModal}
          title={all_lockers_full_err_title}
          text={all_lockers_full_err_body}
          onModalCloseClick={() => this.closeOutOfLockersModal()}
          btnText={close_text}
        />
      </div>
    )
  }
}

const mapStateToProps = ({
  userLogin,
  activeOrders,
  lockersCells,
  bgService,
  translate,
}) => {
  const { stationId } = bgService
  const { userId, role, authenticated, firstName } = userLogin
  const { displayedOrder, ordersRequestsList, isLoading } = activeOrders
  const { nextAllAvailableCells } = lockersCells
  const {
    langName,
    dirRtl,
    user_menu_what_sub_title_text,
    user_menu_new_order_btn_text,
    close_text,
    user_menu_watch_orders_btn_text,
    user_menu_item_collection_btn_text,
    user_menu_product_purchase_btn_text,
    user_menu_driver_interface_btn_text,
    all_lockers_full_err_title,
    all_lockers_full_err_body,
    hello_title_text,
    continue_order,
  } = translate

  const hideCollectItemsBtn = !displayedOrder
  const hideDriverInterfaceBtn = role === "Customer" || role == null
  const isOutOfAvailableLockers =
    !nextAllAvailableCells || nextAllAvailableCells.length < 1

  return {
    userId,
    dirRtl,
    role,
    authenticated,
    displayedOrder,
    hideCollectItemsBtn,
    hideDriverInterfaceBtn,
    isOutOfAvailableLockers,
    stationId,
    firstName,
    langName,
    create_new_order: user_menu_new_order_btn_text,
    watch_order: user_menu_watch_orders_btn_text,
    collect_order: user_menu_item_collection_btn_text,
    purchase_products: user_menu_product_purchase_btn_text,
    driver_interface: user_menu_driver_interface_btn_text,
    all_lockers_full_err_title,
    close_text,
    all_lockers_full_err_body,
    hello_title_text,
    user_menu_what_sub_title_text,
    ordersRequestsList,
    continue_order,
    isLoading,
  }
}

export default connect(mapStateToProps, {
  fetchUserActiveOrders,
  fetchAllLockersStatuses,
  logScreenEnterance,
  fetchAllLockersLayout,
  getAllDynamicProducts,
  resetLockersErrorsAttemptsOpened,
  LogoutUser,
  getOrdersRequests,
  getOrderForPay,
  clearOrderForConfirmCreate,
})(UserMenuScreen)
