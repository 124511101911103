import React, { Component } from "react"
import Modal from "react-modal"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import {
  logScreenEnterance,
  openAndListenToSpecificLockerLock,
  isUserHadOrderBefore,
  fetchAvailableLockersStatus,
  LogoutUser,
} from "../../../../actions"
import CardCheckModal from "../../../Modals/CardCheckModal"
import { FixedFooter, FixedHeader, LockerCells } from "../../../reduxified"
import api from "../../../../api/LocalServiceApi"
import axios from "axios"
import errorImg from "../../../../assets/svg/error-sign.svg"
import "./style.css"
import { OrangeButton } from "../../../common"
import { validation } from "../../../../utils/Validations"

class LockerToLockerDeliveryScreen extends Component {
  constructor(props) {
    super(props)
    this.INIT_STATE = {
      isClickWaiting: false,
      showCardCheckModal: false,
      showYesNoInfoModal: false,
      cardToken: "",
      invalidToken: false,
    }
    this.state = this.INIT_STATE
  }

  componentDidMount() {
    const { authenticated, stationId } = this.props
    if (!authenticated) {
      this.props.LogoutUser()
      this.props.history.push("")
    }
    this.props.logScreenEnterance("LOCKER_TO_LOCKER_DELIVERY_SCREEN")

    this.props.fetchAvailableLockersStatus(stationId)
  }

  checkCreditCard(cCard, formattedDate) {
    axios
      .post(api.getPelecardToken, { cCard, formattedDate })
      .then((res) => {
        res.data.receivedToken
          ? this.setState({
              cardToken: res.data.receivedToken,
              showCardCheckModal: false,
            })
          : this.setState({
              invalidToken: true,
              showCardCheckModal: false,
              showYesNoInfoModal: true,
            })
      })
      .catch((err) => {
        console.log(err)
        this.setState({ invalidToken: true, showYesNoInfoModal: true })
      })
  }

  // onCellClick() {}

  render() {
    const {
      blinkers,
      errLockers,
      isHadOrderBefore,
      isCheckedHadOrderBefore,
      toStationId,
      addresseeDetails,
    } = this.props
    const { showCardCheckModal, showYesNoInfoModal, invalidToken } = this.state

    const isAddresseeDetails = validation.AddresseeDetails(addresseeDetails)

    const step1_check_user =
      (isCheckedHadOrderBefore && !isHadOrderBefore) ||
      (!isCheckedHadOrderBefore && !isHadOrderBefore) ||
      invalidToken
    //const step2_select_station =  (isHadOrderBefore || !invalidToken)  && toStationId == null;
    //const step3_fill_addressee = (isHadOrderBefore || !invalidToken) && toStationId != null  && !isAddresseeDetails;
    const step4_lockers_cells =
      (isHadOrderBefore || !invalidToken) &&
      toStationId != null &&
      isAddresseeDetails //&& !isLockerOpened;
    //const step5_LockerDeliveryMessage = (isHadOrderBefore || !invalidToken) &&  toStationId != null && isAddresseeDetails && isLockerOpened;

    if (step1_check_user) {
      const show_CardCheck_IsUserHadOrderCheck_Modal =
        !invalidToken && !isHadOrderBefore

      return (
        <>
          <div className="ls-wrapper ">
            <FixedHeader buttonsFlag="unlockLockersScreen" />
            <div className="rent-locker-title">נא לבחור לוקר למידותיך</div>

            <FixedFooter history={this.props.history} />
          </div>

          <CardCheckModal //Kiril
            //show={showCardCheckModal}
            show={show_CardCheck_IsUserHadOrderCheck_Modal}
            onCloseModal={() => this.setState({ showCardCheckModal: false })}
            checkCreditCard={this.checkCreditCard.bind(this)}
          />
          <Modal
            isOpen={showYesNoInfoModal}
            className="react-modal-content"
            overlayClassName="modal-overlay"
          >
            <img src={errorImg} alt="_X_" />
            <div className="retry-text">הכרטיס לא זוהה, אנא נסו שוב</div>
            <div className="retry-buttons">
              <OrangeButton
                onClick={() =>
                  this.setState({
                    showYesNoInfoModal: false,
                    showCardCheckModal: true,
                  })
                }
              >
                retry
              </OrangeButton>
              <OrangeButton onClick={() => this.props.history.goBack()}>
                close
              </OrangeButton>
            </div>
          </Modal>
        </>
      )
    }

    if (step4_lockers_cells) {
      return (
        <>
          <div className="ls-wrapper ">
            <FixedHeader buttonsFlag="unlockLockersScreen" />

            <div className="rent-locker-title">נא לבחור לוקר למידותיך</div>

            <div className="ddocs-inner-wrapper">
              <LockerCells
                // onCellClick={this.onCellClick.bind(this)}
                blinkers={blinkers}
                errLockers={errLockers}
                rentLocker
              />
            </div>
            <FixedFooter history={this.props.history} />
          </div>
        </>
      )
    }

    return <>NO DATA</>
  }
}

const mapStateToProps = ({
  userLogin,
  lockersCells,
  translate,
  bgService,
  lockerToLockerDelivery,
}) => {
  const {
    authenticated,
    role,
    userId,

    isHadOrderBefore,
    isCheckedHadOrderBefore,
  } = userLogin
  const { openedLockersArr, errorLockersArr } = lockersCells
  const {
    driver_menu_lockers_cells_opening,
    driver_lockers_cells_opening_subtitle,
  } = translate
  const blinkers = {}
  openedLockersArr.map((lockNum) => (blinkers[lockNum] = lockNum))
  const errLockers = {}
  errorLockersArr.map((lockNum) => (errLockers[lockNum] = lockNum))
  const { stationId } = bgService
  const { order } = lockerToLockerDelivery
  const { toStationId } = order
  return {
    authenticated,
    role,
    userId,
    isHadOrderBefore,
    isCheckedHadOrderBefore,
    blinkers,
    errLockers,
    driver_menu_lockers_cells_opening,
    driver_lockers_cells_opening_subtitle,
    stationId,
    toStationId,
  }
}

export default connect(mapStateToProps, {
  openAndListenToSpecificLockerLock,
  logScreenEnterance,
  isUserHadOrderBefore,
  fetchAvailableLockersStatus,
  LogoutUser,
})(withRouter(LockerToLockerDeliveryScreen))
