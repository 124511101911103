import axios from "axios";
import api from "../api";

import { ENABLE_BG_SYNC, axiosPostRetry, axiosGetRetry } from "../utils";

import {
  ON_USER_SIGN_IN_SUCCESS,
  ON_USER_SIGN_IN_FAIL,
  ON_USER_SIGN_OUT,
  ON_USER_SIGN_IN_REQUEST,
  USER_MOBILE_ALREADY_TAKEN_REQUEST,
  USER_MOBILE_ALREADY_TAKEN_TRUE,
  USER_MOBILE_ALREADY_TAKEN_FALSE,
  GET_IS_USER_HAD_ORDER_BEFORE_SUCCESS,
  GET_IS_USER_HAD_ORDER_BEFORE_FAIL,
  SET_CC_SHURED,
  RESET_CC_SHURED,
  ON_BASE_ERROR,
} from "./types";

import { reportFBEvent } from "./FBEventsActions";

export const onUserSignInClick = (mobile, pwd) => {
  return (dispatch) => {
    dispatch({
      type: ON_USER_SIGN_IN_REQUEST,
    });

    axiosPostRetry(api.signIn, api.signInBody(mobile, pwd), 7000)
      .then((res) => {
        const {
          id: userId,
          role,
          firstName,
          lastName,
          token,
          isPasswordTemporary,
          isRefillDetails,
          email,
          creditCardToken,
          isDeliveryRole,
        } = res.data;

        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        dispatch({
          type: ON_USER_SIGN_IN_SUCCESS,
          payload: {
            userId,
            role,
            firstName,
            lastName,
            email,
            token,
            isPasswordTemporary,
            isRefillDetails,
            creditCardToken,
            isDeliveryRole,
            mobile,
          },
        });

        dispatch(reportFBEvent("Sign_In", "User Sign In Success"));

        if (ENABLE_BG_SYNC) {
          sendUserValidDataToLocalBgService(
            userId,
            mobile,
            firstName,
            lastName,
            email,
            pwd,
            role
          );
        }
      })
      .catch((err) => {
        if (err.message === "Network Error") {
          console.log("ASK BG SERVICE HERE!!!");

          if (ENABLE_BG_SYNC) {
            authenticateUserWithTheLocalBgService(dispatch, mobile, pwd);
          } else {
            dispatch({
              type: ON_USER_SIGN_IN_FAIL,
              payload: {
                // errTitle: err.response.data.message,
                // errText: err.response.status
                errTitle: "err_title_text", //'שגיאה',
                errText: "network_error_text", //'תקלת תקשורת זמנית'
              },
            });
          }
          return;
        }

        // TODO - STOPPED HERE - UNCOMMENT WHEN BG OFFLINE SYNC WORK CONTINUES..
        // if (err.response.status === 404 || err.response.status === 400) {
        dispatch({
          type: ON_USER_SIGN_IN_FAIL,
          payload: {
            // errTitle: err.response.data.message,
            // errText: err.response.status
            errTitle: "err_title_text", //'שגיאה',
            errText: "wrong_login_err_body_text", //'אחד או יותר מהפרטים שהוזנו שגויים. ניתן לאפס סיסמא באמצעות לחיצה על \'שכחת סיסמא?\''
            // errText: 'הלקוח המבוקש לא קיים, תיתכן שגיאה באחד מהשדות'
          },
        });
        // }
      });
  };
};

export const onUserSignInWithDoneId = (doneId) => {
  return (dispatch) => {
    dispatch({
      type: ON_USER_SIGN_IN_REQUEST,
    });
    const data = {
      doneId: doneId,
    };
    axiosPostRetry(api.signIn, data, 7000)
      .then((res) => {
        const {
          id: userId,
          role,
          firstName,
          lastName,
          token,
          isPasswordTemporary,
          isRefillDetails,
          email,
          creditCardToken,
          isDeliveryRole,
          mobilePhone: mobile,
        } = res.data;

        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        dispatch({
          type: ON_USER_SIGN_IN_SUCCESS,
          payload: {
            userId,
            role,
            firstName,
            lastName,
            email,
            token,
            isPasswordTemporary,
            isRefillDetails,
            creditCardToken,
            isDeliveryRole,
            mobile,
          },
        });

        dispatch(reportFBEvent("Sign_In", "User Sign In Success"));

        /*if (ENABLE_BG_SYNC) {
          sendUserValidDataToLocalBgService(
            userId,
            mobile,
            firstName,
            lastName,
            email,
            pwd,
            role
          )
        }*/
      })
      .catch((err) => {
        if (err.message === "Network Error") {
          //console.log("ASK BG SERVICE HERE!!!")

          //if (ENABLE_BG_SYNC) {
          //  authenticateUserWithTheLocalBgService(dispatch, mobile, pwd)
          //} else {
          dispatch({
            type: ON_USER_SIGN_IN_FAIL,
            payload: {
              errTitle: "err_title_text", //'שגיאה',
              errText: "network_error_text", //'תקלת תקשורת זמנית'
            },
          });
          //}
          return;
        }

        dispatch({
          type: ON_USER_SIGN_IN_FAIL,
          payload: {
            errTitle: "err_title_text", //'שגיאה',
            errText: "wrong_login_err_body_text", //'אחד או יותר מהפרטים שהוזנו שגויים. ניתן לאפס סיסמא באמצעות לחיצה על \'שכחת סיסמא?\''
            // errText: 'הלקוח המבוקש לא קיים, תיתכן שגיאה באחד מהשדות'
          },
        });
        // }
      });
  };
};

const sendUserValidDataToLocalBgService = (
  userId,
  mobile,
  firstName,
  lastName,
  email,
  password,
  role
) => {
  axios
    .post(api.createOrUpdateUser, {
      userId,
      mobile,
      firstName,
      lastName,
      email,
      password,
      role,
    })
    .then((res) => {
      console.log("create or update user", res);
    })
    .catch((err) => {
      console.log(err);
    });
};

const authenticateUserWithTheLocalBgService = (dispatch, mobile, password) => {
  axios
    .post(api.authenticateWithBgService, { mobile, password })
    .then((res) => {
      const { userId, role, firstName, lastName } = res.data.user;

      dispatch({
        type: ON_USER_SIGN_IN_SUCCESS,
        payload: { userId, role, firstName, lastName, mobile },
      });
    })
    .catch((err) => {
      console.log(err);

      dispatch({
        type: ON_USER_SIGN_IN_FAIL,
        payload: {
          errTitle: "שגיאה",
          errText: "אחד או יותר מהפרטים שהוזנו שגויים.",
        },
      });
    });
};

export const LogoutUser = () => {
  return (dispatch) => {
    dispatch({
      type: ON_USER_SIGN_OUT,
    });

    axios.defaults.headers.common["Authorization"] = "";
  };
};

export const checkIfMobileAlreadyExists = (mobile) => {
  return (dispatch) => {
    dispatch({
      type: USER_MOBILE_ALREADY_TAKEN_REQUEST,
    });
    // axios.get(api.isUserMobileAlreadyExists(mobile))
    axiosGetRetry(api.isUserMobileAlreadyExists(mobile))
      .then((res) => {
        if (!res) {
          dispatch({
            type: USER_MOBILE_ALREADY_TAKEN_FALSE,
            payload: mobile,
          });
          console.log("MOBILE", mobile, "NOT EXISTS!");
          return;
        }
        dispatch({
          type: USER_MOBILE_ALREADY_TAKEN_TRUE,
          payload: res.data,
        });

        console.log("MOBILE", mobile, "EXISTS!");
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 404) {
          dispatch({
            type: USER_MOBILE_ALREADY_TAKEN_FALSE,
            payload: mobile,
          });
          console.log("MOBILE", mobile, "NOT EXISTS!");
        } else {
          dispatch({
            type: ON_BASE_ERROR,
          });
        }
      });
  };
};

export const isUserHadOrderBefore = (userId) => {
  return (dispatch) => {
    axiosGetRetry(api.isHadOrderBefore(userId))
      .then((res) => {
        dispatch({
          type: GET_IS_USER_HAD_ORDER_BEFORE_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_IS_USER_HAD_ORDER_BEFORE_FAIL,
          payload: err,
        });
      });
  };
};

export const setUserCCShured = (isCCShured) => {
  return (dispatch) => {
    dispatch({
      type: SET_CC_SHURED,
      action: isCCShured,
    });
  };
};

export const resetUserCCShured = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_CC_SHURED,
    });
  };
};
