import axios from "axios"
import api from "../api"

import {
  READ_STATION_ID_FROM_BG_SERVICE_SUCCESS,
  READ_STATION_ID_FROM_BG_SERVICE_FAIL,
} from "./types"

export const readStationIdFromBgService = () => {
  return (dispatch) => {
    axios
      .get(api.getStationId)
      .then((res) => {
        dispatch({
          type: READ_STATION_ID_FROM_BG_SERVICE_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: READ_STATION_ID_FROM_BG_SERVICE_FAIL,
        })
      })
  }
}

export const readBgServiceConfiguration = () => {
  return (dispatch) => {
    axios
      .get(api.getStationConfiguration)
      .then((res) => {
        const { stationNumber, env, version, stationId } = res.data

        console.log(
          "%c%s %c %s %c %s %c %s",
          "font-weight:800; color:blue;",
          "Kiosk Station Configuration =>",
          "font-weight:800; color:magenta;",
          `Kioks Env - ${env} ->`,
          "font-weight:800; color:lime;",
          `Kioks version - ${version} ->`,
          "font-weight:800; color:violet;",
          `Kioks stationNumber - ${stationNumber}`
        )

        api.fbSetStationNumber(stationNumber)
      })
      .catch((err) => {
        console.log("Error reading Station Configuration", err)
      })
  }
}

export const logScreenEnterance = (screenName) => {
  return (dispatch) => {
    const csvLine = `screen_log, ${screenName}, null, null,`

    const reqData = { csvLine }
    axios.post(api.logToStationLogger, reqData)
  }
}

export const logLockerOpenCloseFailToStationLogger = (isOpen, lockerNum) => {
  return (dispatch) => {
    const csvLine = `locker_${
      isOpen ? "open" : "close"
    }, ${lockerNum}, FAIL, null,`

    const reqData = { csvLine }
    axios.post(api.logToStationLogger, reqData)
  }
}
