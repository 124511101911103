import {
  ON_USER_SIGN_IN_SUCCESS,
  ON_USER_SIGN_IN_FAIL,
  ON_USER_SIGN_OUT,
  RESET_ALL_REDUCERS,
  ON_USER_SIGN_IN_REQUEST,
  ON_TRANSLATION_CHANGE,
  FETCH_ORDER_BY_DELIVERY_CODE_SUCCESS,
  FETCH_ORDER_BY_DELIVERY_CODE_FAIL,
  USER_MOBILE_ALREADY_TAKEN_REQUEST,
  USER_MOBILE_ALREADY_TAKEN_TRUE,
  USER_MOBILE_ALREADY_TAKEN_FALSE,
  ON_BASE_ERROR,
  GET_IS_USER_HAD_ORDER_BEFORE_SUCCESS,
  GET_IS_USER_HAD_ORDER_BEFORE_FAIL,
  SET_CC_SHURED,
  RESET_CC_SHURED,
} from "../actions/types";

const INIT_STATE = {
  userId: null,
  role: null,
  firstName: null,
  lastName: null,
  token: null,
  authenticated: false,
  errTitle: null,
  errText: null,
  isLoadingRequest: false,
  isPasswordTemporary: false,
  isRefillDetails: false,
  creditCardToken: null,
  isError: false,
  isDeliveryRole: false,
  //isCollectOrdersScreenVisited: false,
  isHadOrderBefore: false,
  isCheckedHadOrderBefore: false,
  isCCShured: false,
  isCheckedCCShured: false,
  checkedMobile: null,
  mobile: null,
};

const UserLoginReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_USER_SIGN_IN_REQUEST:
    case USER_MOBILE_ALREADY_TAKEN_REQUEST:
      return {
        ...INIT_STATE,
        isLoadingRequest: true,
      };
    case ON_USER_SIGN_IN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        authenticated: true,
        isLoadingRequest: false,
      };
    case ON_USER_SIGN_IN_FAIL:
      return {
        ...state,
        authenticated: false,
        errTitle: action.payload.errTitle,
        errText: action.payload.errText,
        isLoadingRequest: false,
      };
    case FETCH_ORDER_BY_DELIVERY_CODE_SUCCESS:
      return {
        ...state,
        ...action.payload.user,
        authenticated: true,
        isLoadingRequest: false,
      };

    case FETCH_ORDER_BY_DELIVERY_CODE_FAIL:
      return {
        ...INIT_STATE,
        errTitle: action.payload.status,
        errText: action.payload.status,
      };
    case USER_MOBILE_ALREADY_TAKEN_TRUE:
      return {
        ...state,
        isLoadingRequest: false,
        isPasswordTemporary: action.payload,
        isError: false,
      };
    case USER_MOBILE_ALREADY_TAKEN_FALSE:
      return {
        ...state,
        isLoadingRequest: false,
        isPasswordTemporary: false,
        isError: false,
      };
    case GET_IS_USER_HAD_ORDER_BEFORE_SUCCESS:
      return {
        ...state,
        isHadOrderBefore: action.payload,
        isCheckedHadOrderBefore: true,
        isError: false,
      };
    case GET_IS_USER_HAD_ORDER_BEFORE_FAIL:
      return {
        ...state,
        isHadOrderBefore: false,
        isCheckedHadOrderBefore: false,
        isError: true,
      };
    case SET_CC_SHURED:
      return {
        ...state,
        isCCShured: action.payload,
        isCheckedCCShured: true,
      };
    case RESET_CC_SHURED:
      return {
        ...state,
        isCCShured: false,
        isCheckedCCShured: false,
      };
    case ON_BASE_ERROR:
      return {
        ...state,
        isLoadingRequest: false,
        isError: true,
      };
    case ON_USER_SIGN_OUT:
      return {
        ...INIT_STATE,
      };
    case ON_TRANSLATION_CHANGE:
      return {
        ...INIT_STATE,
      };

    case RESET_ALL_REDUCERS:
      return {
        ...INIT_STATE,
      };
    default:
      return state;
  }
};

export default UserLoginReducer;
