import {
  FETCH_ALL_LOCKERS_STATUSES_SUCCESS,
  FETCH_ALL_LOCKERS_LAYOUT_SUCCESS,
  FIND_NEXT_AVAILABLE_CELL,
  ON_LOCKER_LISTENER_START_WITH_OPEN_ATTEMPT,
  ON_LOCKER_LISTENER_START_WITH_OPEN_ATTEMPT_SUCCESS_RESPONSE,
  ON_LOCKER_LISTENER_START_WITH_OPEN_ATTEMPT_FAIL,
  ON_LOCKER_LOCK_LISTENER_RESPONSE,
  ON_LOCKER_LOCK_LISTENER_FAIL_RESPONSE,
  RESET_ALL_REDUCERS,
  CLEAR_RECENTLY_CLOSED_LOCKER_DOOR,
  RESET_CELLS_ERRORS_OPEN_ATTEMPS,
  ON_GET_OPENED_LOCKERS_STATUS_RESPONSE,
  SET_CHOSEN_CELL,
  FETCH_AVAILABLE_LOCKERS_STATUSES_SUCCESS,
} from "../actions/types";

const INIT_STATE = {
  lockersData: [],
  lockersLayout: [],
  chosenSell: null,
  maxLockerNumber: 0,
  brokenLockersNumArr: [],
  nextAllAvailableCells: null,
  nextAvailableCellIndex: 0,
  nextAvailableCellNumber: null,
  openedLockersArr: [],
  errorLockersArr: [],
  recentClosedLockersArr: [],
  openingAttempsMap: {},
  recentOpenAttemptLockerNum: null,
  lockersWithOpenStatusFromHw: [],
  isAnyOrderToReturn: false,
};

const LockersCellsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_AVAILABLE_LOCKERS_STATUSES_SUCCESS:
    case FETCH_ALL_LOCKERS_STATUSES_SUCCESS:
      return handleLockersStatusesResponse(state, action.payload);
    case FETCH_ALL_LOCKERS_LAYOUT_SUCCESS:
      return {
        ...state,
        lockersLayout: action.payload,
      };
    case FIND_NEXT_AVAILABLE_CELL:
      return handleFindNextAvailableCell(state);
    case ON_LOCKER_LISTENER_START_WITH_OPEN_ATTEMPT:
      return {
        ...state,
        openingAttempsMap: {
          ...state.openingAttempsMap,
          [action.payload]: state.openingAttempsMap[action.payload] + 1 || 1,
        },
        openedLockersArr: state.openedLockersArr.filter(
          (lock) => lock !== action.payload
        ),
        errorLockersArr: state.errorLockersArr.filter(
          (lock) => lock !== parseInt(action.payload)
        ),
        recentClosedLockersArr: state.recentClosedLockersArr.filter(
          (lock) => lock !== parseInt(action.payload)
        ),
        recentOpenAttemptLockerNum: parseInt(action.payload),
      };
    case ON_LOCKER_LISTENER_START_WITH_OPEN_ATTEMPT_SUCCESS_RESPONSE:
      return {
        ...state,
        openedLockersArr: [...state.openedLockersArr, parseInt(action.payload)],
        openingAttempsMap: {
          ...state.openingAttempsMap,
          [action.payload]: null,
        },
      };
    case ON_LOCKER_LISTENER_START_WITH_OPEN_ATTEMPT_FAIL:
      return {
        ...state,
        errorLockersArr: [...state.errorLockersArr, parseInt(action.payload)],
      };
    case ON_LOCKER_LOCK_LISTENER_RESPONSE:
      return {
        ...state,
        openedLockersArr: state.openedLockersArr.filter(
          (lock) => lock !== action.payload
        ),
        recentClosedLockersArr: [
          ...state.recentClosedLockersArr,
          parseInt(action.payload),
        ],
        // TODO - THINK AGAIN ONCE DRIVER LOCKER ERROR HANDLING HAS BEEN ADDED.. :
        errorLockersArr: state.errorLockersArr.filter(
          (lock) => lock !== parseInt(action.payload)
        ),
        openingAttempsMap: {
          ...state.openingAttempsMap,
          [action.payload]: null,
        },
      };
    case ON_LOCKER_LOCK_LISTENER_FAIL_RESPONSE:
      return {
        ...state,
        errorLockersArr: [...state.errorLockersArr, parseInt(action.payload)],
      };
    case CLEAR_RECENTLY_CLOSED_LOCKER_DOOR:
      return {
        ...state,
        recentClosedLockersArr: state.recentClosedLockersArr.filter(
          (lock) => lock !== parseInt(action.payload)
        ),
      };
    case RESET_CELLS_ERRORS_OPEN_ATTEMPS:
      return {
        ...state,
        openedLockersArr: [],
        errorLockersArr: [],
        recentClosedLockersArr: [],
        openingAttempsMap: {},
      };
    case ON_GET_OPENED_LOCKERS_STATUS_RESPONSE:
      return {
        ...state,
        lockersWithOpenStatusFromHw: action.payload,
      };

    case RESET_ALL_REDUCERS:
      return {
        ...INIT_STATE,
        nextAllAvailableCells: state.nextAllAvailableCells,
      };
    case SET_CHOSEN_CELL:
      return {
        ...state,
        chosenCell: action.payload,
      };
    default:
      return state;
  }
};

const handleLockersStatusesResponse = (state, respData) => {
  const lockersData = respData.sort((a, b) => a.lockerName - b.lockerName);
  const nextAllAvailableCells = lockersData.filter(
    (itm) => itm.lockerStatus === 3 && itm.lockerType === 0
  );

  const nextCell = nextAllAvailableCells[0] || undefined;
  const nextAvailableCellNumber = nextCell
    ? parseInt(nextCell.lockerName, 10)
    : undefined;
  const maxLockerNumber = lockersData.reduce(
    (accu, { lockerName }) =>
      accu < parseInt(lockerName) ? parseInt(lockerName) : accu,
    0
  );
  const brokenLockersNumArr = lockersData
    .filter((itm) => itm.lockerStatus === 0)
    .map(({ lockerName }) => parseInt(lockerName));
  const isAnyOrderToReturn =
    lockersData.filter(
      (itm) =>
        !itm.disabled &&
        !itm.disabledToPickUp &&
        itm.order !== null &&
        itm.order.orderStatus === 13
    ).length > 0;

  return {
    ...state,
    lockersData,
    nextAllAvailableCells,
    nextAvailableCellIndex: 0,
    nextAvailableCellNumber,
    maxLockerNumber,
    brokenLockersNumArr,
    isAnyOrderToReturn,
  };
};

const handleFindNextAvailableCell = (state) => {
  const { nextAvailableCellIndex, nextAllAvailableCells } = state;

  const nextIndex = nextAvailableCellIndex + 1;
  const nextCell = nextAllAvailableCells[nextIndex] || undefined;
  const nextAvailableCellNumber = nextCell
    ? parseInt(nextCell.lockerName, 10)
    : undefined;

  return {
    ...state,
    nextAvailableCellIndex: nextIndex,
    nextAvailableCellNumber,
  };
};

export default LockersCellsReducer;
