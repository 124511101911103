import React from "react"

import { OrangeButton, OrderDetailsList } from "../../common"
import Modal from "react-modal"

import "./style.css"

const OrderConfirmationModal = ({
  dirRtl,
  show,
  onOkClick,
  onCancelClick,
  items,
  translations,
}) => {
  if (!show) {
    return <noscript />
  }

  const {
    order_details_modal_title,
    order_details_modal_text,
    amount,
    item,
    ok_text,
    back_btn_text,
  } = translations

  const filteredItems = items.filter((item) => item.title && item.amount)
  items = filteredItems

  return (
    <Modal
      isOpen={show}
      onRequestClose={onCancelClick}
      className="react-modal-content tall-modal"
      overlayClassName="modal-overlay"
      dir={dirRtl ? "rtl" : "ltr"}
    >
      <div className="new-order-modal-title">{order_details_modal_title}</div>
      <OrderDetailsList
        HeaderItems={[item, amount]}
        Items={items}
        modalOrderDetailList
      />

      <div className="new-order-modal-footer">
        <div className="new-order-modal-text">{order_details_modal_text}</div>
        <div className="new-order-modal-btns" dir={dirRtl ? "rtl" : "ltr"}>
          <OrangeButton onClick={() => onOkClick()}>{ok_text}</OrangeButton>
          <OrangeButton onClick={() => onCancelClick()}>
            {back_btn_text}
          </OrangeButton>
        </div>
      </div>
    </Modal>
  )
}

export default OrderConfirmationModal
