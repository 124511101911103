import React, { Component } from "react"
import { connect } from "react-redux"
import BarcodeReader from "react-barcode-reader"
import {
  logScreenEnterance,
  onUserSignInWithDoneId,
  readBgServiceConfiguration,
  readStationIdFromBgService,
  reportSuspiciousBrokenLockers,
  resetAllReducers,
} from "../../../../actions"
import { validation } from "../../../../utils/Validations"
import ErrorInfoModal from "../../../Modals/ErrorInfoModal"
import { FixedFooter, FixedHeader } from "../../../reduxified"
import OrangeLockerDoneLogin from "../../../../assets/images/orange_locker_done_login.png"
import ManCellPhone from "../../../../assets/svg/man_cell_phone.svg"
import OrangeArrow from "../../../../assets/svg/orange_arrow.svg"
import LoginWithPassShape from "../../../../assets/svg/login_with_pass_shape.svg"
import "./style.css"
import { WhiteButton } from "../../../common"
import ErrorInfoWithLinkModal from "../../../Modals/ErrorInfoWithLinkModal"
import LoaderModal from "../../../Modals/LoaderModal"

class DoneLoginScreen extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      showScanError: false,
      showErrorModal: false,
      showBaseErrorModal: false,
      serviceName: "",
    }

    this.state = this.INIT_STATE
    this.onGetBarcode = this.onGetBarcode.bind(this)
    this.handleScanError = this.handleScanError.bind(this)
  }

  componentDidMount() {
    this.setState(this.INIT_STATE)

    this.props.resetAllReducers()
    this.props.readStationIdFromBgService()
    this.props.readBgServiceConfiguration()
    this.props.logScreenEnterance("DONE_LOGIN_SCREEN")

    this.setState({ serviceName: this.props.history.location.state })
  }

  componentWillUnmount() {
    this.setState(this.INIT_STATE)
  }

  componentWillReceiveProps(newProps) {
    const {
      authenticated,
      errTitle,
      errText,
      isRefillDetails,
      stationId,
      isError,
    } = newProps
    this.setState({ showBaseErrorModal: isError })

    if (authenticated) {
      const { serviceName } = this.state
      const { history } = this.props

      if (isRefillDetails) {
        history.push("CompleteUserDetails", serviceName)
      }

      if (!isRefillDetails) {
        if (serviceName === "laundry" || serviceName === "driversLogin") {
          history.push("UserMenuScreen")
        }
        if (serviceName === "rent") {
          history.push("RentLockerMenuScreen")
        }
        if (serviceName === "lockerDelivery") {
          history.push("LockerToLockerDeliveryScreen/info")
        }
      }
    }

    const { errTitle: oldErrTitle, errText: oldErrText } = this.props

    if (oldErrTitle !== errTitle || oldErrText !== errText) {
      this.setState({
        showErrorModal: true,
      })
    }

    if (stationId === -1) {
      this.props.history.push("MaintenanceScreen")
      return
    }
  }

  onGetBarcode(barcode) {
    if (this.props.isLoadingRequest) {
      return
    }
    const isBarcodeValid = validation.BarcodeGuid(barcode)

    if (!isBarcodeValid) {
      this.handleScanError(barcode)
      return
    }

    this.setState({ showScanError: false, showErrorModal: false })
    this.props.onUserSignInWithDoneId(barcode)
  }

  handleScanError(res) {
    console.log("Scan barcode error:")
    this.setState({ showScanError: true })
  }

  render() {
    const { showScanError, showErrorModal, showBaseErrorModal, serviceName } =
      this.state

    const {
      errTitle,
      errText,
      dirRtl,
      close_text,
      login_laundry,
      base_error_title,
      base_error_text,
      enter,
      history,
      done_login_text,
      done_login_error_title,
      done_login_error_text,
      to_password_button_text,
      enter_with_mobile_and_password,
      retry,
      isLoadingRequest,
      please_wait,
    } = this.props

    return (
      <div className={"ls-wrapper done-login"}>
        <FixedHeader
          title={login_laundry}
          subTitle=""
          buttonsFlag="userLoginScreen"
        />

        <div className="done-login-container">
          <div className="enter-text">
            <h1>{enter}</h1>
            <h2>
              {done_login_text.split("\n").map((str) => (
                <p>{str}</p>
              ))}
            </h2>
          </div>

          <div className="barcode-reader-info">
            <img
              className="orange-arrow-icon"
              src={OrangeArrow}
              alt="Orange Arrow"
            />
            <img
              className="orange-locker-done-login-icon"
              src={OrangeLockerDoneLogin}
              alt="Orange Locker Done Login"
            />
            <img
              className="man-cell-phone-icon"
              src={ManCellPhone}
              alt="Man Cell Phone"
            />
          </div>

          <div>
            <WhiteButton
              extraClass="to-user-login-button"
              onClick={() => {
                this.props.history.push("/UserLoginScreen", serviceName)
              }}
            >
              <div className="login-with-pass-shape">
                <img src={LoginWithPassShape} />
              </div>
              <div>
                {to_password_button_text.split("\n").map((str) => (
                  <p>{str}</p>
                ))}
              </div>
            </WhiteButton>
          </div>
          <BarcodeReader
            minLength={5}
            onError={(e) => this.handleScanError(e)}
            onScan={(e) => this.onGetBarcode(e)}
          />
        </div>

        <LoaderModal show={isLoadingRequest} title={please_wait} />

        <ErrorInfoWithLinkModal
          dirRtl={dirRtl}
          show={(showErrorModal && errTitle && errText) || showScanError}
          title={done_login_error_title}
          text={done_login_error_text}
          closeTrnsl={retry}
          onModalCloseClick={() => {
            this.setState({ showErrorModal: false, showScanError: false })
          }}
          linkText={enter_with_mobile_and_password}
          onLinkClick={() =>
            this.props.history.push("/UserLoginScreen", serviceName)
          }
        />

        <ErrorInfoModal
          dirRtl={dirRtl}
          show={showBaseErrorModal}
          title={base_error_title}
          text={base_error_text}
          onModalCloseClick={() => this.setState({ showBaseErrorModal: false })}
          closeTrnsl={close_text}
        />

        <FixedFooter history={history} />
      </div>
    )
  }
}

const mapStateToProps = ({ userLogin, bgService, translate }) => {
  const { stationId } = bgService
  const {
    authenticated,
    errTitle,
    errText,
    isLoadingRequest,
    isRefillDetails,
    userId,
    isError,
  } = userLogin
  const {
    dirRtl,
    langName,
    close_text,
    login_laundry,

    enter,
    base_error_title,
    base_error_text,
    done_login_text,
    done_login_error_title,
    done_login_error_text,
    to_password_button_text,
    enter_with_mobile_and_password,
    retry,
    please_wait,
  } = translate

  return {
    authenticated,
    errTitle: translate[errTitle],
    errText: translate[errText],
    isLoadingRequest,
    userId,
    stationId,
    isError,
    enter,
    dirRtl,
    langName,
    close_text,
    login_laundry,
    base_error_title,
    base_error_text,
    isRefillDetails,
    done_login_text,
    done_login_error_title,
    done_login_error_text,
    to_password_button_text,
    enter_with_mobile_and_password,
    retry,
    please_wait,
  }
}

export default connect(mapStateToProps, {
  onUserSignInWithDoneId,
  readStationIdFromBgService,
  readBgServiceConfiguration,
  resetAllReducers,
  reportSuspiciousBrokenLockers,
  logScreenEnterance,
})(DoneLoginScreen)
