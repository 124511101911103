import axios from "axios";
import api from "../api";
import { reportFBEvent } from "./FBEventsActions";

import {
  ON_READY_ORDERS_SUCCESS,
  ON_READY_ORDERS_FAIL,
  SET_NEW_CREATED_ORDER_ITEMS,
  SET_NEW_CREATED_ORDER,
} from "./types";

export const onGetReadyOrders = (stationId) => {
  return (dispatch) => {
    axios
      .get(api.readyOrders(stationId))
      .then((res) => {
        // const readyOrders = res.data;
        // // todo change to map!
        // let newReadyOrders = [];
        // readyOrders.forEach(element => {
        //     const { orderNumber: orderId, customerName } = element;
        //     newReadyOrders.push({ orderId, customerName });
        // });

        dispatch({
          type: ON_READY_ORDERS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);

        dispatch({
          type: ON_READY_ORDERS_FAIL,
        });
      });
  };
};

export const setNewCreatedOrderItems = (items, couponId) => {
  return {
    type: SET_NEW_CREATED_ORDER_ITEMS,
    payload: { items, couponId }, //TODO add orderType
  };
};

export const setNewCreatedOrder = (order) => {
  return {
    type: SET_NEW_CREATED_ORDER,
    payload: { order }, //TODO add orderType
  };
};

export const setOrderRequest = (request) => {
  return (dispatch) => {
    dispatch({
      type: SET_NEW_CREATED_ORDER,
      payload: { request },
    });
    dispatch(
      reportFBEvent(
        "Continue_Order_Request",
        "Set request " + request.orderNumber + " to continue order."
      )
    );
  };
};
