import {
  FETCH_ORDER_BY_DELIVERY_CODE_SUCCESS,
  FETCH_ORDER_BY_DELIVERY_CODE_FAIL,
  SET_LOADING_TO_FETCH_ORDER_BY_DELIVERY_CODE,
  RESET_DELIVERY_REDUCER,
  ON_USER_SIGN_OUT,
  RESET_ALL_REDUCERS,
} from "../actions/types"

const INIT_STATE = {
  isDeliveryByCodeLoaded: false,
  deliveryByCodeError: false,
  deliveryCode: "",
  userActiveDelivery: null,
  /*
    userId: null,
    role: null,
    firstName: null,
    lastName: null,
    token: null,
    authenticated: false,
    creditCardToken: null,
    */
  isLoadingRequest: false,
  errTitle: null,
  errText: null,
  showErrorModal: false,
  displayedOrder: null,
  numOfReadyOrdersToCollect: 0,
}

const DeliveryReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_LOADING_TO_FETCH_ORDER_BY_DELIVERY_CODE:
      return handleLoading(state)
    case FETCH_ORDER_BY_DELIVERY_CODE_SUCCESS:
      return handArrivedActiveDeliveryResponse(state, action.payload)
    case FETCH_ORDER_BY_DELIVERY_CODE_FAIL:
      return handleErrorResponse(state, action.payload)
    case ON_USER_SIGN_OUT:
    case RESET_DELIVERY_REDUCER:
      return {
        ...INIT_STATE,
      }

    case RESET_ALL_REDUCERS:
      return {
        ...INIT_STATE,
      }
    default:
      return state
  }
}
const handleLoading = (state) => {
  return {
    ...state,
    isLoadingRequest: true,
    deliveryByCodeError: false,
    showErrorModal: false,
  }
}
const handleErrorResponse = (state, error) => {
  return {
    ...state,
    isDeliveryByCodeLoaded: false,
    deliveryByCodeError: true,
    deliveryCode: "",
    /*
        userId: null,
        role: null,
        firstName: null,
        lastName: null,
        token: null,
        authenticated: false,
        creditCardToken: null,
        */
    isLoadingRequest: false,
    errTitle: error.status,
    errText: error.status,
    showErrorModal: true,
    numOfReadyOrdersToCollect: 0, // EXCEPT THE CURRENT ONE (len - 1..)
  }
}

const handArrivedActiveDeliveryResponse = (state, userActiveDelivery) => {
  const displayedOrder = userActiveDelivery.order

  //const { userId,role,firstName,lastName,token,creditCardToken}= userActiveDelivery.user;

  return {
    ...state,
    ...userActiveDelivery,
    isDeliveryByCodeLoaded: true,
    deliveryByCodeError: false,
    lodeliveryCodeginCode: "",
    /*
        userId: userId,
        role: role,
        firstName:firstName,
        lastName: lastName,
        token: token,
        authenticated: true,
        creditCardToken: creditCardToken,
        */
    isLoadingRequest: false,
    errTitle: null,
    errText: null,
    showErrorModal: false,
    displayedOrder,
    numOfReadyOrdersToCollect: 0, // EXCEPT THE CURRENT ONE (len - 1..)
  }
}

export default DeliveryReducer
