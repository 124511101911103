import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import {
  logScreenEnterance,
  reportFBEvent,
  fetchAvailableLockersStatus,
  setLockerToLockerDeliveryOrderLoading,
  //getOrdersRequests,
} from "../../../../../actions"
import { FixedFooter, FixedHeader } from "../../../../reduxified"
import WarningInfoModal from "../../../../Modals/WarningInfoModal"
import infoDeliveryGirl from "../../../../../assets/svg/info_delivery_girl.svg"
import "./style.css"
import { DisEnbPendBtn } from "../../../../common"

class LockerToLockerDeliveryInfo extends Component {
  constructor(props) {
    super(props)
    this.INIT_STATE = {
      showOutOfLockersModal: false,
      showOutOfLockersUpdated: false,
    }
    this.state = this.INIT_STATE
  }

  componentDidMount() {
    const { authenticated, stationId, userId } = this.props

    this.props.logScreenEnterance("LOCKER_TO_LOCKER_DELIVERY_INFO")
    if (authenticated) {
      this.props.setLockerToLockerDeliveryOrderLoading()
      this.props.fetchAvailableLockersStatus(stationId)
      //this.props.getOrdersRequests(stationId, userId, 5)
    }

    this.setState({ showOutOfLockersUpdated: false })
  }

  componentWillUpdate(newProps, nextState) {
    const { authenticated, isLoading, isNoAvailableLockersForService } =
      newProps

    const { showOutOfLockersUpdated } = nextState
    //SHOW NOT AVAILABLE POPUP
    if (
      authenticated &&
      !isLoading &&
      isNoAvailableLockersForService &&
      !showOutOfLockersUpdated
    ) {
      this.openOutOfLockersModal()
    }
  }

  openOutOfLockersModal() {
    this.setState({
      showOutOfLockersModal: true,
      showOutOfLockersUpdated: true,
    })
  }

  closeOutOfLockersModal() {
    this.setState({ showOutOfLockersModal: false })
  }

  onContinueButtonClick() {
    const { authenticated } = this.props
    if (!authenticated) {
      this.props.history.push("/UserLoginWithDoneLoginScreen", "lockerDelivery")
      return
    }

    this.props.history.push("/LockerToLockerDeliveryScreen/Menu")
  }

  render() {
    const {
      locker_to_locker_info_title,
      locker_to_locker_info_point_1,
      locker_to_locker_info_point_2,
      locker_to_locker_info_point_3,
      locker_to_locker_info_point_4,
      locker_to_locker_info_thanks,
      continue_text,
      close_text,
      dirRtl,
      all_lockers_full_err_title,
      all_lockers_full_err_body,
      isNoAvailableLockersForService,
      isLoading,
      authenticated,
    } = this.props

    const { showOutOfLockersModal } = this.state

    const point1 =
      locker_to_locker_info_point_1 !== undefined
        ? locker_to_locker_info_point_1.split(/\r?\n/)
        : []
    const point2 =
      locker_to_locker_info_point_2 !== undefined
        ? locker_to_locker_info_point_2.split(/\n/)
        : []
    const point3 =
      locker_to_locker_info_point_3 !== undefined
        ? locker_to_locker_info_point_3.split(/\r?\n/)
        : []
    const point4 =
      locker_to_locker_info_point_4 !== undefined
        ? locker_to_locker_info_point_4.split(/\r?\n/)
        : []

    const titleButtonFlag = authenticated
      ? "unlockLockersScreen"
      : "userLoginScreen"

    return (
      <>
        <div
          className={
            "ls-wrapper locker-to-locker-info " + (dirRtl ? "rtl" : "ltr")
          }
          dir={dirRtl ? "rtl" : "ltr"}
        >
          <FixedHeader buttonsFlag={titleButtonFlag} />

          <div className="title">{locker_to_locker_info_title}</div>
          <div className="info-wrapper">
            <div className="info-icon">
              <img src={infoDeliveryGirl} />
            </div>
            <div className="info-text">
              <div className="item">
                <div className="oval"></div>
                <div className="text">
                  {point1.map((txt) => (
                    <p>{txt}</p>
                  ))}
                </div>
              </div>
              <div className="item">
                <div className="oval"></div>
                <div className="text">
                  {point2.map((txt) => (
                    <p>{txt}</p>
                  ))}
                </div>
              </div>
              <div className="item">
                <div className="oval"></div>
                <div className="text">
                  {point3.map((txt) => (
                    <p>{txt}</p>
                  ))}
                </div>
              </div>
              <div className="item">
                <div className="oval"></div>
                <div className="text">
                  {point4.map((txt) => (
                    <p>{txt}</p>
                  ))}
                </div>
              </div>
              <div className="item">{locker_to_locker_info_thanks}</div>
            </div>
          </div>
          <DisEnbPendBtn
            extraClass={"continue-button"}
            onClick={() => this.onContinueButtonClick()}
            isDisabled={isNoAvailableLockersForService}
            isPending={isLoading}
          >
            {continue_text}
          </DisEnbPendBtn>

          <FixedFooter history={this.props.history} />
        </div>

        <WarningInfoModal
          show={showOutOfLockersModal}
          title={all_lockers_full_err_title}
          text={all_lockers_full_err_body}
          onModalCloseClick={() => this.closeOutOfLockersModal()}
          btnText={close_text}
        />
      </>
    )
  }
}

const mapStateToProps = ({
  userLogin,
  translate,
  bgService,
  lockersCells,
  lockerToLockerDelivery,
}) => {
  const { authenticated, userId } = userLogin

  const {
    dirRtl,
    locker_to_locker_info_title,
    locker_to_locker_info_point_1,
    locker_to_locker_info_point_2,
    locker_to_locker_info_point_3,
    locker_to_locker_info_point_4,
    locker_to_locker_info_thanks,
    continue_text,
    all_lockers_full_err_title,
    all_lockers_full_err_body,
    close_text,
  } = translate

  const { stationId } = bgService
  const {
    isNoAvailableLockersForService,
    isLoading,
    //ordersRequestsList,
  } = lockerToLockerDelivery

  return {
    authenticated,
    userId,

    dirRtl,
    locker_to_locker_info_title,
    locker_to_locker_info_point_1,
    locker_to_locker_info_point_2,
    locker_to_locker_info_point_3,
    locker_to_locker_info_point_4,
    locker_to_locker_info_thanks,
    continue_text,
    stationId,
    all_lockers_full_err_title,
    all_lockers_full_err_body,
    close_text,

    isNoAvailableLockersForService,
    isLoading,
    //ordersRequestsList,
  }
}

export default connect(mapStateToProps, {
  logScreenEnterance,
  reportFBEvent,
  fetchAvailableLockersStatus,
  setLockerToLockerDeliveryOrderLoading,
  //getOrdersRequests,
})(withRouter(LockerToLockerDeliveryInfo))
