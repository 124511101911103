import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { FixedFooter, FixedHeader } from "../../../../reduxified"

import { validation } from "../../../../../utils"
import { DisEnbPendBtn, InputFiled, WhiteButton } from "../../../../common"
import VirtalKeyboard from "../../../../common/VirtualKeyboard"
import ErrorInfoModal from "../../../../Modals/ErrorInfoModal"
//import ConfirmCreateOrderModal from "../../../../Modals/ConfirmCreateOrderModal"
import Modal from "react-modal"
import {
  logScreenEnterance,
  reportFBEvent,
  setLockerToLockerDeliveryOrder,
  createLockerToLokcerDeliveryNewOrder,
  setLockerToLockerDeliveryOrderLoading,
  LogoutUser,
} from "../../../../../actions"

import "./style.css"

class AddresseeDetails extends Component {
  constructor(props) {
    super(props)

    this.INIT = {
      firstName: "",
      lastName: "",
      mobile: "",
      mobileVerification: "",

      firstNameRef: null,
      lastNameRef: null,
      mobileRef: null,
      mobileVerificationRef: null,

      activeInput: null,
      activeInputName: null,

      mobileErr: false,
      mobileVerificationErr: false,
      firstNameErr: false,
      lastNameErr: false,
      isSubmitBtnDisabled: true,
      errorsTimeout: null,
      showErrorModal: false,
      runValidationsTimeOut: null,
      showSummaryModal: false,
    }

    this.state = this.INIT
  }

  componentWillUnmount() {
    this.state = this.INIT
  }

  componentDidMount() {
    const { authenticated } = this.props

    if (!authenticated) {
      this.props.reportFBEvent(
        "AddresseeDetailsScreen",
        "Not authenticated. Redirect to homepage."
      )
      this.props.LogoutUser()
      this.props.history.push("")
    }
    this.props.logScreenEnterance("ADDRESSEE_DETAILS")

    this.runValidationsWithTimoutDebounce()
  }

  componentWillReceiveProps(newProps) {
    const { authenticated } = newProps
    if (!authenticated) {
      this.props.reportFBEvent(
        "AddresseeDetailsScreen",
        "Not authenticated. Redirect to homepage."
      )
      this.props.LogoutUser()
      this.props.history.push("")
    }
    const { createdOrder } = newProps

    if (createdOrder) {
      this.props.history.push("/LockerToLockerDeliveryScreen/PayOrder")
    }
  }

  onKeyboardTextChange(text) {
    const { activeInputName, errorsTimeout } = this.state

    if (errorsTimeout) {
      clearTimeout(errorsTimeout)
    }

    this.setState({
      [activeInputName]: text,
      mobileErr: false,
      mobileVerificationErr: false,
      firstNameErr: false,
      lastNameErr: false,
      errorsTimeout: null,
    })

    this.runValidationsWithTimoutDebounce()
  }

  onRefReady(refName, ref) {
    if (!this.state[refName]) {
      this.setState({ [refName]: ref })
    }
  }

  onInputFocusChange(refName, isFocused) {
    if (isFocused) {
      this.setState({
        activeInput: this.state[refName],
        activeInputName: refName.replace("Ref", ""),
      })
    } else {
      if (this.state.activeInput === this.state[refName])
        this.setState({ activeInput: null })
    }
  }

  onContinueClick() {
    this.setState({ showSummaryModal: true })
  }

  onSummaryClose() {
    this.setState({ showSummaryModal: false })
  }

  sendOrderRequest() {
    this.setState({ showSummaryModal: false })
    const { firstName, lastName, mobile } = this.state
    const { userId, stationId, order } = this.props

    const data = {
      stationId: stationId,
      locationId: userId,
      customerId: userId, //like sourceCustomerId will updated on server to mobilePhone customer
      firstName: lastName,
      lastName: firstName,
      mobilePhone: mobile,
      couponId: "",
      orderType: 5,
      orderItems: [
        {
          quantity: 1,
          processName: "Shipment",
        },
      ],
      sourceCustomerId: userId,
      sourceStationId: stationId,
      targetStationId: order.targetStationId,
      orderId: "",
    }
    this.props.setLockerToLockerDeliveryOrderLoading()
    this.props.setLockerToLockerDeliveryOrder(data)
    this.props.createLockerToLokcerDeliveryNewOrder(data)
  }

  runValidations() {
    const { mobile, firstName, lastName, mobileVerification } = this.state
    const { userMobile } = this.props
    //firstName, lastName
    const firstNameLastNameEmpty = firstName.length < 1 || lastName.length < 1
    const isFirstNameValid = validation.Name(firstName)
    const isLastNameValid = validation.Name(lastName)

    //mobile
    const isMobileValid =
      validation.Mobile05(mobile) &&
      validation.Mobile(mobile) &&
      mobile != userMobile
    const isEmptyMobile = mobile.length < 1
    const mobileErr = !isMobileValid && !isEmptyMobile
    //mobileVerificationErr
    const mobileVerificationErr = mobile !== mobileVerification

    const isSubmitBtnDisabled =
      !isMobileValid ||
      firstNameLastNameEmpty ||
      mobileVerificationErr ||
      !isFirstNameValid ||
      !isLastNameValid

    if (!isSubmitBtnDisabled) {
      this.setState({
        isSubmitBtnDisabled,
        mobileErr,
        mobileVerificationErr,
        firstNameErr: !isFirstNameValid,
        lastNameErr: !isLastNameValid,
        runValidationsTimeOut: null,
      })
    } else {
      const errorsTimeout = setTimeout(
        () =>
          this.setState({
            isSubmitBtnDisabled,
            mobileErr,
            mobileVerificationErr,
            firstNameErr: !isFirstNameValid,
            lastNameErr: !isLastNameValid,
            runValidationsTimeOut: null,
          }),
        800
      )

      this.setState({ errorsTimeout })
    }
  }

  onInputFieldChange(inputName, val) {
    this.setState({ [inputName]: val })
    this.runValidationsWithTimoutDebounce()
  }

  runValidationsWithTimoutDebounce() {
    let { runValidationsTimeOut } = this.state

    if (runValidationsTimeOut) {
      clearTimeout(runValidationsTimeOut)
    }

    runValidationsTimeOut = setTimeout(() => this.runValidations(), 500)

    this.setState({ runValidationsTimeOut })
  }

  onCloseErrorModal() {
    this.setState(this.INIT)
    this.props.resetCompleteDetails()
  }

  render() {
    const {
      firstName,
      lastName,
      mobile,
      mobileVerification,
      activeInputName,
      mobileErr,
      mobileVerificationErr,
      firstNameErr,
      lastNameErr,
      isSubmitBtnDisabled,
      showErrorModal,
      showSummaryModal,
    } = this.state

    const {
      dirRtl,
      addressee_title,

      first_name_input_hint,
      last_name_input_hint,
      mobile_input_hint,
      mobile_verification_input_hint,
      next_text,

      validationtext,
      password_update_error_title,
      password_update_error_text,
      close_text,
      isLoading,

      locker_to_locker_summer_title,
      locker_to_locker_summer_sub_title,
      locker_to_locker_summer_addrssee_name,
      locker_to_locker_summer_addrssee_mobile,
      locker_to_locker_summer_addrssee_address,
      back_btn_text,
      can_not_send_to_your_self,
      ok_text,
      targetStationName,
      targetStationAddress,
      userMobile,
    } = this.props
    const keyboardLang =
      activeInputName === "mobile" || activeInputName === "mobileVerification"
        ? "mobile"
        : "he"
    const maxLength =
      activeInputName === "mobile" || activeInputName === "mobileVerification"
        ? 10
        : 30
    const wrapperClassName = `${dirRtl ? "dirrtl" : "dirltr"}`

    const validationMobileText =
      mobile == userMobile && mobile && mobile.length > 0
        ? can_not_send_to_your_self
        : validationtext.mobile

    return (
      <div className="sus1s-wrapper addressee-wrapper">
        <FixedHeader buttonsFlag="unlockLockersScreen" />
        <div className="addressee_title">{addressee_title}</div>
        <div className="sus1s-body" dir={dirRtl ? "rtl" : "ltr"}>
          <div className="sus1s-frame-container">
            <div className="top-90">
              <div className="sus1s-frame-wrapper">
                <div className="sus1s-frame-inner-wrapper">
                  <InputFiled
                    value={firstName}
                    onChange={(e) =>
                      this.onInputFieldChange("firstName", e.target.value)
                    }
                    placeholder={first_name_input_hint}
                    onRefReady={(ref) => this.onRefReady("firstNameRef", ref)}
                    onFocusChanged={(isFocused) =>
                      this.onInputFocusChange("firstNameRef", isFocused)
                    }
                    mandatory
                    validationtext={validationtext.name}
                    icon="name"
                    dirRtl={dirRtl}
                    displayErr={firstNameErr}
                    disabled={isLoading}
                    tabindex={1}
                    maxLength={50}
                  />
                  <InputFiled
                    value={lastName}
                    onChange={(e) =>
                      this.onInputFieldChange("lastName", e.target.value)
                    }
                    placeholder={last_name_input_hint}
                    onRefReady={(ref) => this.onRefReady("lastNameRef", ref)}
                    onFocusChanged={(isFocused) =>
                      this.onInputFocusChange("lastNameRef", isFocused)
                    }
                    mandatory
                    validationtext={validationtext.name}
                    icon="name"
                    dirRtl={dirRtl}
                    displayErr={lastNameErr}
                    disabled={isLoading}
                    tabindex={2}
                    maxLength={50}
                  />
                </div>

                <div className="sus1s-frame-inner-wrapper">
                  <InputFiled
                    value={mobile}
                    onChange={(e) =>
                      this.onInputFieldChange("mobile", e.target.value)
                    }
                    placeholder={mobile_input_hint}
                    onRefReady={(ref) => this.onRefReady("mobileRef", ref)}
                    onFocusChanged={(isFocused) =>
                      this.onInputFocusChange("mobileRef", isFocused)
                    }
                    displayErr={mobileErr}
                    //isLoading={loadingIfMobileTakenInBg}
                    mandatory
                    dirRtl={dirRtl}
                    maxLength={10}
                    type="tel"
                    icon="mini_phone"
                    validationtext={validationMobileText}
                    disabled={isLoading}
                    tabindex={3}
                  />
                  <InputFiled
                    value={mobileVerification}
                    onChange={(e) =>
                      this.onInputFieldChange(
                        "mobileVerification",
                        e.target.value
                      )
                    }
                    placeholder={mobile_verification_input_hint}
                    onRefReady={(ref) =>
                      this.onRefReady("mobileVerificationRef", ref)
                    }
                    onFocusChanged={(isFocused) =>
                      this.onInputFocusChange(
                        "mobileVerificationRef",
                        isFocused
                      )
                    }
                    displayErr={mobileVerificationErr}
                    mandatory
                    maxLength={10}
                    type="tel"
                    validationtext={validationtext.mobileVerification}
                    icon="mini_phone"
                    dirRtl={dirRtl}
                    disabled={isLoading}
                    tabindex={4}
                  />
                </div>

                <DisEnbPendBtn
                  extraClass="btn-margin"
                  onClick={(e) => this.onContinueClick()}
                  isDisabled={isSubmitBtnDisabled}
                  isPending={isLoading}
                >
                  {next_text}
                </DisEnbPendBtn>
              </div>
            </div>
          </div>
        </div>
        <VirtalKeyboard
          currentValue={this.state[activeInputName]}
          onKeyboardInputChange={(txt) => this.onKeyboardTextChange(txt)}
          show={this.state.activeInput}
          lang={keyboardLang}
          closeOnEnter
          maxLength={maxLength}
        />

        <ErrorInfoModal
          dirRtl={dirRtl}
          show={showErrorModal}
          title={password_update_error_title}
          text={password_update_error_text}
          onModalCloseClick={() => this.onCloseErrorModal()}
          closeTrnsl={close_text}
        />

        <FixedFooter history={this.props.history} />

        <Modal
          className={`react-modal-content summary ${wrapperClassName}`}
          overlayClassName="modal-overlay"
          onRequestClose={() => this.onSummaryClose()}
          isOpen={showSummaryModal}
        >
          <div className="summary-wrapper">
            <p className="summary-title">{locker_to_locker_summer_title}</p>
            <p>
              <div className="summary-sub-title">
                {locker_to_locker_summer_sub_title}
              </div>
              <div>
                <div className="summary-label">
                  {" "}
                  {locker_to_locker_summer_addrssee_name}
                </div>
                <div className="summary-text">
                  {firstName} {lastName}
                </div>
              </div>
              <div>
                <div className="summary-label">
                  {locker_to_locker_summer_addrssee_mobile}
                </div>
                <div className="summary-text">{mobile}</div>
              </div>
              <div>
                <div className="summary-label">
                  {locker_to_locker_summer_addrssee_address}
                </div>
                <div className="summary-text">
                  <div>{targetStationName}</div>
                  <div>{targetStationAddress}</div>
                </div>
              </div>
            </p>
            <DisEnbPendBtn
              extraClass="btn-margin"
              onClick={(e) => this.sendOrderRequest()}
              isDisabled={isSubmitBtnDisabled}
              isPending={isLoading}
            >
              {ok_text}
            </DisEnbPendBtn>

            <div
              className="header-button"
              role="button"
              alt={back_btn_text}
              onClick={(e) => this.onSummaryClose()}
            >
              {back_btn_text}
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = ({
  translate,
  userLogin,
  bgService,
  lockerToLockerDelivery,
}) => {
  const { stationId } = bgService
  const { authenticated, userId, mobile } = userLogin
  const userMobile = mobile
  const { order, isLoading, createdOrder, stations } = lockerToLockerDelivery
  const {
    dirRtl,
    first_name_input_hint,
    last_name_input_hint,
    mobile_input_hint,
    mobile_verification_input_hint,
    next_text,
    signup_cancel_modal_title,
    signup_cancel_modal_body,
    err_title_text,
    ok_text,
    cancel_text,
    close_text,
    create_new_user_exist_mobile_modal_title,
    create_new_user_exist_mobile_modal_text,
    validation_empty_text,
    validation_mobile_text,
    validation_password_text,
    validation_password_verification_text,
    validation_name_text,
    validation_email_text,
    validation_email_verification_text,
    validation_mobile_verification_text,
    password_update_error_title,
    password_update_error_text,
    addressee_title,
    locker_to_locker_summer_title,
    locker_to_locker_summer_sub_title,
    locker_to_locker_summer_addrssee_name,
    locker_to_locker_summer_addrssee_mobile,
    locker_to_locker_summer_addrssee_address,
    back_btn_text,
    can_not_send_to_your_self,
  } = translate

  let validationtext = {
    empty: validation_empty_text,
    mobile: validation_mobile_text,
    password: validation_password_text,
    passwordVerification: validation_password_verification_text,
    name: validation_name_text,
    email: validation_email_text,
    emailVerification: validation_email_verification_text,
    mobileVerification: validation_mobile_verification_text,
  }

  const targetStation = order
    ? stations.filter((s) => s.stationId == order.targetStationId)
    : null
  let targetStationName = ""
  let targetStationAddress = ""
  if (targetStation && targetStation.length > 0) {
    targetStationName = targetStation[0].name
    targetStationAddress = targetStation[0].address
  }

  return {
    authenticated,
    userId,
    userMobile,
    dirRtl,
    first_name_input_hint,
    last_name_input_hint,
    mobile_input_hint,
    mobile_verification_input_hint,
    next_text,
    signup_cancel_modal_title,
    signup_cancel_modal_body,
    err_title_text,
    ok_text,
    cancel_text,
    close_text,
    create_new_user_exist_mobile_modal_title,
    create_new_user_exist_mobile_modal_text,
    validationtext,
    password_update_error_title,
    password_update_error_text,
    close_text,
    addressee_title,
    locker_to_locker_summer_title,
    locker_to_locker_summer_sub_title,
    locker_to_locker_summer_addrssee_name,
    locker_to_locker_summer_addrssee_mobile,
    locker_to_locker_summer_addrssee_address,
    back_btn_text,
    can_not_send_to_your_self,

    stationId,
    isLoading,
    order,
    createdOrder,
    targetStationName,
    targetStationAddress,
  }
}

export default connect(mapStateToProps, {
  reportFBEvent,
  logScreenEnterance,
  setLockerToLockerDeliveryOrder,
  createLockerToLokcerDeliveryNewOrder,
  setLockerToLockerDeliveryOrderLoading,
  LogoutUser,
})(withRouter(AddresseeDetails))
