import React, { Component } from "react"
import packageGuy from "../../../assets/svg/package_guy.svg"
import deliveryGirl from "../../../assets/svg/delivery_girl.svg"
import laundryGuy from "../../../assets/svg/laundry_guy.svg"
import lockerToRent from "../../../assets/svg/rent_locker.svg"
import "./style.css"

class ServiceMenuHorizontalButton extends Component {
  imageSrc(serviceType) {
    if (serviceType === "laundry") {
      return laundryGuy
    }
    if (serviceType === "delivery") {
      return packageGuy
    }
    if (serviceType === "locker-delivery") {
      return deliveryGirl
    }
    if (serviceType === "locker-rent") {
      return lockerToRent
    }
  }

  render() {
    const { show, serviceType, onClick } = this.props
    let { text } = this.props
    text = text != null ? text : ""
    if (show == undefined || !show) {
      return <noscript></noscript>
    }

    return (
      <div className="service-menu-horizontal-button" dir="rtl" onClick={(e) => onClick(e)}>
        <div className="orange-part">
          <img
            className={serviceType}
            src={this.imageSrc(serviceType)}
            alt="card_img"
          />
        </div>
        <div className="white-part">
          <div className="card-text">{text}</div>
        </div>
      </div>
    )
  }
}

export default ServiceMenuHorizontalButton
