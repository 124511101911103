import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import StationsMap from "../../../../common/StationsMap"
import { FixedFooter, FixedHeader } from "../../../../reduxified"
import { InputFiled, DisEnbPendBtn, WhiteButton } from "../../../../common"
import maximizeButton from "../../../../../assets/svg/maximize_button.svg"
import minimizeButton from "../../../../../assets/svg/minimize_button.svg"
import VirtualKeyboard from "../../../../common/VirtualKeyboard"
import StationList from "../StationsList"
import {
  logScreenEnterance,
  fetchStationsList,
  setLockerToLockerDeliveryOrder,
  LogoutUser,
} from "../../../../../actions"

//import {ORDER_LockerToLocker_STUB, ORDER_LockerRent_STUB}  from '../../../../../utils/OrderConvert'

import "./style.css"
import Modal from "react-modal"

class SelectStation extends Component {
  constructor(props) {
    super(props)
    this.INIT_STATE = {
      searchText: "",
      selectedCityItem: null,
      stationListItems: [],
      searchRef: null,
      activeInput: null,
      activeInputName: null,
      showKeyBoard: false,
      activeStation: null,
      bigMap: false,
    }

    this.state = this.INIT_STATE
    this.onInputFocusChange.bind(this.state)
    this.onSearchTextChange.bind(this.state)
    this.onStationListItemClick(this.state)
  }

  componentDidMount() {
    const { authenticated, langName } = this.props
    if (!authenticated) {
      this.props.LogoutUser()
      this.props.history.push("")
    }
    this.props.logScreenEnterance("SELECT_STATION")
    this.props.fetchStationsList(langName)
    this.setState({ ...this.state, showKeyBoard: true })
  }

  componentDidUpdate(prevProps) {
    if (this.props.stations != prevProps.stations) {
      this.setSearchedStationsList("")
    }
  }

  setSearchedStationsList(searchText) {
    const { stations } = this.props
    if (!stations) {
      this.setState({ stationListItems: [], activeStation: null })
      return
    }
    if (!searchText || searchText.length === 0 || searchText.target) {
      this.setState({ stationListItems: stations, activeStation: null })
      return
    }
    const stationListItems = stations.filter((s) =>
      this.searchCity(s.city, searchText.toLowerCase())
    )
    this.setState({ stationListItems })
  }

  searchCity(city, searchText) {
    if (city.cityName.toLowerCase().includes(searchText)) {
      return true
    }

    if (city.cityName_en.toLowerCase().includes(searchText)) {
      return true
    }
    if (city.cityName_ru.toLowerCase().includes(searchText)) {
      return true
    }
    return false
  }

  onSearchTextChange(e) {
    if (!e || e.length <= 0 || e.target) {
      this.setState({ searchText: "", activeStation: null })
      this.setSearchedStationsList("")
      return
    }
    this.setState({ searchText: e })
    this.setSearchedStationsList(e)
  }

  onKeyboardTextChange(text) {
    this.setState({
      searchText: text,
    })
    this.setSearchedStationsList(text)
  }
  onRefReady(inputName, ref) {
    if (this.state[`${inputName}Ref`]) {
      return
    }
    this.setState({
      [`${inputName}Ref`]: ref,
    })
  }

  onInputFocusChange(isFocused) {
    this.setState({
      showKeyBoard: isFocused,
    })
  }

  onStationListItemClick(station) {
    this.setState({
      ...this.state,
      activeStation: station,
      showKeyBoard: false,
    })
  }

  onClickNext() {
    //save station
    const { activeStation } = this.state
    if (!activeStation) return
    let { order } = this.props
    order.targetStationId = activeStation.stationId
    this.props.setLockerToLockerDeliveryOrder(order)
    //clear
    this.onClearClick()
    //go next page
    this.props.history.push("/LockerToLockerDeliveryScreen/AddresseeDetails")
  }

  onClearClick() {
    this.setState({
      ...this.state,
      activeStation: null,
      searchText: "",
      stationListItems: [],
      showKeyBoard: true,
    })
  }
  render() {
    const {
      select_city_placeholder,
      dirRtl,
      langName,
      select_station_to_delivery,
      continue_text,
      button_clear_text,
    } = this.props
    const {
      //selectedCityItem,
      stationListItems,
      searchText,
      showKeyBoard,
      activeStation,
    } = this.state

    return (
      <>
        <div className="ls-wrapper">
          <FixedHeader buttonsFlag="unlockLockersScreen" />
          <div className="locker-to-locker-title">
            {select_station_to_delivery}
          </div>
          <div className="select-station-wrapper" dir={dirRtl ? "rtl" : "ltr"}>
            <div className="select-wrapper">
              <InputFiled
                className="search-input"
                value={searchText}
                onChange={(e) => this.onSearchTextChange(e)}
                placeholder={select_city_placeholder}
                onRefReady={(ref) => this.onRefReady("searchText", ref)}
                onFocusChanged={(isFocused) =>
                  this.onInputFocusChange(isFocused)
                }
                maxLength={20}
              />
            </div>
            <div className="clear-button-wrapper">
              <WhiteButton show={true} onClick={() => this.onClearClick()}>
                {button_clear_text}
              </WhiteButton>
            </div>

            <div className="station-list-wrapper ">
              <StationList
                items={stationListItems}
                onClick={(e) => this.onStationListItemClick(e)}
                activeStation={activeStation}
              />
              <div className="map-button-container">
                <button
                  onClick={() => {
                    this.setState({ bigMap: !this.state.bigMap })
                    console.log(this.state.bigMap)
                  }}
                  className={
                    this.state.bigMap ? "minimize-button" : "maximize-button"
                  }
                >
                  <img
                    src={this.state.bigMap ? minimizeButton : maximizeButton}
                    alt="resize_button"
                    className="resize-btn-img"
                  />
                </button>
                <StationsMap
                  stationsList={stationListItems}
                  activeStation={activeStation}
                  onClick={(e) => this.onStationListItemClick(e)}
                />
              </div>
            </div>
            <div
              className={
                activeStation && showKeyBoard
                  ? //? "continue-button-wrapper active"
                    "continue-button-wrapper"
                  : "continue-button-wrapper"
              }
            >
              <DisEnbPendBtn
                onClick={(e) => this.onClickNext()}
                isDisabled={!activeStation}
                isPending={false}
                extraClass="login-button"
              >
                {continue_text}
              </DisEnbPendBtn>
            </div>
          </div>

          <FixedFooter history={this.props.history} />
        </div>
        <VirtualKeyboard
          currentValue={searchText}
          onKeyboardInputChange={(txt) => this.onKeyboardTextChange(txt)}
          show={showKeyBoard}
          lang={langName}
        />
        <Modal
          isOpen={this.state.bigMap}
          className="react-modal-content big-react-modal padding-0"
          overlayClassName="modal-overlay"
          onRequestClose={() => this.setState({ bigMap: false })}
        >
          <div className="map-button-container">
            <button
              onClick={() => {
                this.setState({ bigMap: !this.state.bigMap })
                console.log(this.state.bigMap)
              }}
              className={
                this.state.bigMap ? "minimize-button" : "maximize-button"
              }
            >
              <img
                src={this.state.bigMap ? minimizeButton : maximizeButton}
                alt="resize_button"
                className="resize-btn-img"
              />
            </button>
            <StationsMap
              stationsList={stationListItems}
              activeStation={activeStation}
              onClick={(e) => this.onStationListItemClick(e)}
              bigMapClass="big-map"
            />
          </div>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = ({
  userLogin,
  translate,
  bgService,
  lockerToLockerDelivery,
}) => {
  const { authenticated } = userLogin

  const {
    dirRtl,
    langName,
    select_city_placeholder,
    select_station_to_delivery,
    continue_text,
    button_clear_text,
  } = translate

  const { stationId } = bgService

  const { stations, cityItemsList, order } = lockerToLockerDelivery

  return {
    authenticated,
    dirRtl,
    langName,
    select_city_placeholder,
    select_station_to_delivery,
    continue_text,
    button_clear_text,
    stationId,
    stations,
    cityItemsList,
    order,
  }
}

export default connect(mapStateToProps, {
  logScreenEnterance,
  fetchStationsList,
  setLockerToLockerDeliveryOrder,
  LogoutUser,
})(withRouter(SelectStation))
