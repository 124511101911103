import React, { Component } from "react"
import { connect } from "react-redux"
import langLayouts from "./LangsLayouts"
import Row from "./Row"

import "./style.css"
import Key from "./Row/Key"
import { withRouter } from "react-router-dom"

class VirtalKeyboard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isShifted: false,
      isLangSwitched: false,
      isKeyboardFocused: false,
      isKeyboardOpen: this.props.show,
      currLang: "en",
    }
  }

  componentDidMount() {
    const { show, lang } = this.props

    if (show) {
      this.setState({
        isKeyboardOpen: true,
        currLang: lang,
      })
    } else {
      this.setState({ currLang: lang })
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.show) {
      this.setState({ isKeyboardOpen: true })
    } else {
      this.setState({ isKeyboardOpen: false })
    }

    const { isShifted } = this.state
    const { lang } = newProps

    const langLayout = langLayouts(lang)

    if (!langLayout.shiftable && isShifted) {
      this.setState({ isShifted: false })
    }

    const { lang: oldLang } = this.props

    if (oldLang !== lang) {
      this.setState({ currLang: lang })
    }
  }

  renderRows = (layout, currLang) => {
    return layout.map((row, index) => {
      return (
        <Row
          keys={row}
          onKeyClick={this.onKeyClick.bind(this)}
          key={index}
          currLang={currLang}
          blockLangSwitch={this.props.blockLangSwitch}
          langName={this.props.langName}
        />
      )
    })
  }

  onKeyClick(key) {
    let { isShifted } = this.state
    let { currentValue, maxLength } = this.props
    let notOverMax = true
    if (maxLength) {
      notOverMax = currentValue.length < maxLength
    }

    switch (key) {
      case "BACKSPACE":
        currentValue =
          currentValue && currentValue.substr(0, currentValue.length - 1)
        break
      case "SHIFT":
        this.setState({ isShifted: !isShifted })
        return
      case "ENTER":
        key = "\n"
        if (this.specialEnterHandling()) return
        currentValue = currentValue.concat(key)
        break
      case "SPACE":
        key = " "
        if (notOverMax) {
          currentValue = currentValue.concat(key)
        }
        break
      case "LANG_SWITCH":
        this.handleLangSwitch()
        return
      case "GO_BACK":
        this.props.history.goBack()
        return
      case "ENG_GO_BACK":
        this.props.history.goBack()
        return
      case "CLOSE":
        this.changeKeyboardOpenState()
        return
      case "ENG_CLOSE":
        this.changeKeyboardOpenState()
        return
      default:
        if (!currentValue) {
          currentValue = ""
        }
        if (notOverMax) {
          currentValue = currentValue.concat(key)
        }
    }

    this.props.onKeyboardInputChange(currentValue)
  }

  changeKeyboardOpenState() {
    const { isKeyboardOpen } = this.state

    this.setState({ isKeyboardOpen: !isKeyboardOpen })

    if (isKeyboardOpen) {
      const { onCloseKeyboardClick, closeKeyboard } = this.props

      if (onCloseKeyboardClick) {
        onCloseKeyboardClick()
      }
      if (closeKeyboard) {
        closeKeyboard()
      }
    }
  }

  specialEnterHandling() {
    if (!this.props.onEnterClick && !this.props.closeOnEnter) return false

    if (this.props.onEnterClick) this.props.onEnterClick()

    if (this.props.closeOnEnter) this.changeKeyboardOpenState()
  }

  handleLangSwitch() {
    const { currLang, isLangSwitched } = this.state

    this.setState({
      currLang: currLang === "en" ? "he" : "en", //??????????
      isShifted: false,
      isLangSwitched: !isLangSwitched,
    })
  }

  render() {
    const { referredInput, show, marginTop, cardNumber, driversLogin } =
      this.props
    const { isShifted, isKeyboardOpen, currLang } = this.state

    let keyboardLocationStyle

    const margTop = marginTop || 0

    if (referredInput) {
      const rect = referredInput.getBoundingClientRect()

      const { top, height } = rect
      keyboardLocationStyle = {
        top: top + height + 10 + margTop,
        alignSelf: "center",
      }
    }

    const langLayout = langLayouts(currLang)
    const layoutToRender = isShifted
      ? langLayout.shiftLayout
      : langLayout.layout

    if (!isKeyboardOpen) return <div />

    return (
      <div className="vkb-wrapper vkb-absolute" style={keyboardLocationStyle}>
        <div
          className={`vkb-keys-layout-wrapper ${
            langLayout.fullKeyboard ? "big-keyboard" : ""
          } ${cardNumber ? "top-position" : ""} ${
            driversLogin && "right-position"
          }`}
        >
          {this.renderRows(layoutToRender, currLang)}
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ translate }) => {
  const { dirRtl, close_text, langName } = translate

  return {
    dirRtl,
    close_text,
    langName,
  }
}

export default connect(mapStateToProps)(withRouter(VirtalKeyboard))
