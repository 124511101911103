import React, { Component } from "react"
import Modal from "react-modal"
import { connect } from "react-redux"
import {
  fetchCouponByName,
  resetCoupons,
  setCouponLoading,
} from "../../../actions"
import { InputFiled, OrangeButton } from "../../common"
import VirtualKeyboard from "../../common/VirtualKeyboard"
import "./style.css"

class AttachCouponModal extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      showKeyBoard: false,
      couponName: "",
      activeInput: null,
      activeInputName: "couponName",
    }

    this.state = this.INIT_STATE
    this.onInputFocusChange.bind(this.state)
  }

  componentDidMount() {
    this.props.resetCoupons()
  }

  componentWillUnmount() {
    this.props.resetCoupons()
  }

  onRefReady(inputName, ref) {
    if (this.state[`${inputName}Ref`]) {
      return
    }
    this.setState({
      [`${inputName}Ref`]: ref,
    })
  }

  onInputFocusChange(isFocused) {
    this.setState({
      showKeyBoard: isFocused,
    })
  }

  onKeyboardTextChange(text) {
    const { activeInputName } = this.state
    const { couponNameIsChecked } = this.props
    this.setState({ [activeInputName]: text })
    this.setState({ couponNameErr: false })
  }

  onInputFieldChange(inputName, val, evt) {
    evt.persist()
    this.setState({ [inputName]: val })
  }

  isValidValue(value) {
    if (value == undefined || !value || value.length === 0) {
      return false
    }
    return value.length <= 20
  }

  filterEnterAndSubmit(e) {
    if (e.key === "Enter") {
      setTimeout(() => {
        this.onGetClick()
      }, 700)
    }
  }

  onGetClick(e) {
    const { couponName } = this.state
    if (!this.isValidValue(couponName)) {
      return
    }
    this.setState({ showKeyBoard: false })

    this.props.setCouponLoading()
    this.props.fetchCouponByName(this.state.couponName, this.props.orderType)
  }

  onBackClick() {
    this.setState(this.INIT_STATE)
    this.props.onCancelClick()
  }

  render() {
    if (!this.props.show) {
      return <noscript />
    }

    const {
      dirRtl,
      onOkClick,
      translations,
      coupon,
      show,
      couponError,
      couponNameIsChecked,
      isCouponLoading,
    } = this.props
    const { close_text, you_got_coupon_discount } = translations
    const { couponId, discount, discountType } = coupon
    const wrapperClassName = `${dirRtl ? "dirrtl" : "dirltr"}`
    if (!couponError.isError && coupon.couponId != "") {
      const discountTypeText = discountType === 0 ? "%" : "₪"
      return (
        <Modal
          isOpen={show}
          onRequestClose={() => this.onBackClick()}
          className={`react-modal-content ${wrapperClassName}`}
          overlayClassName="modal-overlay"
        >
          <div className="code-coupon-modal-body">
            <div className="acm-title">{you_got_coupon_discount}</div>
            <div className="acm-discount-text">
              {discount + discountTypeText}
            </div>
            <OrangeButton
              extraClass="acm-details-single-btn"
              onClick={() => onOkClick(coupon)}
            >
              {close_text}
            </OrangeButton>
          </div>
        </Modal>
      )
    }

    const {
      back_btn_text,
      modal_title,
      add_coupon_btn_text,
      add_coupon_popup_btn_text,
      add_coupon_error_not_valid_text,
      add_coupon_placeholder,
    } = translations

    if (couponError.isError) {
      return (
        <Modal
          isOpen={show}
          onRequestClose={() => this.onBackClick()}
          className={`react-modal-content ${wrapperClassName}`}
          overlayClassName="modal-overlay"
        >
          <div className="orange-circle">
            <div className="orange-stick"></div>
            <div className="orange-dot"></div>
          </div>
          <div className="acm-title error-title">
            {add_coupon_error_not_valid_text}
          </div>
          <OrangeButton
            extraClass="acm-details-single-btn"
            onClick={(e) => this.onBackClick()}
          >
            {back_btn_text}
          </OrangeButton>
        </Modal>
      )
    }

    const { couponName } = this.state

    const couponNameErr = couponNameIsChecked && !couponId

    return (
      <Modal
        isOpen={show}
        onRequestClose={() => this.onBackClick()}
        className="react-modal-content coupon-modal-move-up"
        overlayClassName="modal-overlay"
      >
        <div>
          <div className="acm-title">{modal_title}</div>
          <InputFiled
            value={couponName}
            onChange={(e) =>
              this.onInputFieldChange("couponName", e.target.value, e)
            }
            displayErr={couponNameErr}
            placeholder={add_coupon_placeholder}
            onRefReady={(ref) => this.onRefReady("couponName", ref)}
            onFocusChanged={(isFocused) => this.onInputFocusChange(isFocused)}
            maxLength={20}
            onKeyPress={(e) => this.filterEnterAndSubmit(e)}
            autoFocus
          />

          <div className="acm-details-btns" dir={dirRtl ? "rtl" : "ltr"}>
            <OrangeButton onClick={(e) => this.onGetClick(e)}>
              {add_coupon_popup_btn_text}
            </OrangeButton>

            <OrangeButton onClick={(e) => this.onBackClick()}>
              {back_btn_text}
            </OrangeButton>
          </div>
        </div>
        <VirtualKeyboard
          currentValue={this.state.couponName}
          onKeyboardInputChange={(txt) => this.onKeyboardTextChange(txt)}
          onCloseKeyboardClick={() => {
            this.setState({ showKeyBoard: !this.state.showKeyBoard })
          }}
          show={true}
          lang="en"
        />
      </Modal>
    )
  }
}

const mapStateToProps = ({ userLogin, coupons, translate }) => {
  const { userId } = userLogin
  const { coupon, error, isChecked, isLoading } = coupons

  const {
    back_btn_text,
    close_text,
    add_coupon_modal_title,
    add_coupon_btn_text,
    add_coupon_popup_btn_text,
    you_got_coupon_discount,
    add_coupon_error_not_valid_text,
    add_coupon_placeholder,
  } = translate

  const translations = {
    back_btn_text,
    close_text,
    modal_title: add_coupon_modal_title,
    add_coupon_btn_text,
    add_coupon_popup_btn_text,
    you_got_coupon_discount,
    add_coupon_error_not_valid_text,
    add_coupon_placeholder,
  }

  return {
    userId,
    coupon,
    couponError: error,
    couponNameIsChecked: isChecked,
    isCouponLoading: isLoading,
    translations,
  }
}

export default connect(mapStateToProps, {
  fetchCouponByName,
  setCouponLoading,
  resetCoupons,
})(AttachCouponModal)
