import axios from "axios"
import React, { Component } from "react"
import { connect } from "react-redux"
import api from "../../../api/LocalServiceApi"
import Loader from "../Loader"
import "./style.css"

class PaymentInput extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      changTimeOut: null,
      inputVal: "",
      status: "",
    }
    this.stopRead = true
    this.state = this.INIT_STATE
    this.timeToLeave = 5
  }
  readCardCall() {
    // console.log("readCardCall")
    const { payment_card_reader_error } = this.props
    if (!this.stopRead && this.timeToLeave > 0)
      axios.get(api.readFromCard("read")).then((read) => {
        if (
          read === undefined ||
          read.data === undefined ||
          read.data === "error"
        ) {
          this.setState({ status: payment_card_reader_error })
          this.stopRead = false
          this.timeToLeave -= 1
          this.startReadFromCard()
          this.resetSelfStateIn(2)
        } else {
          this.setState({ status: "" })

          // console.log("read", read)
          this.onInputChange(read.data)
          axios.get(api.readFromCard("reset"))
          this.timeToLeave = 5
          this.stopRead = true
        }
      })
  }
  startReadFromCard() {
    const { payment_card_reader_error } = this.props
    if (this.timeToLeave <= 0) {
      // console.log("timeout  timeToLeave=" + this.timeToLeave)
      this.onInputChange("4580000000000000=data")
      this.stopRead = true
      this.timeToLeave = 5
    } else if (!this.stopRead && this.timeToLeave > 0)
      // console.log("timeToLeave=" + this.timeToLeave)
      axios.get(api.readFromCard("insert")).then((res_insert) => {
        // console.log("insert", res_insert)
        if (
          res_insert !== undefined &&
          res_insert.data !== undefined &&
          res_insert.data !== "error"
        ) {
          if (!this.stopRead && this.timeToLeave > 0)
            axios.get(api.readFromCard("remove")).then((res_remove) => {
              // console.log("remove", res_remove)
              if (
                res_remove !== undefined &&
                res_remove.data !== undefined &&
                res_remove.data !== "error"
              ) {
                this.readCardCall()
              } else {
                this.timeToLeave -= 1
                this.setState({ status: payment_card_reader_error })
                this.stopRead = false
                this.startReadFromCard()
                this.resetSelfStateIn(3)
              }
            })
        } else {
          this.timeToLeave -= 1
          this.setState({ status: payment_card_reader_error })
          this.stopRead = false
          this.startReadFromCard()
          this.resetSelfStateIn(3)
        }
      })
  }
  componentDidMount() {
    this.catchFocus()
    this.stopRead = false
    this.startReadFromCard()
  }

  componentWillUnmount() {
    const { changTimeOut } = this.state
    this.stopRead = true
    clearTimeout(changTimeOut)
  }

  componentWillReceiveProps(newProps) {
    const { refreshComponent } = newProps

    if (refreshComponent) {
      this.timeToLeave = 5
      this.catchFocus()
    }
  }

  catchFocus() {
    const { paymentInput } = this.refs

    paymentInput.focus()
  }

  onInputChange(val) {
    // console.log("val", val)
    if (!isNaN(val)) {
      val = `${val}`
    }
    const { inputVal, changTimeOut } = this.state

    if (inputVal.length < val.length) {
      if (changTimeOut) {
        clearTimeout(changTimeOut)
      }

      const timeout = setTimeout(() => this.onInputStreadEnded(), 700)
      this.setState({ inputVal: val, changTimeOut: timeout })
    }
  }

  onInputStreadEnded() {
    let { inputVal } = this.state

    // console.log("onInputStreadEnded", inputVal)

    try {
      if (inputVal.includes(";")) {
        inputVal = inputVal.split(";")[1]
      }

      inputVal = inputVal.split("=")

      let cCard
      let mmyy

      if (inputVal[0].length === 16 || inputVal[0].length === 15) {
        cCard = inputVal[0]
        mmyy = `${inputVal[1].substr(2, 2)}${inputVal[1].substr(0, 2)}`
      } else {
        // THIS IS NOT A REGULAR CREDIT CARD - 9 DIGITS - OMRI'S CODE :)
        cCard = inputVal[1].substr(0, 9)
        mmyy = `${inputVal[1].substr(16, 2)}${inputVal[1].substr(14, 2)}`
      }

      // console.log("lll", cCard, mmyy)

      this.props.onPaymentDataReady(cCard, mmyy)
      this.resetSelfStateIn(3)
    } catch (e) {
      // BUG FIX - partial card data reading..
      this.setState({ inputVal: "" })
      const { payment_card_reader_error } = this.props
      this.setState({ status: payment_card_reader_error }) // invalid card
      this.stopRead = false
      this.timeToLeave = 2
      this.startReadFromCard()
      this.resetSelfStateIn(3)

      return
    }
  }

  resetSelfStateIn(secs) {
    setTimeout(() => this.setState({ ...this.INIT_STATE }), secs * 1000)
  }

  //   renderSpinner() {
  //     const { inputVal } = this.state

  //     if (inputVal.length < 2) {
  //       return < />
  //     }

  //     return <ElectronSpinner />
  //   }

  render() {
    const { extraClass } = this.props
    const { inputVal } = this.state

    return (
      <div className={extraClass}>
        {!this.props.justCheck && (
          <div className="whlb-wrapper paymdl2-title">{this.state.status}</div>
        )}
        <Loader />
        <form>
        <input
          type="password"
          value={inputVal}
          className="payment-input"
          onChange={(e) => this.onInputChange(e.target.value)}
          onBlur={() => this.catchFocus()}
          ref="paymentInput"
          />
          </form>
      </div>
    )
  }
}

const mapStateToProps = ({ translate }) => {
  const {
    payment_card_reader_insert,
    payment_card_reader_remove,
    payment_card_reader_wait,
    payment_card_reader_error,
  } = translate

  return {
    payment_card_reader_insert,
    payment_card_reader_remove,
    payment_card_reader_wait,
    payment_card_reader_error,
  }
}

export default connect(mapStateToProps)(PaymentInput)
