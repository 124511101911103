import React from "react"
import { OrderDetailsList } from ".."

import "./style.css"

const ItemRow = ({ item, background }) => {
  const { title, amount } = item

  return (
    <div className={`ordl-itm-wrap ${background}`}>
      <div className={`ordl-itm-row-f itls-itm-row-flex2`}>{title}</div>
      <div className="ordl-itm-row-f">{amount}</div>
    </div>
  )
}

export default ItemRow
