import React from "react"

import "./style.css"

const SuccessFailBtn = ({ success }) => {
  // const className = success ? 'o-circle__sign--success' : 'o-circle__sign--failure';

  return (
    <div className="orange-circle">
      <div></div>
    </div>
  )
}

export default SuccessFailBtn
