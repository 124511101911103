import React from "react";
import Modal from "react-modal";
import { OrangeButton } from "../../common";
import { getDateOutOfTimeStamp, getTimeOutOfTimeStamp } from "../../../utils";
import "./style.css";
import Loader from "../../common/Loader";

const WaitingForLockerLock = ({
  show,
  onCloseClicked,
  onUnresponsiveDoorClick,
  title,
  subTitle,
  orderData,
  onOopsClicked,
  dirRtl,
}) => {
  const renderUnresponsiveDoorClick = (onUnresponsiveDoorClick) => {
    if (!onUnresponsiveDoorClick) {
      return;
    }

    return (
      <div
        extraClass="wfll-unresp-door-extra-class"
        onClick={() => onUnresponsiveDoorClick()}
      >
        לא זוהתה סגירת הדלת? לחץ כאן
      </div>
    );
  };

  const renderOopsButton = (onOopsClicked) => {
    if (!onOopsClicked) {
      return;
    }

    return (
      <OrangeButton onClick={(e) => onOopsClicked()} extraClass="wfll-oops-btn">
        ביטול פעולה{" "}
      </OrangeButton>
    );
  };

  const renderOrderData = (orderData) => {
    if (!orderData) {
      return;
    }

    const { orderNumber, firstName, lastName, orderDate, customerName } =
      orderData;

    let customerNameDisplay = customerName;
    if (
      !customerNameDisplay ||
      customerNameDisplay == null ||
      customerNameDisplay.length < 2
    ) {
      customerNameDisplay = `${firstName} ${lastName}`;
    }

    const displayOrderDate = getDateOutOfTimeStamp(`${orderDate}Z`);
    const displayOrderTime = getTimeOutOfTimeStamp(`${orderDate}Z`);

    return (
      <div extraClass="wfll-details-order">
        <div className="wfll-details-order-wrapper wfll-details-order-bold">
          <div>{customerNameDisplay}</div>
        </div>
        <div className="wfll-details-order-wrapper">
          <div>{orderNumber}</div>
          <div>{displayOrderDate}</div>
          <div>{displayOrderTime}</div>
        </div>
      </div>
    );
  };

  return (
    <Modal
      isOpen={show}
      onRequestClose={onCloseClicked}
      className="react-modal-content"
      overlayClassName="modal-overlay"
    >
      {/* {renderCloseBtn(onCloseClicked)} */}
      {renderUnresponsiveDoorClick(onUnresponsiveDoorClick)}

      <div className="wfll-label-extra-class" dir={dirRtl ? "rtl" : "ltr"}>
        {title}
      </div>
      <div className="wfll-label-extra-class" dir={dirRtl ? "rtl" : "ltr"}>
        {subTitle}
      </div>
      {renderOrderData(orderData)}
      <Loader />
      {renderOopsButton(onOopsClicked)}
    </Modal>
  );
};

export default WaitingForLockerLock;
