import React, { Component } from 'react';
import { connect } from 'react-redux';
import './style.css';

class FixedRightBottomButton extends Component {
    

    render(){

        const {text, onClick} = this.props
        return(
            <div className='fixed-right-bottom-wrapper'>
                <div className='fixed-right-bottom-button' onClick={e=> onClick()}>{text}</div>
            </div>
        );
    }

}

export default FixedRightBottomButton;