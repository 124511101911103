import React, { Component } from "react"
import { connect } from "react-redux"

import ManWomanCellPhone from "../../../assets/svg/man_woman_cell_phone.svg"
import QrCodeShape from "../../../assets/svg/qr_code_shape.svg"
import { newlineText } from "../../../utils/TextHelper"
import "./style.css"

class DoneLoginButton extends Component {
  render() {
    const { done_login_button_title, done_login_button_text } = this.props
    return (
      <div className="done-login-btn-wrapper">
        <div className="enter-by-app-title">
          {done_login_button_title.split("\n").map((str) => (
            <p>{str}</p>
          ))}
        </div>
        <div className="orange-part" onClick={() => this.props.onClick()}>
          <div className="qr-code-shape">
            <img src={QrCodeShape} />
          </div>
          <img src={ManWomanCellPhone} />
        </div>
        <div className="white-part" onClick={() => this.props.onClick()}>
          <div>
            {done_login_button_text.split("\n").map((str) => (
              <p>{str}</p>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ translate }) => {
  const {
    done_login_button_title,
    done_login_button_text,
    password_login_button_title,
  } = translate
  return {
    done_login_button_title,
    done_login_button_text,
    password_login_button_title,
  }
}

export default connect(mapStateToProps, {})(DoneLoginButton)
