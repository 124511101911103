import React, { Component } from "react"
import { connect } from "react-redux"
import axios from "axios"
import api from "../../../../api/AzureApi"
import {
  fetchAllLockersStatuses,
  LogoutUser,
  logScreenEnterance,
  onDriverCollectsOrder,
  openAndListenToSpecificLockerLock,
  setPageAsVisited,
} from "../../../../actions"
import CollectWithBarcodeModal from "../../../Modals/CollectWithBarcodeModal"
import ErrorInfoModal from "../../../Modals/ErrorInfoModal"
import LoadingInfoModal from "../../../Modals/LoadingInfoModal"
import WaitingForLockerLock from "../../../Modals/WaitingForLockerLock"
import { FixedFooter, FixedHeader, LockerCells } from "../../../reduxified"
import "./style.css"

class CollectOrdersScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showWaitModal: false,
      orderData: null,
      showOpeningLockerFailModal: false,
      lockerNum: null,
      orderId: null,
      lockerId: null,
      barcodePopUpDisplay: false,
      orderBarcode: null,
      currentlyOpeningALocker: false,
      nextMaxAttempts: 0,
      enableUnresponsiveDoorBtn: false,
      cellNum: null,
      lockerData: null,
      isUsedBarcode: false,
    }
  }

  componentDidMount() {
    const { authenticated } = this.props
    if (!authenticated) {
      this.props.LogoutUser()
      this.props.history.push("")
    }

    this.props.setPageAsVisited("CollectOrdersScreen")
    this.props.logScreenEnterance("DRIVER_COLLECT_ORDER_SCREEN")
  }

  async componentWillReceiveProps(newProps) {
    const {
      openedLockersArr,
      errorLockersArr,
      stationId,
      openingAttempsMap,
      recentClosedLockersArr,
      recentOpenAttemptLockerNum,
    } = newProps

    const { lockerNum, orderId, lockerId, nextMaxAttempts, orderBarcode } =
      this.state
    if (!lockerNum) {
      return
    }

    if (lockerNum !== recentOpenAttemptLockerNum) {
      return
    }

    const openingDoorSuccess = openedLockersArr.includes(lockerNum)
    const openingErrorOccurred = errorLockersArr.includes(lockerNum)
    const orderWasCollected = recentClosedLockersArr.includes(lockerNum)
    const openAttemptsNum = openingAttempsMap[lockerNum] || 0

    if (orderWasCollected) {
      this.setState({ enableUnresponsiveDoorBtn: false, showWaitModal: false })
      this.props.fetchAllLockersStatuses(stationId)
      return
    }

    if (openingDoorSuccess) {
      this.setState({
        showWaitModal: true,
        currentlyOpeningALocker: false,
        enableUnresponsiveDoorBtn: false,
      })

      orderBarcode === null &&
        (await this.props.onDriverCollectsOrder(orderId, lockerId, stationId))

      setTimeout(
        () => this.setState({ enableUnresponsiveDoorBtn: true }),
        10 * 1000
      )
      return
    }

    if (openingErrorOccurred) {
      if (openAttemptsNum < nextMaxAttempts) {
        this.openAndListenToLocker(lockerNum, 5)
      } else {
        this.setState({
          showOpeningLockerFailModal: true,
          currentlyOpeningALocker: false,
        })
      }
    }
  }

  onCancelClick() {
    this.props.history.goBack()
  }

  onCellClick(cellNum, lockerData) {
    const { lockerId, order, disabled, pickUpDisabled } = lockerData
    if (disabled || pickUpDisabled || !order) {
      return
    }
    const { orderId, orderStatus, orderType, barcodePopUpDisplay } = order
    this.setState({
      orderData: order,
      lockerData: lockerData,
      cellNum: cellNum,
    })

    if (
      orderStatus !== 0 &&
      orderStatus !== 13 &&
      orderType !== 1 &&
      orderType !== 2 &&
      orderType !== 3
    ) {
      console.log(
        'driver is not supposed to pickup an order that is not in "dirty status"'
      )
      return
    }

    barcodePopUpDisplay
      ? this.setState({ showBarcodeModal: true })
      : this.openCellAndWaitForClose(cellNum, orderId, lockerId)
  }

  openCellAndWaitForClose(lockerNum, orderId, lockerId) {
    this.openAndListenToLocker(lockerNum)

    const { openingAttempsMap } = this.props

    const openAttemptsNum = openingAttempsMap[lockerNum] || 0

    this.setState({
      lockerNum,
      orderId,
      lockerId,
      currentlyOpeningALocker: true,
      nextMaxAttempts: openAttemptsNum + 4,
    })
  }

  openAndListenToLocker(lockerNum, attempts) {
    this.props.openAndListenToSpecificLockerLock(lockerNum, attempts)
  }

  async onAddingBarcode(barcode) {
    const { lockerData, cellNum } = this.state
    const { lockerId, order } = lockerData
    const { orderId, orderType } = order
    const { stationId } = this.props

    await axios
      .get(api.isUsedBarcode(barcode, orderType))
      .then((res) => this.setState({ isUsedBarcode: res.data }))

    const { isUsedBarcode } = this.state

    if (isUsedBarcode) {
      this.setState({
        showOpeningLockerFailModal: true,
      })
      return
    }

    this.setState({ orderBarcode: barcode })
    this.openCellAndWaitForClose(cellNum, orderId, lockerId)
    await this.props.onDriverCollectsOrder(
      orderId,
      lockerId,
      stationId,
      barcode
    )
  }

  render() {
    const {
      showWaitModal,
      showOpeningLockerFailModal,
      showBarcodeModal,
      orderData,
      lockerNum,
      currentlyOpeningALocker,
      enableUnresponsiveDoorBtn,
      cellNum,
    } = this.state
    const {
      driver_menu_orders_collecting,
      close_text,
      next_text,
      order_details,
      user_name_text,
      order_number,
      locker_number,
      dirRtl,
    } = this.props
    const handleUnresponsiveDoor = enableUnresponsiveDoorBtn
      ? () =>
          this.setState({
            showWaitModal: false,
            enableUnresponsiveDoorBtn: false,
          })
      : null

    return (
      <div className="ls-wrapper">
        <FixedHeader buttonsFlag="collectOrdersScreen" />
        <div className="driver-screen-title">
          {driver_menu_orders_collecting}
        </div>
        <div className="dcfacs-inner-wrapper">
          <LockerCells onCellClick={this.onCellClick.bind(this)} />
        </div>

        <FixedFooter history={this.props.history} />

        <WaitingForLockerLock
          show={showWaitModal}
          title={`תא ${lockerNum} פתוח, סגור תא זה להמשך`}
          subTitle="פרטי ההזמנה"
          orderData={orderData}
          onUnresponsiveDoorClick={handleUnresponsiveDoor}
          dirRtl={dirRtl}
          onCloseClicked={() => this.setState({ showWaitModal: false })}
        />

        <CollectWithBarcodeModal
          show={showBarcodeModal}
          onAddingBarcode={this.onAddingBarcode.bind(this)}
          dirRtl={dirRtl}
          orderData={orderData}
          lockerNum={cellNum}
          btnText={next_text}
          onCloseClicked={() => this.setState({ showBarcodeModal: false })}
          title={order_details}
          user_name_text={user_name_text}
          order_number={order_number}
          locker_number={locker_number}
        />

        <LoadingInfoModal
          show={currentlyOpeningALocker}
          title={`פותח את תא מספר ${lockerNum}`}
          text="...אנא המתן"
        />
        <ErrorInfoModal
          show={showOpeningLockerFailModal}
          title={
            !this.state.isUsedBarcode
              ? "תקלה בעת ניסיון פתיחת הדלת"
              : "כבר קיימת הזמנה עם ברקוד הזה"
          }
          text={
            !this.state.isUsedBarcode
              ? "אירעה תקלה בעת ניסיון פתיחת הדלת לאחר 4 פעמים"
              : "נא בחרו את ברקוד אחר"
          }
          onModalCloseClick={() =>
            this.setState({ showOpeningLockerFailModal: false })
          }
          closeTrnsl={close_text}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userLogin, bgService, lockersCells, translate }) => {
  const { authenticated, role } = userLogin
  const { stationId } = bgService
  const {
    openedLockersArr,
    errorLockersArr,
    openingAttempsMap,
    recentClosedLockersArr,
    recentOpenAttemptLockerNum,
  } = lockersCells
  const {
    driver_menu_orders_collecting,
    close_text,
    next_text,
    order_details,
    driver_interface_locker_screen_order_client_name_text: user_name_text,
    order_number,
    locker_number,
    dirRtl,
  } = translate
  return {
    stationId,
    authenticated,
    role,
    openedLockersArr,
    errorLockersArr,
    openingAttempsMap,
    recentClosedLockersArr,
    recentOpenAttemptLockerNum,
    driver_menu_orders_collecting,
    close_text,
    next_text,
    order_details,
    user_name_text,
    order_number,
    locker_number,
    dirRtl,
  }
}

export default connect(mapStateToProps, {
  onDriverCollectsOrder,
  fetchAllLockersStatuses,
  openAndListenToSpecificLockerLock,
  logScreenEnterance,
  setPageAsVisited,
  LogoutUser,
})(CollectOrdersScreen)
