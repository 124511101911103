import React, { Component } from "react"

import { connect } from "react-redux"

import { OrangeButton } from "../../../common"
import { FixedHeader } from "../../../reduxified"
import { logScreenEnterance, LogoutUser } from "../../../../actions"

import "./style.css"

class DriverMenuScreen extends Component {
  componentDidMount() {
    const { authenticated } = this.props
    if (!authenticated) {
      this.props.LogoutUser()
      this.props.history.push("")
    }

    const {
      isDeliveryRole,
      isAnyOrderToReturn,
      isCollectOrdersScreenVisited,
      isLoadCleanOrdersScreenVisited,
    } = this.props

    if (isDeliveryRole && isAnyOrderToReturn && !isCollectOrdersScreenVisited) {
      this.props.history.push("CollectOrdersScreen")
    } else if (
      isDeliveryRole &&
      !isAnyOrderToReturn &&
      !isLoadCleanOrdersScreenVisited
    ) {
      this.props.history.push("LoadCleanOrdersScreen")
    }

    this.props.logScreenEnterance("DRIVER_MENU_SCREEN")
  }

  componentWillReceiveProps(newProps) {
    const { authenticated } = newProps

    if (!authenticated) {
      this.props.LogoutUser()
      this.props.history.push("")
    }

    //Start --- Not see it happen
    const {
      isDeliveryRole,
      isAnyOrderToReturn,
      isCollectOrdersScreenVisited,
      isLoadCleanOrdersScreenVisited,
    } = newProps

    if (isDeliveryRole && isAnyOrderToReturn && !isCollectOrdersScreenVisited) {
      this.props.history.push("CollectOrdersScreen")
    } else if (
      isDeliveryRole &&
      isAnyOrderToReturn &&
      !isLoadCleanOrdersScreenVisited
    ) {
      this.props.history.push("LoadCleanOrdersScreen")
    }
    //End --- Not see it happen
  }

  onDriverLoadingACellClick() {
    this.props.history.push("LoadCleanOrdersScreen")
  }

  onDriverCollectFromACellClick() {
    this.props.history.push("CollectOrdersScreen")
  }

  onDriverDeliberatelyOpenCellsClick() {
    this.props.history.push("UnlockLockersScreen")
  }

  onGoBackClick() {
    this.props.history.goBack()
  }

  render() {
    const {
      dirRtl,
      user_menu_what_sub_title_text,
      driver_menu_ready_orders_loading,
      driver_menu_orders_collecting,
      driver_menu_lockers_cells_opening,
      back_btn_text,
      hello_title_text,
      firstName,
    } = this.props

    return (
      <div className="ls-wrapper">
        <FixedHeader buttonsFlag="driverMenuScreen" />
        <div className="dms-title" dir={dirRtl ? "rtl" : "ltr"}>
          {`${hello_title_text}, ${firstName}`}
        </div>
        <div className="dms-subtitle" dir={dirRtl ? "rtl" : "ltr"}>
          {user_menu_what_sub_title_text}
        </div>
        <div className="dms-wrapper">
          <OrangeButton
            extraClass="user-menu-btn"
            onClick={(e) => this.onDriverLoadingACellClick()}
          >
            {driver_menu_ready_orders_loading}
          </OrangeButton>
          <OrangeButton
            extraClass="user-menu-btn"
            onClick={(e) => this.onDriverCollectFromACellClick()}
          >
            {driver_menu_orders_collecting}
          </OrangeButton>
          <OrangeButton
            extraClass="user-menu-btn"
            onClick={(e) => this.onDriverDeliberatelyOpenCellsClick()}
          >
            {driver_menu_lockers_cells_opening}
          </OrangeButton>
          <OrangeButton
            extraClass="user-menu-btn"
            onClick={(e) => this.onGoBackClick()}
          >
            {back_btn_text}
          </OrangeButton>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({
  translate,
  userLogin,
  lockersCells,
  visitingScreens,
}) => {
  const { authenticated, isDeliveryRole, firstName } = userLogin
  const { isAnyOrderToReturn } = lockersCells
  const { isCollectOrdersScreenVisited, isLoadCleanOrdersScreenVisited } =
    visitingScreens

  const {
    dirRtl,
    user_menu_what_sub_title_text,
    driver_menu_ready_orders_loading,
    driver_menu_orders_collecting,
    driver_menu_lockers_cells_opening,
    back_btn_text,
    hello_title_text,
  } = translate

  return {
    authenticated,
    isDeliveryRole,
    isAnyOrderToReturn,
    isCollectOrdersScreenVisited,
    isLoadCleanOrdersScreenVisited,
    dirRtl,
    hello_title_text,
    user_menu_what_sub_title_text,
    driver_menu_ready_orders_loading,
    driver_menu_orders_collecting,
    driver_menu_lockers_cells_opening,
    back_btn_text,
    firstName,
  }
}

export default connect(mapStateToProps, { logScreenEnterance, LogoutUser })(
  DriverMenuScreen
)
