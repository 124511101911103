import React from "react"
import "./style.css"

import eye from "../../../assets/svg/eye.svg"
import eye_closed from "../../../assets/svg/eye_closed.svg"

const InputFiled = ({
  show = true,
  value,
  icon,
  displayErr,
  onFocusChanged,
  onRefReady,
  isLoading,
  mandatory,
  onChange,
  dirRtl,
  step1,
  ...props
}) => {
  if (!show) {
    return <></>
  }
  const inputDirectionClass = !dirRtl ? "infl-ltr" : "infl-rtl"
  let showValidation =
    displayErr && props.validationtext && props.validationtext.length > 0
  const isHidedPassword =
    props.isPasswordTypeWithEye && props.type === "password"
  let onEyeClick = props.onViewPasswordClick
  if (props.onViewPasswordClick === undefined) {
    onEyeClick = () => {}
  }
  return (
    <div
      className={`${step1 ? "step1" : "input-wrapper"} ${
        props.cardNumber && "cardNumber"
      }`}
    >
      {renderPasswordView(
        props.isPasswordTypeWithEye,
        isHidedPassword,
        onEyeClick,
        inputDirectionClass
      )}
      <form>
        <input
          className={props.smsCode ? "sms-code-input" : ""}
          value={value}
          onFocus={(e) => onFocusChanged(true)}
          ref={(ref) => onRefReady(ref)}
          onChange={(e) => onChange(e)}
          autocomplete="on"
          {...props}
          placeholder={props.placeholder}
        />
      </form>
      {renderValidation(showValidation, props.validationtext, dirRtl)}
    </div>
  )
}

const renderPasswordView = (
  isPasswordType,
  isHidedPassword,
  onViewPasswordClick
) => {
  if (!isPasswordType) {
    return <></>
  }
  if (isHidedPassword) {
    return (
      <div className={"password-eye "} onClick={() => onViewPasswordClick()}>
        <img src={eye} alt="eye_img" />
      </div>
    )
  }

  return (
    <div className={"password-eye "} onClick={() => onViewPasswordClick()}>
      <img src={eye_closed} alt="closed-eye" />
    </div>
  )
}

const renderValidation = (show, text, dirRtl) => {
  if (!show) {
    return <></>
  }
  return (
    <div className="validation-wrapper">
      <div className="validation-text" dir={dirRtl ? "rtl" : "ltr"}>
        {text.split("\n").map((str) => (
          <p>{str}</p>
        ))}
      </div>
    </div>
  )
}

export { InputFiled }
