import axios from "axios"
import { connect } from "react-redux"
import api from "../../api"
const PingAPI = (props) => {
  const payload = { stationId: props.stationId }
  setInterval(
    () =>
      payload.stationId &&
      axios
        .post(api.pingAPI(props.stationId), payload)
        .then((res) => console.log(res))
        .catch((err) => console.error(err)),
    300000
  )

  const root = document.getElementById("root")
  if (props.langName === "en") {
    // root.style.fontFamily = "PlacardNext-CondBold"
  }
  if (props.langName === "he" || props.langName === "ru") {
    root.style.fontFamily = "AlmoniTzar"
  }
  return null
}
const mapStateToProps = ({ bgService, translate }) => {
  const { stationId } = bgService
  const { langName } = translate
  return {
    langName,
    stationId,
  }
}
export default connect(mapStateToProps)(PingAPI)
