import {
  RESET_ALL_REDUCERS,
  SET_LOCKER_TO_LOCKER_DELIVERY_ORDER,
  FETCH_AVAILABLE_LOCKERS_STATUSES_SUCCESS,
  FETCH_AVAILABLE_LOCKERS_STATUSES_FAIL,
  FETCH_LOCKER_TO_LOCKER_DELIVERY_STATION_SUCCESS,
  FETCH_LOCKER_TO_LOCKER_DELIVERY_STATION_FAIL,
  ON_CREATE_LOCKER_TO_LOCKER_DELIVERY_ORDER_SUCCESS,
  ON_CREATE_LOCKER_TO_LOCKER_DELIVERY_ORDER_FAIL,
  ON_LOCKER_TO_LOCKER_DELIVERY_LOADING,
  ON_GET_ORDER_FOR_PAY_SUCCESS,
  ON_GET_ORDER_FOR_PAY_FAIL,
  ON_CONFIRM_CREATE_ORDER_SUCCESS,
  ON_CONFIRM_CREATE_ORDER_FAIL,
  SET_LOCKER_TO_LOCKER_DELIVERY_PAYED_ORDER,
  ON_GET_LOCKER_TO_LOCKER_DELIVERY_ORDERS_REQUESTS_SUCCESS,
  ON_GET_ORDERS_REQUESTS_FAIL,
  ON_CLEAR_ORDER_FOR_CONFIRM_CREATE,
  ON_ADD_COUPON_TO_ORDER_SUCCESS,
} from "../actions/types"

const INIT_ORDER = () => {
  return {
    stationId: "",
    locationId: "",
    customerId: "", //like sourceCustomerId will updated on server to mobilePhone customer
    firstName: "",
    lastName: "",
    mobilePhone: "",
    couponId: "",
    orderType: 5,
    orderItems: [
      {
        quantity: 1,
        processName: "Shipment",
      },
    ],
    sourceCustomerId: "",
    sourceStationId: "",
    targetStationId: "",
  }
}

const INIT_STATE = {
  activeFlow: "lockerToLocker",
  stations: [],
  cityItemsList: [],
  toStationId: null,
  order: INIT_ORDER(),
  isLoading: false,
  createdOrder: null,
  orderForPay: null,
  payedOrder: null,
  isOrderConfirmed: false,
  isNoAvailableLockersForService: false,
  ordersRequestsList: [],
}

const LockerToLockerDeliveryReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_LOCKER_TO_LOCKER_DELIVERY_ORDER:
      return {
        ...state,
        order: action.payload,
      }

    case FETCH_AVAILABLE_LOCKERS_STATUSES_SUCCESS:
      let isNoAvailableLockersForService = false
      if (action.payload) {
        isNoAvailableLockersForService =
          action.payload.filter((l) => l.disabled == false).length <= 0
      }
      return {
        ...state,
        lockersStatuses: action.payload,
        isNoAvailableLockersForService: isNoAvailableLockersForService,
        isLoading: false,
      }
    case FETCH_AVAILABLE_LOCKERS_STATUSES_FAIL:
      return {
        ...state,
        lockersStatuses: [],
      }
    case FETCH_LOCKER_TO_LOCKER_DELIVERY_STATION_SUCCESS:
      const stations = action.payload

      const cityItemsList_Doubles = stations.map((s) => {
        return {
          label: s.cityName,
          value: s.city.cityId,
        }
      })

      const key = "value"
      const cityItemsList = [
        ...new Map(
          cityItemsList_Doubles.map((item) => [item[key], item])
        ).values(),
      ]

      return {
        ...state,
        stations: action.payload,
        cityItemsList,
      }
    case FETCH_LOCKER_TO_LOCKER_DELIVERY_STATION_FAIL:
      return {
        ...state,
        stations: [],
      }
    case ON_LOCKER_TO_LOCKER_DELIVERY_LOADING:
      return {
        ...state,
        isOrderConfirmed: false,
        isLoading: true,
      }
    case ON_CREATE_LOCKER_TO_LOCKER_DELIVERY_ORDER_SUCCESS:
      return {
        ...state,
        createdOrder: action.payload,
        orderForPay: null,
        payedOrder: null,
        isLoading: false,
      }
    case ON_CREATE_LOCKER_TO_LOCKER_DELIVERY_ORDER_FAIL:
      return {
        ...state,
        createdOrder: action.payload,
        isLoading: false,
      }
    case ON_ADD_COUPON_TO_ORDER_SUCCESS:
    case ON_GET_ORDER_FOR_PAY_SUCCESS:
      return {
        ...state,
        orderForPay: action.payload,
        payedOrder: null,
        isLoading: false,
        order: {
          stationId: "",
          locationId: "",
          customerId: "", //like sourceCustomerId will updated on server to mobilePhone customer
          firstName: "",
          lastName: "",
          mobilePhone: "",
          couponId: "",
          orderType: 5,
          orderItems: [
            {
              quantity: 1,
              processName: "Shipment",
            },
          ],
          sourceCustomerId: "",
          sourceStationId: "",
          targetStationId: "",
        },
        //createdOrder: null,
      }
    case ON_CLEAR_ORDER_FOR_CONFIRM_CREATE:
    case ON_GET_ORDER_FOR_PAY_FAIL:
      return {
        ...state,
        orderForPay: null,
        isLoading: false,
      }
    case SET_LOCKER_TO_LOCKER_DELIVERY_PAYED_ORDER:
      return {
        ...state,
        payedOrder: action.payload,
        orderForPay: null,
      }
    case ON_CONFIRM_CREATE_ORDER_SUCCESS:
      return {
        ...state,
        createdOrder: null,
        orderForPay: null,
        //payedOrder:  //TODO
        isOrderConfirmed: action.payload,
        isLoading: false,
      }
    case ON_CONFIRM_CREATE_ORDER_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }
    case ON_GET_LOCKER_TO_LOCKER_DELIVERY_ORDERS_REQUESTS_SUCCESS:
      return {
        ...state,
        createdOrder: null,
        orderForPay: null,
        ordersRequestsList: action.payload,
        isLoading: false,
      }
    case ON_GET_ORDERS_REQUESTS_FAIL:
      return {
        ...state,
        createdOrder: null,
        orderForPay: null,
        ordersRequestsList: [],
        error: action.payload,
        isLoading: false,
      }
    case RESET_ALL_REDUCERS:
      return {
        ...INIT_STATE,
      }
    default:
      return state
  }
}

export default LockerToLockerDeliveryReducer
