import { READ_STATION_ID_FROM_BG_SERVICE_SUCCESS, RESET_ALL_REDUCERS, READ_STATION_ID_FROM_BG_SERVICE_FAIL } from '../actions/types';

const INIT_STATE = {
    stationId: null
};

const BgServiceReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case READ_STATION_ID_FROM_BG_SERVICE_SUCCESS:
            return {
                ...state,
                stationId: action.payload
            };

        case READ_STATION_ID_FROM_BG_SERVICE_FAIL:
            return {
                ...state,
                stationId: -1
            };


        case RESET_ALL_REDUCERS:
            return {
                ...INIT_STATE,
                stationId: state.stationId
            };
        default:
            return state;
    }
};

export default BgServiceReducer;
