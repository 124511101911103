import {
    ON_LOCKER_LISTENER_START_WITH_OPEN_ATTEMPT,
    ON_LOCKER_LOCK_LISTENER_RESPONSE,
    RESET_ALL_REDUCERS
} from '../actions/types';

const INIT_STATE = {
    openedLocker: null,
    orderWasCollected: false
};

const LockersReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ON_LOCKER_LISTENER_START_WITH_OPEN_ATTEMPT:
            return {
                ...state,
                openedLocker: parseInt(action.payload),
                orderWasCollected: false
            };
        case ON_LOCKER_LOCK_LISTENER_RESPONSE:
            return {
                ...state,
                openedLocker: action.payload === state.openedLocker ? null : state.openedLocker,
                orderWasCollected: action.payload === state.openedLocker
            };
        case RESET_ALL_REDUCERS:
            return {
                ...INIT_STATE
            };
        default:
            return state;
    }
};

export default LockersReducer;
