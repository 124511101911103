import React, { Component } from "react"
import { connect } from "react-redux"
import {
  changeDisplayedLanguage,
  getOrderByDeliveryCode,
  logScreenEnterance,
  reportSuspiciousBrokenLockers,
  resetDeliveryReducer,
  restoreDeliveryCode,
} from "../../../../actions"
import { DisEnbPendBtn, InputFiled } from "../../../common"
import VirtualKeyboard from "../../../common/VirtualKeyboard"
import ErrorInfoModal from "../../../Modals/ErrorInfoModal"
import { FixedFooter, FixedHeader } from "../../../reduxified"
//import WarningInfoModal from '../../../Modals/WarningInfoModal';
import "./style.css"

class SmsLoginScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      deliveryCode: "",
      activeInput: null,
      codeRef: "deliveryCode",
      codeErr: false,
      isSubmitBtnDisabled: true,
      errorsTimeout: null,
      showErrorModal: false,
      errText: "",
      isPasswordResetModalShown: false,
      runValidationsTimeOut: null,
      openedDoorModalCloseClicked: false,
    }
  }

  componentWillMount() {
    this.props.resetDeliveryReducer()
  }

  componentDidMount() {
    this.props.logScreenEnterance("SMS_LOGIN_SCREEN")
  }

  componentWillUnmount() {
    const { errorsTimeout, runValidationsTimeOut } = this.state

    if (errorsTimeout) {
      clearTimeout(errorsTimeout)
    }

    if (runValidationsTimeOut) {
      clearTimeout(runValidationsTimeOut)
    }
  }

  componentWillReceiveProps(newProps) {
    const {
      authenticated,
      errTitle,
      errText,
      showErrorModal,
      stationId,
      langName,
    } = newProps

    if (authenticated) {
      this.props.history.push("CollectOrderScreen", "delivery")
    }

    //! TODOD- RESET THE ERR TITLE AND TEXT WHEN STARTING NEW REQ!
    //const { errTitle: oldErrTitle, errText: oldErrText} = this.props

    if (showErrorModal) {
      this.setState({ showErrorModal: true })
    }

    if (stationId === -1 || stationId === null) {
      this.props.history.push("MaintenanceScreen")
      return
    }
  }

  onKeyboardTextChange(text) {
    const { errorsTimeout } = this.state

    if (errorsTimeout) {
      clearTimeout(errorsTimeout)
    }

    this.setState({
      deliveryCode: text,
      codeErr: false,
      errorsTimeout: null,
    })

    this.runValidationsWithTimoutDebounce()
  }

  onRefReady(refName, ref) {
    if (!this.state[refName]) this.setState({ [refName]: ref })
  }

  onInputFocusChange(refName, isFocused) {}

  isCodeValid(deliveryCode, evt) {
    if (
      deliveryCode == undefined ||
      !deliveryCode ||
      deliveryCode.length === 0
    ) {
      return false
    }

    let isValidField = true
    if (evt !== undefined) {
      //isValidField = evt.target.validity.valid;
    }
    return isValidField && deliveryCode.length === 6
  }

  runValidations(evt) {
    const { deliveryCode } = this.state
    const isCodeValid = this.isCodeValid(deliveryCode, evt)
    console.log("isCodeValid: " + isCodeValid)
    //const isEmptyPwd = pwd.length < 1;

    const isSubmitBtnDisabled = !isCodeValid
    const codeErr = !isCodeValid
    if (!isSubmitBtnDisabled) {
      this.setState({
        isSubmitBtnDisabled,
        codeErr,
        runValidationsTimeOut: null,
      })
    } else {
      const errorsTimeout = setTimeout(
        () =>
          this.setState({
            isSubmitBtnDisabled,
            codeErr,
            runValidationsTimeOut: null,
          }),
        800
      )

      this.setState({ errorsTimeout })
    }
  }

  onSignInClick() {
    const { deliveryCode } = this.state
    const { stationId } = this.props
    if (this.isCodeValid(deliveryCode)) {
      this.props.getOrderByDeliveryCode(stationId, deliveryCode)
    }
  }

  onInputFieldChange(inputName, val, evt) {
    evt.persist()
    this.setState({ [inputName]: val })
    this.runValidationsWithTimoutDebounce(evt)
  }

  runValidationsWithTimoutDebounce(evt) {
    let { runValidationsTimeOut } = this.state

    if (runValidationsTimeOut) {
      clearTimeout(runValidationsTimeOut)
    }

    runValidationsTimeOut = setTimeout(() => this.runValidations(evt), 500)
    this.setState({ runValidationsTimeOut })
  }

  filterEnterAndSubmit(e) {
    if (e.key === "Enter") {
      setTimeout(() => {
        const { isSubmitBtnDisabled } = this.state

        if (!isSubmitBtnDisabled) {
          this.onSignInClick()
        }
      }, 700)
    }
  }

  onLangChange(lang) {
    this.props.changeDisplayedLanguage(lang)
  }
  onErrorClose() {
    this.setState({ showErrorModal: false, deliveryCode: "" })
  }

  render() {
    const { deliveryCode, isSubmitBtnDisabled, codeErr, showErrorModal } =
      this.state

    const {
      isLoadingRequest,
      dirRtl,
      sms_login_title,
      sms_login_subtitle,
      sms_login_input_placeholder,
      sms_login_button_text,
      close_text,
      sms_login_not_found_order_error_title,
      sms_login_not_found_order_error_text,
      collect_package,
    } = this.props

    return (
      <div className="ls-wrapper sms-login-screen">
        <FixedHeader
          title={sms_login_title}
          subTitle={sms_login_subtitle}
          buttonsFlag="showRestoreDeliveryCode"
        />

        <div className="content-wrapper">
          <div className="enter-text">
            <h1>{collect_package}</h1>
            <h3>{sms_login_subtitle}</h3>
          </div>
          <InputFiled
            value={deliveryCode}
            type="tel"
            pattern="[0-9]"
            maxLength={6}
            onChange={(e) =>
              this.onInputFieldChange("deliveryCode", e.target.value, e)
            }
            placeholder={sms_login_input_placeholder}
            onRefReady={(ref) => this.onRefReady("codeRef", ref)}
            onFocusChanged={(isFocused) =>
              this.onInputFocusChange("codeRef", isFocused)
            }
            displayErr={codeErr}
            onKeyPress={(e) => this.filterEnterAndSubmit(e)}
            smsCode
          />

          <div className="keyboard-wrapper">
            <VirtualKeyboard
              currentValue={this.state["deliveryCode"]}
              show
              onKeyboardInputChange={(txt) => this.onKeyboardTextChange(txt)}
              lang={"code"}
              maxLength={6}
            />
          </div>
          <DisEnbPendBtn
            onClick={(e) => this.onSignInClick()}
            isDisabled={isSubmitBtnDisabled}
            isPending={isLoadingRequest}
          >
            {sms_login_button_text}
          </DisEnbPendBtn>
        </div>

        <ErrorInfoModal
          dirRtl={dirRtl}
          show={showErrorModal}
          title={sms_login_not_found_order_error_title}
          text={sms_login_not_found_order_error_text}
          onModalCloseClick={() => this.onErrorClose()}
          closeTrnsl={close_text}
        />
        <FixedFooter history={this.props.history} />
      </div>
    )
  }
}

const mapStateToProps = ({
  bgService,
  translate,
  activeDelivery,
  userLogin,
}) => {
  const { stationId } = bgService
  const { authenticated, errTitle, errText } = userLogin
  const { isLoadingRequest, showErrorModal } = activeDelivery
  const {
    dirRtl,
    langName,
    sms_login_title,
    sms_login_subtitle,
    sms_login_input_placeholder,
    sms_login_button_text,
    sms_login_didnot_get_sms,
    login_btn_text,
    password_for_delivery,
    close_text,
    back_btn_text,
    system_updgrade_err_msg,
    sms_login_not_found_order_error_title,
    sms_login_not_found_order_error_text,
    collect_package,
  } = translate

  return {
    authenticated,
    errTitle: translate[errTitle],
    errText: translate[errText],
    isLoadingRequest,
    showErrorModal,
    stationId,

    dirRtl,
    langName,
    sms_login_title,
    sms_login_subtitle,
    sms_login_input_placeholder,
    sms_login_button_text,
    sms_login_didnot_get_sms,
    login_btn_text,
    password_for_delivery,
    back_btn_text,
    close_text,
    sms_login_not_found_order_error_title,
    sms_login_not_found_order_error_text,
    system_updgrade_err_msg,
    collect_package,
  }
}

export default connect(mapStateToProps, {
  getOrderByDeliveryCode,
  restoreDeliveryCode,
  resetDeliveryReducer,
  changeDisplayedLanguage,
  reportSuspiciousBrokenLockers,
  logScreenEnterance,
})(SmsLoginScreen)
