import { combineReducers } from "redux"
import ActiveOrdersReducer from "./ActiveOrdersReducer"
import LockersReducer from "./LockersReducer"
import PaymentModalReducer from "./PaymentModalReducer"
import UserLoginReducer from "./UserLoginReducer"
import RegistrationReducer from "./RegistrationReducer"
import ReadyOrdersReducer from "./ReadyOrdersReducer"
import LockersCellsReducer from "./LockersCellsReducer"
import BgServiceReducer from "./BgServiceReducer"
import ThankYouReducer from "./ThankYouReducer"
import RecreatePasswordReducer from "./RecreatePasswordReducer"
import TranslateReducer from "./TranslateReducer"
import PricesReducer from "./PricesReducer"
import StationServiceTypesReducer from "./StationServiceTypesReducer"
import DeliveryReducer from "./DeliveryReducer"
import RestoreDeliveryCodeReducer from "./RestoreDeliveryCodeReducer"
import CouponsReducer from "./CouponsReducer"
import OrderTypeReducer from "./OrderTypeReducer"
import VisitingScreensReducer from "./VisitingScreensReducer"
import FlowReducer from "./FlowReducer.js"
import LockerToLockerDeliveryReducer from "./LockerToLockerDeliveryReducer.js"

export default combineReducers({
  activeOrders: ActiveOrdersReducer,
  lockers: LockersReducer,
  paymentModal: PaymentModalReducer,
  userLogin: UserLoginReducer,
  registration: RegistrationReducer,
  readyOrders: ReadyOrdersReducer,
  lockersCells: LockersCellsReducer,
  bgService: BgServiceReducer,
  thankYou: ThankYouReducer,
  recreatePassword: RecreatePasswordReducer,
  translate: TranslateReducer,
  prices: PricesReducer,
  stationServiceTypes: StationServiceTypesReducer,
  activeDelivery: DeliveryReducer,
  restoreDeliveryCode: RestoreDeliveryCodeReducer,
  coupons: CouponsReducer,
  orderTypes: OrderTypeReducer,
  visitingScreens: VisitingScreensReducer,
  flow: FlowReducer,
  lockerToLockerDelivery: LockerToLockerDeliveryReducer,
})
