import React from "react"
import { OrangeButton } from "../../../../../common"

import "./style.css"

const ItemRow = ({
  item,
  roundBottomCorners,
  onRowItemClick,
  isOrange,
  refOnSelect,
  background,
}) => {
  const {
    orderId,
    orderNumber,
    customerName,
    orderDate,
    orderType,
    customerMobilePhone,
    packageNumber,
    orderTypeName,
  } = item

  const [firstName, lastName] = customerName.split(" ")

  const orderData = {
    orderNumber,
    packageNumber,
    firstName,
    lastName,
    orderDate,
    customerName,
  }

  const className = `rodl-itm-wrap \
                            ${
                              roundBottomCorners
                                ? "rodl-itm-round-btm-crnrs"
                                : ""
                            }\
                            ${isOrange ? "rodl-orange" : ""}`

  //const rowIconSrc = getIconFromOrderType(orderType);
  const ref = isOrange ? refOnSelect : null
  return (
    <div className={`${className} ${background}`} ref={ref}>
      {/* <div className='rodl-itm-row-f'>
                <img className='rodl-img' src={rowIconSrc} alt='' />
            </div> */}
      <div className="rodl-itm-row-f">{orderNumber}</div>
      <div className="rodl-itm-row-f">{packageNumber}</div>
      <div className="rodl-itm-row-f">{customerMobilePhone}</div>
      <div className="rodl-itm-row-f rold-itm-flex-2">{customerName}</div>
      <div className="rodl-itm-row-f">{orderTypeName}</div>
      <div className="rodl-itm-row-f">
        <OrangeButton
          extraClass="rodl-frame-single-btn"
          onClick={() => onRowItemClick(orderId, orderData)}
        >
          פתח תא פנוי
        </OrangeButton>
      </div>
    </div>
  )
}

const getIconFromOrderType = (orderType) => {
  switch (
    orderType // 1-Laundry 2-mail 0-none
  ) {
    case 1:
      return require("../../../../../../assets/images/fashion.png") // fashionSrc
    case 2:
      return require("../../../../../../assets/images/close-envelope.png") // closeEnvelopeSrc
    default:
      return ""
  }
}

export default ItemRow
