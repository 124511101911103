import React, { Component } from "react"
import { connect } from "react-redux"
import {
  fetchAllLockersStatuses,
  fetchOrderTypes,
  LogoutUser,
  logScreenEnterance,
  onDriverReturnesCleanOrder,
  onGetReadyOrders,
  openAndListenToSpecificLockerLock,
  setPageAsVisited,
} from "../../../../actions"
import { getOrderTypeTextFromOrderType } from "../../../../utils/OrderStatus"
import { WhiteLabel } from "../../../common"
import OrderBarcodeReader from "../../../common/OrderBarcodeReader"
import ErrorInfoModal from "../../../Modals/ErrorInfoModal"
import LoadingInfoModal from "../../../Modals/LoadingInfoModal"
import WaitingForLockerLock from "../../../Modals/WaitingForLockerLock"
import WarningInfoModal from "../../../Modals/WarningInfoModal"
import { FixedHeader, LockerCells } from "../../../reduxified"
import ReadyOrdersDetailsList from "./ReadyOrdersDetailsList"
import FixedFooter from "../../../reduxified/FixedFooter"
import "./style.css"

class LoadCleanOrdersScreen extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      selectedOrderId: null,
      showWarningModal: false,
      showWarning2Modal: false,
      showWarning3Modal: false,
      showWaitingForLockModal: false,
      selectedOrderData: null,
      lockerNum: null,
      lockerId: null,
      currentlyOpeningALocker: false,
      nextMaxAttempts: 0,
      showOpeningLockerFailModal: false,
      enableUnresponsiveDoorBtn: false,
      lastOpenDoorSuccessLockerNum: null,
      barcode: "",
    }

    this.state = this.INIT_STATE
    this.refOnSelectOrder = React.createRef()
  }

  componentWillMount() {
    this.props.fetchOrderTypes()
    this.updateReadyOredersList()
  }

  componentDidMount() {
    const { authenticated } = this.props
    if (!authenticated) {
      this.props.LogoutUser()
      this.props.history.push("")
    }
    this.props.setPageAsVisited("LoadCleanOrdersScreen")
    this.props.logScreenEnterance("DRIVER_LOAD_CLEAN_ORDERS_SCREEN")
  }

  componentWillReceiveProps(newProps) {
    const {
      openedLockersArr,
      errorLockersArr,
      openingAttempsMap,
      recentClosedLockersArr,
      recentOpenAttemptLockerNum,
      stationId,
    } = newProps

    const {
      lockerNum,
      selectedOrderId,
      lockerId,
      nextMaxAttempts,
      currentlyOpeningALocker,
      lastOpenDoorSuccessLockerNum,
    } = this.state

    if (!lockerNum) {
      return
    }

    if (lockerNum !== recentOpenAttemptLockerNum) {
      return
    }

    const openingDoorSuccess = openedLockersArr.includes(lockerNum)
    const openingErrorOccurred = errorLockersArr.includes(lockerNum)
    const orderWasCollected = recentClosedLockersArr.includes(lockerNum)
    const openAttemptsNum = openingAttempsMap[lockerNum] || 0

    if (orderWasCollected) {
      console.log("ORDER WAS COLLECTED")

      this.setState({
        showWaitingForLockModal: false,
        currentlyOpeningALocker: false,
        selectedOrderId: null,
        lockerId: null,
      })

      if (selectedOrderId && lockerId) {
        this.props.onDriverReturnesCleanOrder(
          selectedOrderId,
          lockerId,
          stationId
        )
        console.log("onDriverReturnesCleanOrder", selectedOrderId, lockerId)
        setTimeout(() => this.updateCellsAndList(), 800)
      } else {
        console.log(
          "selectedOrderId && lockerId ARE FALSE!",
          selectedOrderId,
          lockerId
        )
      }

      return
    }

    if (openingDoorSuccess && lastOpenDoorSuccessLockerNum !== lockerNum) {
      console.log("OPEN DOOR SUCCESS!")

      this.setState({
        showWaitingForLockModal: true,
        enableUnresponsiveDoorBtn: false,
        currentlyOpeningALocker: false,
        lastOpenDoorSuccessLockerNum: lockerNum,
      })

      setTimeout(
        () =>
          this.setState({
            enableUnresponsiveDoorBtn: true,
          }),
        10 * 1000
      )

      return
    }

    if (openingErrorOccurred) {
      console.log("OPENING DOOR ERROR OCCURED!")
      if (openAttemptsNum < nextMaxAttempts) {
        this.openAndListenToLocker(lockerNum, 5)
      } else {
        console.log("OVER ATTEMPTS NUMBER!")
        this.setState({
          showOpeningLockerFailModal: true,
          currentlyOpeningALocker: false,
        })
      }
    }
  }

  updateReadyOredersList() {
    const { stationId } = this.props
    this.props.onGetReadyOrders(stationId)
  }

  onCellClick(cellNum, lockerData = {}) {
    const { selectedOrderId } = this.state

    if (!selectedOrderId) {
      this.setState({ showWarningModal: true })
      return
    }

    const { lockerId, lockerStatus, lockerType, disabled } = lockerData

    if (lockerStatus !== 3 || disabled) {
      this.setState({ showWarning2Modal: true })
      return
    }

    // if (lockerType !== 0) {
    //     this.setState({ showWarning3Modal: true });
    //     return;
    // }

    this.openALockerAndWaitForClose(cellNum, lockerId)
  }

  openALockerAndWaitForClose(lockerNum, lockerId) {
    this.openAndListenToLocker(lockerNum)

    const { openingAttempsMap } = this.props

    const openAttemptsNum = openingAttempsMap[lockerNum] || 0

    this.setState({
      lockerNum,
      lockerId,
      currentlyOpeningALocker: true,
      nextMaxAttempts: openAttemptsNum + 4,
    })
  }

  openAndListenToLocker(lockerNum, attempts) {
    this.props.openAndListenToSpecificLockerLock(lockerNum, attempts)
  }

  updateCellsAndList() {
    const { stationId } = this.props

    this.updateReadyOredersList()
    this.props.fetchAllLockersStatuses(stationId) // REFRESHING THE LOCKERS STATUSES..!
  }

  onReadyOrderListItemClick(orderId, orderData) {
    this.setState({
      selectedOrderId: orderId,
      selectedOrderData: orderData,
    })
  }
  componentDidUpdate() {
    if (this.refOnSelectOrder.current != null) {
      this.executeScroll()
    }
  }
  executeScroll = () => this.refOnSelectOrder.current.scrollIntoView()

  renderReadyOrdersList() {
    let { readyOrdersList, orderTypesArr } = this.props
    const { selectedOrderId } = this.state

    if (readyOrdersList.length <= 0) {
      return <div className="no-orders-text">אין הזמנות להעמסה בעמדה זו</div>
    }

    readyOrdersList.forEach((element) => {
      element.orderTypeName = getOrderTypeTextFromOrderType(
        orderTypesArr,
        element.orderType
      )
    })

    return (
      <ReadyOrdersDetailsList
        HeaderItems={[
          "מס' הזמנה",
          "מס' חבילה",
          "נייד",
          "שם לקוח",
          "סוג הזמנה",
          "פעולה",
        ]}
        Items={readyOrdersList}
        onItemClick={(orderId, orderData) =>
          this.onReadyOrderListItemClick(orderId, orderData)
        }
        orangeListItem={selectedOrderId}
        refOnSelect={this.refOnSelectOrder}
      />
    )
  }

  closeWaitingForLockModal(notifyServerDriverPutCleanOrder) {
    const {
      selectedOrderId,
      lockerId,
      lastOpenDoorSuccessLockerNum: lastLNum,
    } = this.state
    const { stationId } = this.props

    this.setState({
      showWaitingForLockModal: false,
      enableUnresponsiveDoorBtn: false,
      selectedOrderId: null,
      lockerId: null,
      lastOpenDoorSuccessLockerNum: notifyServerDriverPutCleanOrder
        ? lastLNum
        : null,
    })

    if (notifyServerDriverPutCleanOrder) {
      this.props.onDriverReturnesCleanOrder(
        selectedOrderId,
        lockerId,
        stationId
      )

      setTimeout(() => this.updateCellsAndList(), 800)
    }
  }

  render() {
    const {
      selectedLocker,
      showWarningModal,
      showWarning2Modal,
      showWarning3Modal,
      showWaitingForLockModal,
      selectedOrderData,
      currentlyOpeningALocker,
      lockerNum,
      showOpeningLockerFailModal,
      enableUnresponsiveDoorBtn,
    } = this.state
    const { driver_menu_ready_orders_loading, close_text, dirRtl } = this.props
    const enableUnresponsiveClose10sPassed = enableUnresponsiveDoorBtn
      ? () => this.closeWaitingForLockModal(true)
      : null

    return (
      <div className="barcode-screen-wrapper">
        <FixedHeader
          orderId="שלום נהג שמח!"
          suborderId="פרטי הזמנה להעמסה"
          buttonsFlag="loadCleanOrdersScreen"
        />
        <div className="driver-screen-title">
          {driver_menu_ready_orders_loading}
        </div>
        <div className="dlacs-frame-container">
          <div className="dlacs-inner-wrapper">
            <LockerCells
              onCellClick={this.onCellClick.bind(this)}
              blinkers={{ [selectedLocker]: selectedLocker }}
              extraClass="dlacs-cells"
            />
          </div>
          {this.renderReadyOrdersList()}
          <OrderBarcodeReader
            onOrderFound={(orderId, orderData) =>
              this.onReadyOrderListItemClick(orderId, orderData)
            }
          />
        </div>

        <LoadingInfoModal
          show={currentlyOpeningALocker}
          title={`פותח את תא מספר ${lockerNum}`}
          text="...אנא המתן"
          dirRtl={dirRtl}
        />

        <WarningInfoModal
          show={showWarningModal}
          title="בחר הזמנה תחילה"
          text="בכדי להעמיס את התא הנבחר, בחר את ההזמנה תחילה"
          onModalCloseClick={() => this.setState({ showWarningModal: false })}
          btnText={close_text}
          dirRtl={dirRtl}
        />

        <WarningInfoModal
          show={showWarning2Modal}
          title="לוקר זה תפוס"
          text='הלוקר הנבחר תפוס כבר ע"י הזמנה אחרת, אנא בחר לוקר אחר להעמסה'
          onModalCloseClick={() => this.setState({ showWarning2Modal: false })}
          btnText={close_text}
          dirRtl={dirRtl}
        />
        <WarningInfoModal
          show={showWarning3Modal}
          title="לוקר זה אינו מתאים לאחסון כביסה"
          text="הלוקר הנבחר אינו מסוג הלוקרים שיכולים להכיל כביסה, אלא דואר או כל מוצר מסוג אחר"
          onModalCloseClick={() => this.setState({ showWarning3Modal: false })}
          btnText={close_text}
          dirRtl={dirRtl}
        />

        <WaitingForLockerLock
          title={`,פותח את תא מספר ${lockerNum}. בסיום הנחת ההזמנה בלוקר`}
          subTitle="נא לטרוק את דלת התא"
          show={showWaitingForLockModal}
          orderData={selectedOrderData}
          onUnresponsiveDoorClick={enableUnresponsiveClose10sPassed}
          onOopsClicked={() => this.closeWaitingForLockModal(false)}
          dirRtl={dirRtl}
        />

        <ErrorInfoModal
          show={showOpeningLockerFailModal}
          title="תקלה בעת ניסיון פתיחת הדלת"
          text="אירעה תקלה בעת ניסיון פתיחת הדלת לאחר 4 פעמים"
          onModalCloseClick={() =>
            this.setState({ showOpeningLockerFailModal: false })
          }
          closeTrnsl={close_text}
          dirRtl={dirRtl}
        />
        <FixedFooter history={this.props.history} />
      </div>
    )
  }
}
const mapStateToProps = ({
  readyOrders,
  lockers,
  bgService,
  lockersCells,
  orderTypes,
  userLogin,
  stationServiceTypes,
  translate,
}) => {
  const { authenticated, role } = userLogin
  const { isStationHaveBarcodeReader } = stationServiceTypes
  const { readyOrdersArr } = readyOrders
  const { openedLocker } = lockers
  const { stationId } = bgService
  const { orderTypesArr } = orderTypes
  const { driver_menu_ready_orders_loading, close_text } = translate

  const {
    openedLockersArr,
    errorLockersArr,
    openingAttempsMap,
    recentClosedLockersArr,
    recentOpenAttemptLockerNum,
  } = lockersCells

  return {
    authenticated,
    role,
    readyOrdersList: readyOrdersArr,
    openedLocker,
    stationId,
    orderTypesArr,
    isStationHaveBarcodeReader,

    openedLockersArr,
    errorLockersArr,
    openingAttempsMap,
    recentClosedLockersArr,
    recentOpenAttemptLockerNum,

    driver_menu_ready_orders_loading,
    close_text,
  }
}
export default connect(mapStateToProps, {
  onGetReadyOrders,
  onDriverReturnesCleanOrder,
  setPageAsVisited,
  logScreenEnterance,
  LogoutUser,
  fetchAllLockersStatuses,
  openAndListenToSpecificLockerLock,
  fetchOrderTypes,
})(LoadCleanOrdersScreen)
