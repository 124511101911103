import axios from "axios"
import api from "../api"

import {
  ON_PAYMENT_SUCCESS,
  ON_PAYMENT_FAIL,
  ON_PAYMENT_REQUEST_START,
  ON_PAYMENT_NETWORK_FAIL,
  RESET_PAYMENT_TO_TRY_AGAIN,
  ON_PELE_TOKEN_SAVED_IN_SERVER_SUCCESS,
  ON_PELE_TOKEN_SAVED_IN_SERVER_FAIL,
  ON_PELE_TOKEN_SAVED_IN_SERVER_REQUEST,
} from "../actions/types"

import { reportFBEvent } from "./FBEventsActions"
import { axiosPutRetry } from "../utils"

export const payWithPelecard = (
  cCard,
  formattedDate,
  id,
  cvv,
  total,
  orderId
) => {
  return (dispatch) => {
    dispatch({ type: ON_PAYMENT_REQUEST_START })
    axios
      .post(
        api.payWithPelecard,
        { cCard, formattedDate, id, cvv, total },
        { timeout: 30000 }
      )
      .then((res) => {
        const { status, errCode, pelecardResponse, cardToken, cardTokenErr } =
          res.data
        console.log("PELECARD RESPONSE", res.data)
        if (status === "ok") {
          console.log("PAYMENT IS OK!")
          axiosPutRetry(api.markOrderAsPayed(orderId), pelecardResponse)
            .then((res) => console.log(res.data))
            .catch((err) => console.log(err))

          setTimeout(
            () =>
              dispatch({
                type: ON_PAYMENT_SUCCESS,
                payload: cardToken,
              }),
            3000
          )

          dispatch(
            reportFBEvent("Payment_Success", "Order Was Payed Successfully")
          )

          if (cardTokenErr) {
            console.log("CARD TOKEN ERR =>", cardTokenErr)
          }
        } else {
          const { errMsg } = res.data
          console.log("PAYMENT FAILED!")
          console.log("errMsg:" + errMsg)
          console.log(res)

          if (errCode === 1) {
            // WHEN THIS IS A NETWORK ERROR..
            console.log("ON_PAYMENT_NETWORK_FAIL")
            setTimeout(() => dispatch({ type: ON_PAYMENT_NETWORK_FAIL }), 3000)
          } else if (errCode === 2) {
            // WHEN THIS IS A REJECTION FROM 'PELECARD'..
            console.log("ON_PAYMENT_FAIL")
            setTimeout(() => dispatch({ type: ON_PAYMENT_FAIL }), 3000)
          }
        }
      })
      .catch((err) => {
        console.log("ON_PAYMENT_NETWORK_FAIL CATCH FAIL..!!??")
        setTimeout(() => dispatch({ type: ON_PAYMENT_NETWORK_FAIL }), 3000)
      })
  }
}
export const onPaymentFail = () => {
  return (dispatch) => {
    dispatch({ type: ON_PAYMENT_FAIL })
  }
}
export const resetPaymentToTryAgain = () => {
  return (dispatch) => {
    dispatch({ type: RESET_PAYMENT_TO_TRY_AGAIN })
  }
}

export const createCreditToken = (userId, cCard, formattedDate) => {
  return (dispatch) => {
    axios
      .post(api.getPelecardToken, { cCard, formattedDate })
      .then((res) => {
        const { receivedToken } = res.data

        dispatch(saveCreditTokenInServer(userId, receivedToken))
      })
      .catch((err) => console.log(err))
  }
}

export const saveCreditTokenInServer = (userId, token) => {
  return (dispatch) => {
    dispatch({
      type: ON_PELE_TOKEN_SAVED_IN_SERVER_REQUEST,
    })

    axiosPutRetry(
      api.saveUserCreditTokenInServerUrl(userId),
      api.saveUserCreditTokenInServerBody(token)
    )
      .then((res) => {
        console.log("USER'S TOKEN IS SAVED!")
        dispatch({ type: ON_PELE_TOKEN_SAVED_IN_SERVER_SUCCESS })
      })
      .catch((err) => {
        dispatch({ type: ON_PELE_TOKEN_SAVED_IN_SERVER_FAIL })
        console.log("USER'S TOKEN SAVE ERROR!", err)
      })
  }
}

export const payWithPelecardToken = (cardToken, total, orderId) => {
  return (dispatch) => {
    dispatch({ type: ON_PAYMENT_REQUEST_START })

    axios
      .post(api.payUsingPelecardToken, { cardToken, total }, { timeout: 30000 })
      .then((res) => {
        const { status, errCode, pelecardResponse } = res.data
        if (status === "ok") {
          console.log("TOKEN PAYMENT IS OK!")
          // axios.put(api.markOrderAsPayed(orderId), pelecardResponse)
          axiosPutRetry(api.markOrderAsPayed(orderId), pelecardResponse)
            .then((res) => console.log(res.data))
            .catch((err) => console.log(err))

          setTimeout(
            () =>
              dispatch({
                type: ON_PAYMENT_SUCCESS,
                payload: null,
              }),
            3000
          )
        } else {
          console.log("TOKEN PAYMENT FAILED!")

          if (errCode === 1) {
            // WHEN THIS IS A NETWORK ERROR..
            console.log("ON_PAYMENT_NETWORK_FAIL - TOKEN")
            setTimeout(() => dispatch({ type: ON_PAYMENT_NETWORK_FAIL }), 3000)
          } else if (errCode === 2) {
            // WHEN THIS IS A REJECTION FROM 'PELECARD'..
            console.log("ON_PAYMENT_FAIL - TOKEN")
            setTimeout(() => dispatch({ type: ON_PAYMENT_FAIL }), 3000)
          }
        }
      })
      .catch((err) => {
        console.log("ON_PAYMENT_NETWORK_FAIL CATCH FAIL..!!?? - TOKEN", err)
        setTimeout(() => dispatch({ type: ON_PAYMENT_NETWORK_FAIL }), 3000)
      })
  }
}
