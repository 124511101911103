import React, { Component } from "react"
import { connect } from "react-redux"
import {
  logScreenEnterance,
  onCloseOrder,
  openAndListenToSpecificLockerLock,
  reportFBEvent,
  setThankYouSubTitle,
  resetCoupons,
  addCouponByNameToOrder,
  LogoutUser,
} from "../../../../actions"
import additionalOrdersImg from "../../../../assets/images/additional_orders.png"
import { OrangeButton, OrderDetailsList, WhiteLabel } from "../../../common"
import ErrorInfoModal from "../../../Modals/ErrorInfoModal"
import LoadingInfoModal from "../../../Modals/LoadingInfoModal"
import PaymentModal1 from "../../../Modals/PaymentModal1"
import PaymentModal2 from "../../../Modals/PaymentModal2"
import PaymentModal3 from "../../../Modals/PaymentModal3"
import WaitingForLockerLock from "../../../Modals/WaitingForLockerLock"
import WarningInfoModal from "../../../Modals/WarningInfoModal"
import AttachCouponModal from "../../../Modals/AttachCouponModal"
import { FixedFooter, FixedHeader, LockerCells } from "../../../reduxified"
import "./style.css"

class CollectOrderScreen extends Component {
  constructor(props) {
    super(props)
    this.INIT_STATE = {
      showPayModal: false,
      showDoorOpenErrorModal: false,
      closedOrderSent: false,
      isMoreThanOneOrderWaitShow: false,
      showAttachCouponModal: false,
    }
    this.state = this.INIT_STATE
  }

  componentDidMount() {
    this.props.logScreenEnterance("USER_COLLECT_ORDER_SCREEN")
    const rentalOrder = this.props.history.location.state
    let { displayedOrder, numOfReadyOrdersToCollect } = this.props
    if (rentalOrder) {
      displayedOrder = rentalOrder
    }
    if (displayedOrder == null) {
      //add report
      this.props.reportFBEvent(
        "CollectOrderScreen",
        "The displayedOrder is null. Redirect to homepage."
      )
      this.props.LogoutUser()
      this.props.history.push("")
    }
    if (numOfReadyOrdersToCollect > 1) {
      this.setState({ isMoreThanOneOrderWaitShow: true })
    }

    const img = new Image()
    img.src = additionalOrdersImg
  }

  componentWillUnmount() {
    this.setState(this.INIT_STATE)
  }

  componentWillReceiveProps(newProps) {
    const { authenticated } = newProps

    if (!authenticated) {
      //add report
      this.props.reportFBEvent(
        "CollectOrderScreen",
        "Not authenticated. Redirect to homepage."
      )
      this.props.LogoutUser()
      this.props.history.push("")
    }

    const {
      orderWasCollected,
      openingErrorOccurred,
      openingDoorSuccess,
      openAttemptsNum,
      lockerNum,
      see_you_again,
      displayedOrder,
    } = newProps

    if (displayedOrder == null) {
      this.props.reportFBEvent(
        "CollectOrderScreen",
        "The displayedOrder is null. Redirect to homepage."
      )
      this.props.LogoutUser()
      this.props.history.push("")
      return
    }
    if (orderWasCollected) {
      console.log("Locker " + displayedOrder.lockerNum + "is closed.")
      this.closeOrder(displayedOrder)
      this.props.setThankYouSubTitle(see_you_again)
      this.props.history.push("ThankYouScreen")
      return
    }

    if (openingErrorOccurred) {
      console.log("openingErrorOccurred - ", openingErrorOccurred, "!!!")

      if (openAttemptsNum < 4) {
        this.openAndListenToLocker(displayedOrder.lockerNum, 5)
      } else {
        this.setState({ showDoorOpenErrorModal: true })
      }
    }

    if (openingDoorSuccess) {
      console.log("Locker " + displayedOrder.lockerNum + " is opened.")
      this.closeOrder(displayedOrder)
    }
  }

  closeOrder(order) {
    console.log("closeOrder >")
    console.log(order)
    const { closedOrderSent } = this.state
    if (closedOrderSent) {
      console.log("Close order allready was sent. Locker:" + order.lockerNum)
      return
    }
    console.log("Go to close order.")
    const { orderId, locationId: lockerId } = order
    this.props.onCloseOrder(orderId, lockerId)
    this.setState({ closedOrderSent: true })
  }

  onOkClick() {
    console.log("onOkClick לתשלום ולפתיחה")
    const { orderTotalPrice, isOrderPayed } = this.props

    if (orderTotalPrice === 0 || isOrderPayed) {
      this.onPaymentSuccess()
      return
    }
    this.setState({ showPayModal: true })
  }

  onPaymentSuccess() {
    let { lockerNum } = this.props
    this.setState({ showPayModal: false })

    if (!lockerNum) {
      console.log(
        "CollectOrderScreen>onPaymentSuccess> no lockerNum:" + lockerNum
      )
      setTimeout(() => this.onPaymentSuccess(), 5 * 1000)
      return
      //lockerNum = 11111
    }
    console.log(
      "CollectOrderScreen>onPaymentSuccess> have lockerNum:" + lockerNum
    )
    this.openAndListenToLocker(lockerNum)
  }

  onPaymentNetworkFail() {
    let { lockerNum } = this.props
    this.setState({ showPayModal: false })

    if (!lockerNum) {
      console.log(
        "CollectOrderScreen> onPaymentNetworkFail> no lockerNum:" + lockerNum
      )
      setTimeout(() => this.onPaymentNetworkFail(), 5 * 1000)
      return
      //lockerNum = 11111
    }
    console.log(
      "CollectOrderScreen> onPaymentNetworkFail> have lockerNum:" + lockerNum
    )

    this.openAndListenToLocker(lockerNum)
  }

  closePaymentModal() {
    this.setState(this.INIT_STATE)
  }

  openAndListenToLocker(lockerNum, attempts) {
    this.props.openAndListenToSpecificLockerLock(lockerNum, attempts)
  }

  onDoorOpenErrModalClose() {
    const { see_you_again } = this.props

    this.props.setThankYouSubTitle(see_you_again)
    this.props.history.push("ThankYouScreen")
  }

  onAttachCouponClick() {
    this.setState({
      showAttachCouponModal: true,
    })
  }

  closeAttachCouponModal() {
    this.props.resetCoupons()
    this.setState({ showAttachCouponModal: false })
  }

  onAddingCoupon(coupon) {
    if (!coupon || coupon.couponId === "") {
      this.closeAttachCouponModal()
      return
    }

    this.setState({
      ...this.state,
      coupon: {
        couponName: coupon.couponName,
        couponId: coupon.couponId,
        couponDescription: coupon.couponDescription,
        discount: coupon.discount,
        discountType: coupon.discountType,
      },
    })

    //TODO update displayedOrder

    this.props.addCouponByNameToOrder(this.props.orderId, coupon.couponName)

    this.closeAttachCouponModal()
  }

  renderDiscountInfo() {
    const {
      couponId,
      discount,
      discountType,
      you_got_coupon_discount,
      dirRtl,
    } = this.props
    if (!discount || discount == 0) {
      return <></>
    }
    if (discountType == 0) {
      return (
        <div className="discount-info">
          <div className={dirRtl ? "rtl" : "ltr"}>
            {you_got_coupon_discount}
          </div>
          <div className={dirRtl ? "ltr" : "rtl"}> %{discount}</div>
        </div>
      )
    }
    return (
      <div className="discount-info">
        <div className={dirRtl ? "rtl" : "ltr"}>{you_got_coupon_discount}</div>
        <div className={dirRtl ? "ltr" : "rtl"}>
          ₪{(discount * 1.17).toFixed(2)}
        </div>
      </div>
    )
  }

  render() {
    const {
      lockerNum,
      orderItems,
      orderTotalPrice,
      orderNumber,
      orderId,
      couponId,
      isOrderPayed,
      orderType,
      errLockers,
      blinkers,
      openingDoorSuccess,
      openAttemptsNum,
      dirRtl,
      collectable_order_details,
      order_number,
      total_to_pay_text,
      item_description,
      item_quantity,
      pay_and_open_locker_btn_text,
      open_locker_btn_text,
      opening_locker_number,
      please_wait,
      waitingForLockerLockTitle,
      pls_close_door,
      err_while_opening_door,
      pls_contact_us_to_open_locker,
      close_text,
      more_than_one_ready_order_title,
      more_than_one_ready_order_text,
      add_coupon_btn_text,
      isLoading,
    } = this.props
    const {
      showDoorOpenErrorModal,
      showPayModal,
      isMoreThanOneOrderWaitShow,
      showAttachCouponModal,
    } = this.state

    const textOnContinueOpanPayBtn =
      orderTotalPrice !== 0 && !isOrderPayed
        ? pay_and_open_locker_btn_text
        : open_locker_btn_text

    const { numOfReadyOrdersToCollect, stationPaymentVersion } = this.props
    const message = more_than_one_ready_order_text + numOfReadyOrdersToCollect
    const showAddCoupon =
      (!couponId || couponId.length <= 0) &&
      !isOrderPayed &&
      orderTotalPrice !== 0

    return (
      <div
        className={`collect-order-screen ${
          dirRtl ? "ls-wrapper-rtl" : "ls-wrapper-ltr"
        }`}
      >
        <FixedHeader
          subTitle={collectable_order_details}
          buttonsFlag="collectOrderScreen"
        />

        <div className="nos-frame-container" dir={dirRtl ? "rtl" : "ltr"}>
          <div className="cos-inner-container">
            <div className="cos-inner-wrapper" dir={dirRtl ? "rtl" : "ltr"}>
              <LockerCells
                extraClass="collect-order-cells"
                blinkers={blinkers}
                errLockers={errLockers}
                dirRtl={dirRtl}
                hideInfo
                showMinified
              />
            </div>
            <div className="cos-column">
              <div className="cos-table-total">
                <div>
                  <div className="order-number">
                    <div className={`${dirRtl ? "rtl" : "ltr"}`}>
                      {order_number}
                    </div>
                    <div className={`${dirRtl ? "rtl" : "ltr"}`}>
                      {orderNumber}
                    </div>
                  </div>

                  <OrderDetailsList
                    HeaderItems={[item_description, item_quantity]}
                    Items={orderItems}
                  />
                </div>
              </div>
              <div className="cos-frame-btns">
                {this.renderDiscountInfo()}
                <div className="order-cost">
                  <div className={dirRtl ? "rtl" : "ltr"}>
                    {total_to_pay_text}
                  </div>
                  <div className={dirRtl ? "ltr" : "rtl"}>
                    {" "}
                    {`₪${orderTotalPrice && orderTotalPrice.toFixed(2)}`}
                  </div>
                </div>

                <div>
                  <OrangeButton
                    show={showAddCoupon}
                    disabled={isLoading}
                    extraClass="nos-frame-single-btn white-button"
                    onClick={() => this.onAttachCouponClick()}
                  >
                    {add_coupon_btn_text}
                  </OrangeButton>

                  <OrangeButton
                    onClick={() => this.onOkClick()}
                    extraClass="collect-submit-btn"
                  >
                    {textOnContinueOpanPayBtn}
                  </OrangeButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <FixedFooter history={this.props.history} />
        </div>

        <LoadingInfoModal
          dirRtl={dirRtl}
          show={openAttemptsNum > 0 && !showDoorOpenErrorModal}
          title={opening_locker_number.replace("{XYZ}", lockerNum)}
          text={please_wait}
        />

        <WaitingForLockerLock
          dirRtl={dirRtl}
          show={openingDoorSuccess}
          title={waitingForLockerLockTitle.replace("{XYZ}", lockerNum)}
          subTitle={pls_close_door}
        />

        <ErrorInfoModal
          dirRtl={dirRtl}
          show={showDoorOpenErrorModal}
          title={err_while_opening_door}
          text={pls_contact_us_to_open_locker.replace("{XYZ}", lockerNum)}
          onModalCloseClick={() => this.onDoorOpenErrModalClose()}
          closeTrnsl={close_text}
        />

        <PaymentModal1
          show={showPayModal && stationPaymentVersion === 1}
          total={orderTotalPrice}
          orderId={orderId}
          onUserPaymentSuccess={() => this.onPaymentSuccess()}
          onPaymentNetworkFail={() => this.onPaymentNetworkFail()}
          onCloseModalClick={() => this.closePaymentModal()}
          dirRtl={dirRtl}
        />

        <PaymentModal2
          show={showPayModal && stationPaymentVersion === 2}
          total={orderTotalPrice}
          orderId={orderId}
          onUserPaymentSuccess={() => this.onPaymentSuccess()}
          onPaymentNetworkFail={() => this.onPaymentNetworkFail()}
          onCloseModalClick={() => this.closePaymentModal()}
          dirRtl={dirRtl}
        />

        <PaymentModal3
          show={showPayModal && stationPaymentVersion === 3}
          total={orderTotalPrice}
          orderId={orderId}
          onUserPaymentSuccess={() => this.onPaymentSuccess()}
          onCloseModalClick={() => this.closePaymentModal()}
          dirRtl={dirRtl}
        />

        <WarningInfoModal
          show={isMoreThanOneOrderWaitShow}
          title={more_than_one_ready_order_title}
          text={message}
          icon="additional_orders"
          onModalCloseClick={() =>
            this.setState({ isMoreThanOneOrderWaitShow: false })
          }
          btnText={close_text}
          dirRtl={dirRtl}
          moreThanOneOrder
        />

        <AttachCouponModal
          dirRtl={dirRtl}
          show={showAttachCouponModal}
          onOkClick={(coupon) => this.onAddingCoupon(coupon)}
          onCancelClick={() => this.closeAttachCouponModal()}
          orderType={orderType}
        />
      </div>
    )
  }
}

const mapStateToProps = ({
  lockers,
  activeOrders,
  lockersCells,
  translate,
  activeDelivery,
  stationServiceTypes,
  userLogin,
}) => {
  const { authenticated } = userLogin
  let { displayedOrder, numOfReadyOrdersToCollect, rentalOrder, isLoading } =
    activeOrders
  const { openedLocker } = lockers
  const {
    openedLockersArr,
    errorLockersArr,
    openingAttempsMap,
    recentClosedLockersArr,
  } = lockersCells
  const { stationPaymentVersion } = stationServiceTypes
  if (activeDelivery.isDeliveryByCodeLoaded) {
    displayedOrder = activeDelivery.displayedOrder
  }

  if (rentalOrder !== null) {
    displayedOrder = rentalOrder
  }

  let {
    orderItems,
    orderTotalPrice,
    orderNumber,
    orderId,
    orderType,
    couponId,
    discount,
    discountType,
  } = displayedOrder || []

  if (!orderItems) {
    orderItems = []
  }

  const { lockerNum, isOrderPayed } = displayedOrder || {}
  const openingDoorSuccess = openedLockersArr.includes(lockerNum)
  const openingErrorOccurred = errorLockersArr.includes(lockerNum)
  const orderWasCollected = recentClosedLockersArr.includes(lockerNum)
  const openAttemptsNum = openingAttempsMap[lockerNum] || 0
  const blinkers = !openingErrorOccurred ? { [lockerNum]: lockerNum } : {}
  const errLockers = openingErrorOccurred ? { [lockerNum]: lockerNum } : {}

  const {
    dirRtl,
    collectable_order_details,
    see_you_again,
    order_number,
    item_description,
    item_quantity,
    total_to_pay_text,
    pay_and_open_locker_btn_text,
    open_locker_btn_text,
    opening_locker_number,
    please_wait,
    on_order_collection_finish,
    pls_close_door,
    err_while_opening_door,
    pls_contact_us_to_open_locker,
    close_text,
    num_of_other_ready_orders,
    more_than_one_ready_order_title,
    more_than_one_ready_order_text,
    back_btn_text,
    add_coupon_btn_text,
    you_got_coupon_discount,
  } = translate

  const waitingForLockerLockTitle = `${opening_locker_number}. ${on_order_collection_finish}`

  return {
    authenticated,
    openedLocker,
    orderWasCollected,
    orderItems,
    lockerNum,
    orderTotalPrice,
    orderNumber,
    displayedOrder,
    orderId,
    couponId,
    orderType,
    discount,
    discountType,
    numOfReadyOrdersToCollect,
    openingDoorSuccess,
    openingErrorOccurred,
    blinkers,
    errLockers,
    openAttemptsNum,
    isOrderPayed,
    back_btn_text,
    waitingForLockerLockTitle,
    dirRtl,
    collectable_order_details,
    see_you_again,
    order_number,
    item_description,
    item_quantity,
    total_to_pay_text,
    pay_and_open_locker_btn_text,
    open_locker_btn_text,
    opening_locker_number,
    please_wait,
    on_order_collection_finish,
    pls_close_door,
    err_while_opening_door,
    pls_contact_us_to_open_locker,
    close_text,
    num_of_other_ready_orders,
    more_than_one_ready_order_title,
    more_than_one_ready_order_text,
    add_coupon_btn_text,
    you_got_coupon_discount,
    stationPaymentVersion,
    isLoading,
  }
}

export default connect(mapStateToProps, {
  openAndListenToSpecificLockerLock,
  onCloseOrder,
  setThankYouSubTitle,
  logScreenEnterance,
  reportFBEvent,
  resetCoupons,
  addCouponByNameToOrder,
  LogoutUser,
})(CollectOrderScreen)
