import React, { Component } from "react"
import { connect } from "react-redux"
import { isProdEnv } from "../../../../utils"
import {
  onUserSignInClick,
  readStationIdFromBgService,
  readBgServiceConfiguration,
  resetAllReducers,
  changeDisplayedLanguage,
  fetchAllLockersStatuses,
  fetchAllLockersLayout,
  fetchPriceListByStationId,
  getAllOpenedLockers,
  reportSuspiciousBrokenLockers,
  logScreenEnterance,
  fetchStationServiceTypes,
} from "../../../../actions"
import { AppVersion } from "../../../../utils/Env"
import {
  FixedHeader,
  FixedFooter,
  FixedLeftBottomButton,
} from "../../../reduxified"
import ServiceMenuHorizontalButton from "../../../common/ServiceMenuHorizontalButton"
import ErrorInfoModal from "../../../Modals/ErrorInfoModal"
import OpenedDoorsWarningModal from "../../../Modals/OpenedDoorsWarningModal"
import AppQRCode from "../../../common/AppQRCode"

import "./style.css"

class ServiceMenuAppQRScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errorsTimeout: null,
      showErrorModal: false,
      runValidationsTimeOut: null,
      askedAboutOpenedDoors: false,
      openedDoorModalCloseClicked: false,
    }
  }

  componentWillMount() {
    
    this.props.resetAllReducers()
    this.props.readStationIdFromBgService()
    this.props.readBgServiceConfiguration()
  }

  componentDidMount() {
    const { maxLockerNumber, brokenLockersNumArr, stationId } = this.props

    if (maxLockerNumber) {
      this.setState({ askedAboutOpenedDoors: true })
      this.props.getAllOpenedLockers(maxLockerNumber, brokenLockersNumArr)
    }
    if (stationId != null) {
      this.props.fetchStationServiceTypes(stationId)
      // console.log(this.props.fetchStationServiceTypes(stationId));
    }
    this.props.logScreenEnterance("SERVICE_MENU_SCREEN")
  }

  componentWillReceiveProps(newProps) {
    const {
      errTitle,
      errText,
      stationId,
      langName,
      maxLockerNumber,
      brokenLockersNumArr,
      isDeliveryActive,
      isLaundryActive,
      isLockerRentActive,
      isLockerToLockerDeliveryActive,
    } = newProps

    //! TODOD- RESET THE ERR TITLE AND TEXT WHEN STARTING NEW REQ!
    const {
      errTitle: oldErrTitle,
      errText: oldErrText,
      maxLockerNumber: oldMaxLockerNum,
    } = this.props

    if (oldErrTitle !== errTitle || oldErrText !== errText) {
      setTimeout(
        () =>
          this.setState({
            showErrorModal: true,
          }),
        250
      )
    }

    if (stationId === -1) {
      this.props.history.push("MaintenanceScreen")
      return
    }

    if (stationId && !this.props.stationId) {
      // WOULD RUN ONLY THE FIRST TIME.. :)
      this.props.fetchStationServiceTypes(stationId)
      this.props.fetchAllLockersStatuses(stationId)
      this.props.fetchAllLockersLayout(stationId)
      this.props.fetchPriceListByStationId(stationId, langName)
    }

    if (
      stationId &&
      this.props.stationId &&
      !this.isStationHaveAnyType(
        isDeliveryActive,
        isLaundryActive,
        isLockerRentActive,
        isLockerToLockerDeliveryActive
      )
    ) {
      this.props.history.push("MaintenanceScreen")
      return
    }

    const { askedAboutOpenedDoors } = this.state

    if (!askedAboutOpenedDoors && maxLockerNumber && !oldMaxLockerNum) {
      this.props.getAllOpenedLockers(maxLockerNumber, brokenLockersNumArr)
    }
  }

  isStationHaveAnyType(
    isDeliveryActive,
    isLaundryActive,
    isLockerRentActive,
    isLockerToLockerDeliveryActive
  ) {
    const isAnyType =
      isDeliveryActive ||
      isLaundryActive ||
      isLockerRentActive ||
      isLockerToLockerDeliveryActive
    return isAnyType
  }

  onLangChange(lang) {
    const { stationId } = this.props
    this.props.fetchStationServiceTypes(stationId)
    this.props.changeDisplayedLanguage(lang)
    this.props.fetchPriceListByStationId(stationId, lang)
  }

  onWarningOpenedDoorsModalOkClicked() {
    const { maxLockerNumber, brokenLockersNumArr } = this.props

    this.props.getAllOpenedLockers(maxLockerNumber, brokenLockersNumArr)
  }

  onOpenedDoorWarningModalCloseClicked() {
    console.log("onOpenedDoorWarningModalCloseClicked")

    const { stationId, lockersWithOpenStatusFromHw } = this.props

    this.setState({ openedDoorModalCloseClicked: true })

    this.props.reportSuspiciousBrokenLockers(
      stationId,
      lockersWithOpenStatusFromHw
    )
  }

  render() {
    const { showErrorModal, openedDoorModalCloseClicked } = this.state

    const {
      errTitle,
      errText,
      lockersWithOpenStatusFromHw,
      dirRtl,
      login_menu_title,
      login_menu_delivery,
      login_menu_landry,
      close_text,
      isDeliveryActive,
      isLaundryActive,
      isLockerRentActive,
      isLockerToLockerDeliveryActive,
      main_title,
      rent_a_locker,
      locker_delivery,
      service_menu_app_link_title,
      service_menu_app_qr,
    } = this.props

    return (
      <div className="ls-wrapper">
        {!isProdEnv() && (
          <div className="testing-version">{`TESTING v${AppVersion}`}</div>
        )}
        <FixedHeader title={login_menu_title} subTitle="" />

        <div className="services-qr-wrapper" dir={dirRtl ? "rtl" : "lrt"}>
          <div className="services-wrapper">
            <div className="services-lines">
              <div className="menu-title">{main_title}</div>
            </div>
            <div className="services-lines">
              <ServiceMenuHorizontalButton
                show={isLaundryActive}
                serviceType="laundry"
                text={login_menu_landry}
                onClick={() =>
                  this.props.history.push(
                    "UserLoginWithDoneLoginScreen",
                    "laundry"
                  )
                }
              />
              <ServiceMenuHorizontalButton
                show={isLockerToLockerDeliveryActive}
                serviceType="locker-delivery"
                text={locker_delivery}
                onClick={() =>
                  this.props.history.push(
                    "LockerToLockerDeliveryScreen/info",
                    "lockerDelivery"
                  )
                }
              />
            </div>
            <div className="services-lines">
              <ServiceMenuHorizontalButton
                show={isLockerRentActive}
                serviceType="locker-rent"
                text={rent_a_locker}
                onClick={() => this.props.history.push("AboutRental", "rent")}
              />
              <ServiceMenuHorizontalButton
                show={isDeliveryActive}
                serviceType="delivery"
                text={login_menu_delivery}
                onClick={() => this.props.history.push("SmsLoginScreen")}
              />
            </div>
          </div>
          <div className="qr-wrapper">
            <div className="services-lines">
              <div className="menu-title">{service_menu_app_link_title}</div>
            </div>
            <div className="services-lines">
              <div className="qr-text">{service_menu_app_qr}</div>
            </div>
            <div className="services-lines">
              <AppQRCode show={true} />
            </div>
          </div>
        </div>

        <ErrorInfoModal
          dirRtl={dirRtl}
          show={showErrorModal && errText && errText}
          title={errTitle}
          text={errText}
          onModalCloseClick={() => this.setState({ showErrorModal: false })}
          closeTrnsl={close_text}
        />

        <OpenedDoorsWarningModal
          show={
            lockersWithOpenStatusFromHw.length > 0 &&
            !openedDoorModalCloseClicked
          }
          openedDoorsArr={lockersWithOpenStatusFromHw}
          onModalOkClick={() => this.onWarningOpenedDoorsModalOkClicked()}
          onModalCloseClick={() => this.onOpenedDoorWarningModalCloseClicked()}
        />

        <FixedFooter
          hideBack
          showDriverLogin
          onLangChange={(lang) => this.onLangChange(lang)}
          homePage
        />
      </div>
    )
  }
}

const mapStateToProps = ({
  userLogin,
  bgService,
  lockersCells,
  translate,
  stationServiceTypes,
}) => {
  const { stationId } = bgService
  const { maxLockerNumber, lockersWithOpenStatusFromHw, brokenLockersNumArr } =
    lockersCells
  const {
    authenticated,
    errTitle,
    errText,
    isLoadingRequest,
    isPasswordTemporary,
    userId,
  } = userLogin
  const {
    dirRtl,
    langName,
    welcome_title_text,
    login_menu_title,
    login_menu_delivery,
    login_menu_landry,
    login_menu_driver,
    close_text,
    system_updgrade_err_msg,
    main_title,
    rent_a_locker,
    locker_delivery,
    service_menu_app_link_title,
    service_menu_app_qr,
  } = translate
  const {
    isDeliveryActive,
    isLaundryActive,
    isLockerRentActive,
    isLockerToLockerDeliveryActive,
  } = stationServiceTypes

  return {
    authenticated,
    errTitle: translate[errTitle],
    errText: translate[errText],
    isLoadingRequest,
    isPasswordTemporary,
    userId,
    stationId,
    maxLockerNumber,
    lockersWithOpenStatusFromHw,
    brokenLockersNumArr,
    main_title,
    dirRtl,
    langName,
    welcome_title_text,
    login_menu_title,
    login_menu_delivery,
    login_menu_landry,
    login_menu_driver,
    close_text,
    system_updgrade_err_msg,
    isDeliveryActive,
    isLaundryActive,
    isLockerRentActive,
    isLockerToLockerDeliveryActive,
    rent_a_locker,
    locker_delivery,
    service_menu_app_link_title,
    service_menu_app_qr,
  }
}

export default connect(mapStateToProps, {
  onUserSignInClick,
  readStationIdFromBgService,
  readBgServiceConfiguration,
  resetAllReducers,
  changeDisplayedLanguage,
  fetchAllLockersStatuses,
  fetchAllLockersLayout,
  fetchPriceListByStationId,
  getAllOpenedLockers,
  reportSuspiciousBrokenLockers,
  logScreenEnterance,
  fetchStationServiceTypes,
})(ServiceMenuAppQRScreen)
