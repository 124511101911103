import openSocket from 'socket.io-client';
import {
    ON_LOCKER_LISTENER_START_WITH_OPEN_ATTEMPT,
    ON_LOCKER_LOCK_LISTENER_RESPONSE,
    ON_LOCKER_LOCK_LISTENER_FAIL_RESPONSE,
    ON_LOCKER_LISTENER_START_WITH_OPEN_ATTEMPT_SUCCESS_RESPONSE,
    ON_LOCKER_LISTENER_START_WITH_OPEN_ATTEMPT_FAIL,
    CLEAR_RECENTLY_CLOSED_LOCKER_DOOR,
    RESET_CELLS_ERRORS_OPEN_ATTEMPS,
    ON_GET_OPENED_LOCKERS_STATUS_RESPONSE
} from '../actions/types';

import { reportFBEvent } from './FBEventsActions';
import { logLockerOpenCloseFailToStationLogger } from './BgServiceActions';

const socket = openSocket('http://localhost:3001');

// UNCOMMNET WHEN NEEDED.. :)
// export const readLockerStatus = lockNum => {
//     return (dispatch) => {
//         socket.emit('readStatus', { lockNum });
//         socket.once('statusResponse', res => dispatch(onLockerStatusResponse(res)));
//     }
// }

// export const onLockerStatusResponse = res => {
//     console.log('onLockerStatusResponse', res);

//     return {
//         type: 'TODO..!!',
//         payload: res
//     };
// };

// UNCOMMNET WHEN NEEDED.. :)
// export const openSpecificLocker = lockNum => {
//     return (dispatch) => {
//         socket.emit('openLocker', { lockNum });
//         socket.once('openLockerResponse', res => {
//             if (res.status === 'OK') {
//                 console.log('LOCKER #', lockNum, 'WAS OPENED!');
//                 // dispatch(onLockerOpenSuccessResponse(res));
//             }
//             else {
//                 console.log('LOCKER #', lockNum, 'ERROR OPEN ATTEMPT WAS FAILED!');
//                 // dispatch(onLockerOpenFailResponse(res));
//             }
//         });
//     }
// }

// export const onLockerOpenSuccessResponse = res => {
//     console.log('onLockerStatusResponse', res);

//     return {
//         type: 'TODO..!!',
//         payload: res
//     };
// };

// export const onLockerOpenFailResponse = res => {
//     console.log('onLockerStatusResponse', res);

//     return {
//         type: 'TODO..!!',
//         payload: res
//     };
// };

export const openAndListenToSpecificLockerLock = (lockNum, attempts) => {
    
    return (dispatch) => {
        socket.emit('openLockerAndListen', { lockNum, attempts });
        const handleWSResponse = response => {
            console.log(`openLockerAndListenResponse_${lockNum} response`, response);

            const { status, isLocked } = response;
            if (status === 'OK') {

                if (isLocked) {
                    dispatch(onLockerOpenAndListeningSuccessResponse(lockNum));

                    dispatch(reportFBEvent('Door_Event', `Door Number ${lockNum} Was Locked!`));

                    // REMOVING THE CURRENT LISTENER ON DOOR CLOSE:
                    socket.removeEventListener(`openLockerAndListenResponse_${lockNum}`, handleWSResponse);
                } else {
                    dispatch(onLockerListenerOpenAttemptSuccessResponse(lockNum));

                    dispatch(reportFBEvent('Door_Event', `Door Number ${lockNum} Was Opened!`));
                }

            } else {

                dispatch(logLockerOpenCloseFailToStationLogger(isLocked, lockNum));

                if (isLocked) {
                    dispatch(onLockerListenerOpenAttemptFail(lockNum));
                } else {
                    dispatch(onLockerListenerCloseAttemptFail(lockNum));
                }

                // REMOVING ALL LISTENERS ON ERROR..!
                socket.removeEventListener(`openLockerAndListenResponse_${lockNum}`, handleWSResponse);
            }
        };

        socket.removeAllListeners(`openLockerAndListenResponse_${lockNum}`);
        socket.on(`openLockerAndListenResponse_${lockNum}`, handleWSResponse);
        dispatch(onLockerListenerStartWithOpenAttempt(lockNum));
    }
};


export const onLockerListenerStartWithOpenAttempt = (lockNum) => {
    
    console.log('onLockerListenerStartWithOpenAttempt', lockNum);
    return {
        type: ON_LOCKER_LISTENER_START_WITH_OPEN_ATTEMPT,
        payload: lockNum
    };
};

export const onLockerListenerOpenAttemptSuccessResponse = (lockNum) => {
    
    console.log('onLockerListenerOpenAttemptSuccessResponse', lockNum);
    return {
        type: ON_LOCKER_LISTENER_START_WITH_OPEN_ATTEMPT_SUCCESS_RESPONSE,
        payload: lockNum
    };
};

export const onLockerListenerOpenAttemptFail = (lockNum) => {
    
    console.log('onLockerListenerOpenAttemptFail', lockNum);
    return {
        type: ON_LOCKER_LISTENER_START_WITH_OPEN_ATTEMPT_FAIL,
        payload: lockNum
    };
};

export const onLockerOpenAndListeningSuccessResponse = (lockNum) => {
    console.log('onLockerOpenAndListeningSuccessResponse', lockNum);
    
    return {
        type: ON_LOCKER_LOCK_LISTENER_RESPONSE,
        payload: lockNum
    };
};

export const onLockerListenerCloseAttemptFail = (lockNum) => {
    console.log('onLockerListenerCloseAttemptFail', lockNum);
    
    return {
        type: ON_LOCKER_LOCK_LISTENER_FAIL_RESPONSE,
        payload: lockNum
    };
};

export const resetLockersErrorsAttemptsOpened = () => {
    console.log('resetLockersErrorsAttemptsOpened');
    
    return {
        type: RESET_CELLS_ERRORS_OPEN_ATTEMPS
    }
};

//************************READING ALL LOCKERS STATUSES************************

export const getAllOpenedLockers = (maxLockerNum, brokenLockerArr) => {
    return (dispatch) => {
        
        console.log('getAllOpenedLockers', maxLockerNum, brokenLockerArr);

        socket.emit('getAllOpenedLockers', { maxLockerNum });
        socket.once('getAllOpenedLockers', resp => {
            console.log('getAllOpenedLockers => RESPONSE', resp);

            const nonBrokenOpenedLockerNumsArr = resp.filter(num => !brokenLockerArr.includes(num) && num > 0)

            dispatch({
                type: ON_GET_OPENED_LOCKERS_STATUS_RESPONSE,
                payload: nonBrokenOpenedLockerNumsArr
            })
        });
    }
};