import React from "react"
// import Modal from "../../Modal"
import Modal from "react-modal"
import { OrangeButton } from "../../common"
import { LockerCells } from "../../reduxified"
import "./style.css"

const OpenedDoorsWarningModal = ({
  show,
  openedDoorsArr,
  onModalOkClick,
  onModalCloseClick,
  dirRtl,
}) => {
  const blinkers = openedDoorsArr
    ? openedDoorsArr.reduce((accu, num) => {
        accu[`${num}`] = num

        return accu
      }, {})
    : {}

  return (
    <Modal
      isOpen={show}
      onRequestClose={() => onModalCloseClick()}
      className="react-modal-content big-react-modal"
      overlayClassName="modal-overlay"
    >
      <div className="warndoor-titles-and-btn">
        <div className="warndoor-titles">
          <div className="warndoor-title">המערכת זיהתה דלתות פתוחות</div>
          <div className="warndoor-sub-title">
            בכדי להגדיל את פעולת המערכת, אנא סגור את התאים הפתוחים
          </div>
        </div>
      </div>
      <div className="warndoor-cells-wrapper">
        <LockerCells blinkers={blinkers} hideInfo />
      </div>
      <OrangeButton onClick={() => onModalCloseClick()}>
        סגור חלון{" "}
      </OrangeButton>
    </Modal>
  )
}

export default OpenedDoorsWarningModal
