import React, { Component } from "react"
import { connect } from "react-redux"
import {
  fetchAllLockersLayout,
  fetchAllLockersStatuses,
  fetchUserActiveOrders,
  getAllDynamicProducts,
  LogoutUser,
  logScreenEnterance,
  onCloseOrder,
  openAndListenToSpecificLockerLock,
  resetLockersErrorsAttemptsOpened,
  setRentalOrder,
  setThankYouSubTitle,
} from "../../../../actions"
import { MenuOrangeButton } from "../../../common"
import Loader from "../../../common/Loader"
import WarningInfoModal from "../../../Modals/WarningInfoModal"
import { FixedHeader } from "../../../reduxified"
import "./style.css"

class RentLockerMenuScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showOutOfLockersModal: false,
      showPayModal: false,
      showDoorOpenErrorModal: false,
      closedOrderSent: false,
      isMoreThanOneOrderWaitShow: false,
      openAttemptsNum: null,
    }
  }

  async componentDidMount() {
    this.props.logScreenEnterance("RENT_LOCKER_MENU_SCREEN")

    const { authenticated } = this.props
    if (!authenticated) {
      this.props.LogoutUser()
      this.props.history.push("")
    }
    const { userId, stationId, langName } = this.props

    await this.props.fetchUserActiveOrders(userId, stationId, { isRent: true })
  }

  openErrorModal() {
    this.setState({ showDoorOpenErrorModal: true })
  }

  onNewOrderClick() {
    const { isOutOfAvailableLockers } = this.props

    if (isOutOfAvailableLockers) {
      this.setState({ showOutOfLockersModal: true })
      return
    }
    this.props.history.push("RentLockerScreen")
  }

  closeOrder(order) {
    console.log("closeOrder >")
    console.log(order)
    const { closedOrderSent } = this.state
    if (closedOrderSent) {
      console.log("Close order allready was sent. Locker:" + order.lockerNum)
      return
    }
    console.log("Go to close order.")
    const { orderId, locationId: lockerId } = order
    this.props.onCloseOrder(orderId, lockerId)
    this.setState({ closedOrderSent: true })
  }

  onFinishRentClick(order) {
    this.props.setRentalOrder(order)
    this.props.history.push("CollectOrderScreen", order)
  }

  onPaymentSuccess(order) {
    let lockerNum = order.lockerNum
    this.setState({ showPayModal: false })

    if (!lockerNum) {
      lockerNum = 11111
    }

    this.openAndListenToLocker(lockerNum)
  }

  onPaymentNetworkFail(order) {
    let lockerNum = order.lockerNum
    this.setState({ showPayModal: false })

    if (!lockerNum) {
      lockerNum = 11111
    }

    this.openAndListenToLocker(lockerNum)
  }

  closePaymentModal() {
    this.setState(this.INIT_STATE)
  }

  openAndListenToLocker(lockerNum, attempts) {
    this.props.openAndListenToSpecificLockerLock(lockerNum, attempts)
  }

  onDoorOpenErrModalClose() {
    const { see_you_again } = this.props

    this.props.setThankYouSubTitle(see_you_again)
    this.props.history.push("ThankYouScreen")
  }

  closeOutOfLockersModal() {
    this.setState({ showOutOfLockersModal: false })
  }

  renderLoader() {
    const { isLoading } = this.props
    if (!isLoading) {
      return <></>
    }
    return <Loader />
  }

  render() {
    const {
      userActiveOrders,
      all_lockers_full_err_title,
      all_lockers_full_err_body,
      close_text,
      firstName,
      hello_title_text,
      user_menu_what_sub_title_text,
      finish_rent,
      new_rental,
      more_info,
      click_here,
      dirRtl,
      stationPaymentVersion,
      opening_locker_number,
      please_wait,
      err_while_opening_door,
      pls_contact_us_to_open_locker,
      isLoading,
    } = this.props
    const {
      showOutOfLockersModal,
      showPayModal,
      currOrder,
      showDoorOpenErrorModal,
      openAttemptsNum,
    } = this.state

    const stationPaymentVersion_OverCard1 = stationPaymentVersion === 1
    const stationPaymentVersion_InputCard2 = stationPaymentVersion === 2

    return (
      <div className="user-menu">
        <FixedHeader buttonsFlag="userMenuScreen" />
        <div
          className="ums-title"
          dir={dirRtl ? "rtl" : "ltr"}
        >{`${hello_title_text}, ${firstName}`}</div>
        <div className="ums-subtitle" dir={dirRtl ? "rtl" : "ltr"}>
          {user_menu_what_sub_title_text}
        </div>

        <div className="ums-wrapper">
          <MenuOrangeButton onClick={() => this.onNewOrderClick()}>
            {new_rental}
          </MenuOrangeButton>
          {this.renderLoader()}
          {!isLoading &&
            userActiveOrders.map((order) => {
              return (
                <MenuOrangeButton
                  onClick={() => this.onFinishRentClick(order)}
                  key={order.orderId}
                  textStyle="finish-rent-text"
                >{`${finish_rent} ${order.lockerNum}`}</MenuOrangeButton>
              )
            })}
        </div>
        <div className="more-info" dir={dirRtl ? "rtl" : "ltr"}>
          {more_info}{" "}
          <span
            className="click-here"
            onClick={() => this.props.history.push("/AboutRental")}
          >
            {click_here}
          </span>
        </div>
        <WarningInfoModal
          show={showOutOfLockersModal}
          title={all_lockers_full_err_title}
          text={all_lockers_full_err_body}
          onModalCloseClick={() => this.closeOutOfLockersModal()}
          btnText={close_text}
        />
      </div>
    )
  }
}

const mapStateToProps = ({
  userLogin,
  activeOrders,
  lockersCells,
  bgService,
  translate,
  stationServiceTypes,
}) => {
  const { stationId } = bgService
  const { userId, role, authenticated, firstName } = userLogin
  const { displayedOrder, userActiveOrders, isLoading } = activeOrders

  const {
    nextAllAvailableCells,
    openedLockersArr,
    errorLockersArr,
    openingAttempsMap,
    recentClosedLockersArr,
  } = lockersCells
  const {
    langName,
    dirRtl,
    user_menu_what_sub_title_text,
    user_menu_new_order_btn_text,
    close_text,
    user_menu_watch_orders_btn_text,
    user_menu_item_collection_btn_text,
    user_menu_product_purchase_btn_text,
    user_menu_driver_interface_btn_text,
    all_lockers_full_err_title,
    all_lockers_full_err_body,
    hello_title_text,
    finish_rent,
    new_rental,
    more_info,
    click_here,
    see_you_again,
    opening_locker_number,
    please_wait,
    err_while_opening_door,
    pls_contact_us_to_open_locker,
  } = translate
  const { stationPaymentVersion } = stationServiceTypes

  const hideCollectItemsBtn = !displayedOrder
  const hideDriverInterfaceBtn = role === "Customer" || role == null
  const isOutOfAvailableLockers =
    !nextAllAvailableCells || nextAllAvailableCells.length < 1

  return {
    userId,
    dirRtl,
    role,
    authenticated,
    displayedOrder,
    userActiveOrders,
    hideCollectItemsBtn,
    hideDriverInterfaceBtn,
    isOutOfAvailableLockers,
    stationId,
    firstName,
    langName,
    create_new_order: user_menu_new_order_btn_text,
    watch_order: user_menu_watch_orders_btn_text,
    collect_order: user_menu_item_collection_btn_text,
    purchase_products: user_menu_product_purchase_btn_text,
    driver_interface: user_menu_driver_interface_btn_text,
    all_lockers_full_err_title,
    close_text,
    all_lockers_full_err_body,
    hello_title_text,
    user_menu_what_sub_title_text,
    finish_rent,
    new_rental,
    more_info,
    click_here,
    stationPaymentVersion,
    see_you_again,
    openedLockersArr,
    errorLockersArr,
    openingAttempsMap,
    recentClosedLockersArr,
    opening_locker_number,
    please_wait,
    err_while_opening_door,
    pls_contact_us_to_open_locker,
    isLoading,
  }
}

export default connect(mapStateToProps, {
  fetchUserActiveOrders,
  fetchAllLockersStatuses,
  logScreenEnterance,
  fetchAllLockersLayout,
  getAllDynamicProducts,
  resetLockersErrorsAttemptsOpened,
  openAndListenToSpecificLockerLock,
  LogoutUser,
  onCloseOrder,
  setThankYouSubTitle,
  setRentalOrder,
})(RentLockerMenuScreen)
