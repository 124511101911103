import React, { Component } from "react"
import { connect } from "react-redux"

import { PaymentInputCard } from "../PaymentInputCard"
import { PaymentWaitCard } from "../PaymentWaitCard"
import { PaymentTakeCard } from "../PaymentTakeCard"
import SuccessFailBtn from "../../common/PendingSuccessFailBtn/SuccessFailBtn"
import { OrangeButton } from "../../common"
import "./style.css"
import axios from "axios"
import api from "../../../api/LocalServiceApi"

class PaymentInput2 extends Component {
  constructor(props) {
    super(props)

    //const {payment_card_reader_insert} = props;
    this.INIT_STATE = {
      changTimeOut: null,
      inputVal: "",
      status: this.props.payment_card_reader_insert,
      showInputCard: true,
      //showInputCard: false,
      showWaitCard: false,
      showTakeCard: false,
      //showTryPayCard: true,
      showTryPayCard: false,
    }
    this.stopRead = true
    //this.waitRead=false;
    this.state = this.INIT_STATE
    this.timeToLeave = 5
    // console.log("timeToLeave=" + this.timeToLeave)
  }

  readCardCall() {
    const { payment_card_reader_error } = this.props

    if (!this.stopRead && this.timeToLeave > 0)
      axios.get(api.readFromCard("read")).then((read) => {
        //READ CARD
        // console.log("read:", read)
        // console.log("read.data:", read.data)

        if (
          read === undefined ||
          read.data === undefined ||
          read.data === "error"
        ) {
          this.setState({ status: payment_card_reader_error })
          this.stopRead = false
          this.timeToLeave -= 1
          // console.log("timeToLeave=" + this.timeToLeave)
          this.startReadFromCard()
          this.resetSelfStateIn(2)
        } else {
          this.onInputChange(read.data)
          this.timeToLeave = 5
          // console.log("timeToLeave=" + this.timeToLeave)
          this.stopRead = true
          //RESET CARD
          axios.get(api.readFromCard("reset")).then((reset) => {
            // console.log("reset:", reset)
          })
        }
      })
  }
  startReadFromCard() {
    if (this.timeToLeave <= 0) {
      //Pay with fake after timeToLeave
      this.onInputChange("4580000000000000=data")
      this.stopRead = true
      this.timeToLeave = 5
      // console.log("timeToLeave=" + this.timeToLeave)
    }
    // else if (!this.stopRead && this.timeToLeave > 0)
    // console.log("timeToLeave=" + this.timeToLeave)
    const {
      payment_card_reader_insert,
      payment_card_reader_remove,
      payment_card_reader_error,
    } = this.props

    this.setState({
      status: payment_card_reader_insert,
      showWaitCard: false,
      showTakeCard: false,
      showInputCard: true,
    })

    //INSERT CARD
    axios.get(api.readFromCard("insert")).then((res_insert) => {
      // console.log("insert", res_insert)
      //call API to remove card
      if (
        res_insert !== undefined &&
        res_insert.data !== undefined &&
        res_insert.data !== "error"
      ) {
        if (!this.stopRead && this.timeToLeave > 0)
          // todo if time to leave  =0?
          //REMOVE CARD
          axios.get(api.readFromCard("remove")).then((res_remove) => {
            // console.log("remove", res_remove)
            if (
              res_remove !== undefined &&
              res_remove.data !== undefined &&
              res_remove.data !== "error"
            ) {
              //set remove card //  status: 'הוצא כרטיס'
              this.setState({
                status: payment_card_reader_remove,
                showWaitCard: false,
                showTakeCard: true,
                showInputCard: false,
              })

              //call API to read number and put it on 'inputVal'
              this.readCardCall()
            } else {
              this.timeToLeave -= 1
              // console.log("timeToLeave=" + this.timeToLeave)
              this.setState({ status: payment_card_reader_error })
              this.stopRead = false
              this.startReadFromCard()
              this.resetSelfStateIn(3)
            }
          })
      } else {
        this.timeToLeave -= 1
        // console.log("timeToLeave=" + this.timeToLeave)
        this.setState({ status: payment_card_reader_error })
        this.stopRead = false
        this.startReadFromCard()
        this.resetSelfStateIn(3)
      }
    })
  }

  stopReadCard() {
    // console.log("stopReadCard")
    const { changTimeOut } = this.state
    this.stopRead = true
    clearTimeout(changTimeOut)
    //RESET CARD
    axios.get(api.readFromCard("reset")).then((reset) => {
      // console.log("reset:" + reset)
    })
  }

  componentDidMount() {
    this.catchFocus()
    //call API to insert card
    this.stopRead = false
    this.startReadFromCard()
  }

  componentWillUnmount() {
    this.stopReadCard()
    this.state = this.INIT_STATE
  }

  componentWillReceiveProps(newProps) {
    const { refreshComponent } = newProps

    if (refreshComponent) {
      this.timeToLeave = 5
      // console.log("timeToLeave=" + this.timeToLeave)
      this.catchFocus()
    }
  }

  catchFocus() {
    const { paymentInput } = this.refs
    paymentInput.focus()
  }

  onInputChange(val) {
    const { payment_card_reader_wait } = this.props
    //console.log('val=', val);
    this.setState({
      status: payment_card_reader_wait,
      showWaitCard: true,
      showTakeCard: false,
      showInputCard: false,
    })
    if (!isNaN(val)) {
      val = `${val}`
    }
    const { inputVal, changTimeOut } = this.state

    if (inputVal.length < val.length) {
      if (changTimeOut) {
        clearTimeout(changTimeOut)
      }

      const timeout = setTimeout(() => this.onInputStreadEnded(), 700)
      this.setState({ inputVal: val, changTimeOut: timeout })
    }
  }

  onInputStreadEnded() {
    let { inputVal } = this.state
    const { payment_card_reader_error } = this.props

    try {
      if (inputVal.includes(";")) {
        inputVal = inputVal.split(";")[1]
      }

      inputVal = inputVal.split("=")
      // const cCard = inputVal[0]
      // const mmyy = inputVal[1]

      let cCard
      let mmyy

      if (inputVal[0].length === 16 || inputVal[0].length === 15) {
        cCard = inputVal[0]
        mmyy = `${inputVal[1].substr(2, 2)}${inputVal[1].substr(0, 2)}`
      } else {
        // THIS IS NOT A REGULAR CREDIT CARD - 9 DIGITS - OMRI'S CODE :)
        cCard = inputVal[1].substr(0, 9)
        mmyy = `${inputVal[1].substr(16, 2)}${inputVal[1].substr(14, 2)}`
      }

      // console.log("lll", cCard, mmyy)

      this.props.onPaymentDataReady(cCard, mmyy)
      this.resetSelfStateIn(3)
    } catch (e) {
      // BUG FIX - partial card data reading..
      // invalid card
      //SHOW TRY PAY AGAIN BUTTONS
      this.setState({
        inputVal: "",
        status: payment_card_reader_error,
        showWaitCard: false,
        showTakeCard: false,
        showInputCard: false,
        showTryPayCard: true,
      })
      // console.log("onInputStreadEnded exeption->" + e)
      //this.stopRead=false
      //this.timeToLeave=2
      //console.log('timeToLeave='+this.timeToLeave);
      //this.startReadFromCard();
      //this.resetSelfStateIn(3);
      return
    }
  }

  tryPayCard() {
    // console.log("tryPayCard")
    this.stopRead = false
    this.timeToLeave = 2
    // console.log("timeToLeave=" + this.timeToLeave)
    this.setState({
      showWaitCard: false,
      showTakeCard: false,
      showInputCard: false,
      showTryPayCard: false,
    })

    this.resetSelfStateIn(1)
    this.startReadFromCard()
  }

  resetSelfStateIn(secs) {
    setTimeout(() => this.setState({ ...this.INIT_STATE }), secs * 1000)
  }

  renderTryAgainBtn(show) {
    if (!show) {
      return <></>
    }
    const { payment_swipe_a_card } = this.props
    return (
      <>
        <SuccessFailBtn success={false} />
        <OrangeButton
          onClick={() => this.tryPayCard()}
          extraClass="paymdl2-enter-carddate-manually-btn"
        >
          {" "}
          {payment_swipe_a_card}{" "}
        </OrangeButton>
      </>
    )
  }

  render() {
    const { extraClass } = this.props
    const {
      inputVal,
      showInputCard,
      showWaitCard,
      showTakeCard,
      showTryPayCard,
    } = this.state

    return (
      <div className={"payment2-wrapper " + extraClass}>
        {!this.props.justCheck && (
          <div className="whlb-wrapper  paymdl2-title">{this.state.status}</div>
        )}
        <PaymentInputCard show={showInputCard} />
        <PaymentWaitCard show={showWaitCard} />
        <PaymentTakeCard show={showTakeCard} />
        {this.renderTryAgainBtn(showTryPayCard)}
        <form>
          <input
            type="password"
            value={inputVal}
            className="payment-input"
            onChange={(e) => this.onInputChange(e.target.value)}
            onBlur={() => this.catchFocus()}
            ref="paymentInput"
          />
        </form>
      </div>
    )
  }
}

const mapStateToProps = ({ userLogin, translate }) => {
  const { authenticated } = userLogin
  //const {loadingRequest, paymentFailed, paymentSuccess, } = paymentModal;

  const {
    payment_card_reader_insert,
    payment_card_reader_remove,
    payment_card_reader_wait,
    payment_card_reader_error,
    payment_swipe_a_card,
  } = translate

  return {
    authenticated,
    payment_card_reader_insert,
    payment_card_reader_remove,
    payment_card_reader_wait,
    payment_card_reader_error,
    payment_swipe_a_card,
  }
}

export default connect(mapStateToProps)(PaymentInput2)
