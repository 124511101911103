import React, { Component } from "react"
import Modal from "react-modal"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { LogoutUser, recreateUserPassword } from "../../../actions"
import { validation } from "../../../utils"
import PricesTableByType from "../../Modals/PricesListModal/PricesTableByType"
import WarningInfoModal from "../../Modals/WarningInfoModal"
import YesNoInfoModal from "../../Modals/YesNoInfoModal"
import WhatsApp from "../../../assets/svg/whatsapp.svg"
import SupportPhone from "../../../assets/svg/support_phone.svg"
import "./style.css"

class FixedHeader extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showYesNoModal: false,
      showPricesList: false,
      showRecreatePasswordModal: false,
    }
  }

  headerButtons = (buttonsFlag) => {
    const {
      price_list,
      login_courier,
      sms_login_didnot_get_sms,
      forgot_password_btn_text,
      register_btn_text,
      mobile,
      logout_btn_text,
    } = this.props
    if (buttonsFlag === "showRestoreDeliveryCode") {
      return (
        <div
          className="header-button"
          onClick={() => this.props.history.push("/RestoreDeliveryCodeScreen")}
        >
          {sms_login_didnot_get_sms}
        </div>
      )
    }
    if (buttonsFlag === "loginScreen") {
      return (
        <div
          className="header-button"
          onClick={() => this.onSendMobileToRestorePasswordClick(mobile)}
        >
          {forgot_password_btn_text}
        </div>
      )
    }
    if (buttonsFlag === "userLoginScreen") {
      return (
        <>
          <div
            className="header-button"
            onClick={() =>
              this.setState({
                showPricesList: true,
              })
            }
          >
            {price_list}
          </div>
          <div
            className="header-button"
            onClick={() => this.onSendMobileToRestorePasswordClick(mobile)}
          >
            {forgot_password_btn_text}
          </div>
          <div
            className="header-button"
            onClick={() => this.props.history.push("/SignUpStep1Screen")}
          >
            {register_btn_text}
          </div>
        </>
      )
    }
    if (
      buttonsFlag === "passwordUpdateScreen" ||
      buttonsFlag === "signUpStep1Screen" ||
      buttonsFlag === "aboutRental"
    ) {
      return null
    }
    if (
      buttonsFlag === "loadCleanOrdersScreen" ||
      buttonsFlag === "driverMenuScreen" ||
      buttonsFlag === "collectOrdersScreen" ||
      buttonsFlag === "unlockLockersScreen"
    ) {
      return (
        <div
          className="header-button"
          onClick={() =>
            this.setState({
              showYesNoModal: true,
            })
          }
        >
          {logout_btn_text}
        </div>
      )
    }
    if (
      buttonsFlag === "userMenuScreen" ||
      buttonsFlag === "newOrderScreen" ||
      buttonsFlag === "collectOrderScreen" ||
      buttonsFlag === "newLockerToLockerScreen"
    ) {
      return (
        <>
          <div
            className="header-button"
            onClick={() =>
              this.setState({
                showPricesList: true,
              })
            }
          >
            {price_list}
          </div>
          <div
            className="header-button"
            onClick={() =>
              this.setState({
                showYesNoModal: true,
              })
            }
          >
            {logout_btn_text}
          </div>
        </>
      )
    }

    return (
      <>
        <div
          className="header-button"
          onClick={() =>
            this.setState({
              showPricesList: true,
            })
          }
        >
          {price_list}
        </div>
        <div
          className="header-button"
          onClick={() => this.props.history.push("/LoginScreen")}
        >
          {login_courier}
        </div>
        <div
          className="header-button"
          onClick={() => this.props.history.push("/SignUpStep1Screen")}
        >
          {register_btn_text}
        </div>
      </>
    )
  }

  onSendMobileToRestorePasswordClick(mobile) {
    if (!validation.Mobile05(mobile)) {
      this.props.history.push("/RecreatePasswordScreen")
      return
    }

    this.setState({ showRecreatePasswordModal: true })
    this.props.recreateUserPassword(mobile)
  }

  setName = (firstName) => {
    if (firstName != null) return firstName
    return ""
  }
  newlineText(text) {
    if (text === undefined || text === null || text.length <= 0) {
      return text
    }
    return text.split("\n").map((str) => <p>{str}</p>)
  }
  render() {
    const {
      title,
      subTitle,
      firstName,
      hello_title_text,
      dirRtl,
      customer_service_text,
      customer_service_phone_text,
      customer_service_whatsapp_text,
      pricesArrs,
      asterisk,
      close_text,
      price_list,
      login_courier,
      showRestoreDeliveryCode,
      sms_login_didnot_get_sms,
      forgot_password_btn_text,
      buttonsFlag,
      reset_pwd_modal_title,
      reset_pwd_modal_body,
      logout_modal_title,
      logout_modal_text,
      ok_text,
      cancel_text,
    } = this.props
    const { showRecreatePasswordModal, showYesNoModal } = this.state

    const { showPricesList } = this.state
    const welcomeTitle = `${hello_title_text} ${this.setName(firstName)}`
    const displayedTitle = title || welcomeTitle

    const fhStyle = dirRtl ? { direction: "rtl" } : undefined

    const title_with_lines = this.newlineText(displayedTitle)
    const subTitle_with_lines = this.newlineText(subTitle)

    return (
      <div className="fh-header">
        <div className="header-buttons">{this.headerButtons(buttonsFlag)}</div>

        <div className="fh-header-itm">
          <div className="customer-service" dir={`${dirRtl ? "rtl" : "ltr"}`}>
            <div>{`${customer_service_text}`} </div>
            <div className="space">&nbsp;</div>
            <div className="support-phone">
              <img src={SupportPhone} alt="support phone" />
            </div>
            <div>{`${customer_service_phone_text}`}</div>

            <div>{`${dirRtl ? asterisk : ""}`}</div>
            <div className="space">&nbsp;</div>
            <div className="icon-app">
              <img src={WhatsApp} alt="whatsapp" />
            </div>
            <div>{`${customer_service_whatsapp_text}`}</div>
          </div>
        </div>

        <Modal
          isOpen={showPricesList}
          onRequestClose={() => this.setState({ showPricesList: false })}
          className="react-modal-content tall-modal"
          overlayClassName="modal-overlay"
        >
          <div className="pricelist-title">{`₪ ${price_list}`}</div>

          <div className={`${dirRtl ? "dirrtl" : "dirltr"} price-modal-body`}>
            {pricesArrs.map((pricesArr, index) => {
              if (pricesArr.length < 1) {
                return ""
              }
              const { productType } = pricesArr[0]
              return (
                <PricesTableByType
                  itemsArr={pricesArr}
                  tableTitle={productType}
                  key={index}
                />
              )
            })}
          </div>
          <button
            className="close-btn"
            onClick={() => {
              this.setState({ showPricesList: false })
            }}
          >
            {close_text}
          </button>
        </Modal>
        <WarningInfoModal
          dirRtl={dirRtl}
          show={showRecreatePasswordModal}
          title={reset_pwd_modal_title}
          text={reset_pwd_modal_body}
          onModalCloseClick={() =>
            this.setState({
              showRecreatePasswordModal: false,
            })
          }
          btnText={close_text}
        />
        <YesNoInfoModal
          dirRtl={dirRtl}
          show={showYesNoModal}
          title={logout_modal_title}
          text={logout_modal_text}
          onModalOkClick={() => {
            this.props.LogoutUser()
            this.props.history.push("")
          }}
          onModalCancelClick={() => this.setState({ showYesNoModal: false })}
          okTransl={ok_text}
          cancelTransl={cancel_text}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userLogin, prices, translate }) => {
  let { firstName } = userLogin
  const { pricesArrs } = prices

  const {
    dirRtl,
    asterisk,
    customer_service_text,
    customer_service_phone_text,
    customer_service_whatsapp_text,
    hello_title_text,
    close_text,
    price_list,
    login_courier,
    sms_login_didnot_get_sms,
    forgot_password_btn_text,
    reset_pwd_modal_title,
    reset_pwd_modal_body,
    register_btn_text,
    logout_btn_text,
    logout_modal_title,
    logout_modal_text,
    ok_text,
    cancel_text,
  } = translate

  return {
    firstName,
    hello_title_text,
    dirRtl,
    customer_service_text,
    customer_service_phone_text,
    customer_service_whatsapp_text,
    asterisk,
    pricesArrs,
    close_text,
    price_list,
    login_courier,
    sms_login_didnot_get_sms,
    forgot_password_btn_text,
    reset_pwd_modal_title,
    reset_pwd_modal_body,
    register_btn_text,
    logout_btn_text,
    logout_modal_title,
    logout_modal_text,
    ok_text,
    cancel_text,
  }
}

export default connect(mapStateToProps, {
  LogoutUser,
  // fetchPriceListByStationId,
  recreateUserPassword,
})(withRouter(FixedHeader))
