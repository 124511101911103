import axios from "axios"
import React, { useEffect, useState } from "react"
import Modal from "react-modal"
import { connect } from "react-redux"
import PaymentInput from "../../common/PaymentInput"
import PaymentInput2 from "../../common/PaymentInput2"
import api from "../../../api/LocalServiceApi"
import "./style.css"

const CardCheckModal = ({
  show,
  stationPaymentVersion,
  checkCreditCard,
  onCloseModal,
}) => {
  return (
    <>
      <Modal
        isOpen={show}
        className="react-modal-content medium-react-modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => onCloseModal()}
      >
        <button className="check-card-close-btn" onClick={() => onCloseModal()}>
          X
        </button>
        <div className="check-card-title">
          אנא העבירו כרטיס אשראי לתחילת ההשכרה
        </div>
        <div className="card-input-wrapper">
          {stationPaymentVersion === 1 ? (
            <PaymentInput
              onPaymentDataReady={checkCreditCard}
              justCheck={true}
            />
          ) : (
            <PaymentInput2
              onPaymentDataReady={checkCreditCard}
              justCheck={true}
            />
          )}
        </div>
        <div className="check-card-text" dir="rtl">
          כדי למנוע זיופים ותפיסת תאים, אנו מבקשים הזדהות באמצעות כרטיסי אשראי.
          הכרטיס לא יחוייב. התשלום נעשה בסיום ההשכרה.
        </div>
      </Modal>
    </>
  )
}

const mapStateToProps = ({ stationServiceTypes, translate }) => {
  const { stationPaymentVersion } = stationServiceTypes

  const {
    driver_menu_lockers_cells_opening,
    driver_lockers_cells_opening_subtitle,
  } = translate

  return {
    stationPaymentVersion,
    driver_menu_lockers_cells_opening,
    driver_lockers_cells_opening_subtitle,
  }
}

export default connect(mapStateToProps)(CardCheckModal)
