import React, { Component } from "react"
import { connect } from "react-redux"

import {
  setLockerToLokcerDeliveryRequest,
  fetchAllLockersStatuses,
  logScreenEnterance,
  fetchAllLockersLayout,
  getAllDynamicProducts,
  resetLockersErrorsAttemptsOpened,
  LogoutUser,
  openAndListenToSpecificLockerLock,
  setThankYouSubTitle,
  setRentalOrder,
  getOrdersRequests,
} from "../../../../../actions"
import WarningInfoModal from "../../../../Modals/WarningInfoModal"
import LoadingInfoModal from "../../../../Modals/LoadingInfoModal"
import ErrorInfoModal from "../../../../Modals/ErrorInfoModal"

import { FixedHeader } from "../../../../reduxified"
import { MenuOrangeButton } from "../../../../common"
import Loader from "../../../../common/Loader"
import "./style.css"

class LockerToLockerDeliveryMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showOutOfLockersModal: false,
      showPayModal: false,
      showDoorOpenErrorModal: false,
      closedOrderSent: false,
      isMoreThanOneOrderWaitShow: false,
      // currOrder: null,
      openAttemptsNum: null,
    }
  }

  async componentDidMount() {
    this.props.logScreenEnterance("USER_LOCKER_DELIVERY_MENU_SCREEN")

    const { authenticated } = this.props
    if (!authenticated) {
      this.props.LogoutUser()
      this.props.history.push("")
    }
    const { userId, stationId } = this.props
    this.props.getOrdersRequests(stationId, userId, 5)
  }

  componentWillUpdate(newProps, nextState) {
    const {
      authenticated,
      isLoading,
      ordersRequestsList,
      isNoAvailableLockersForService,
    } = newProps

    if (!authenticated) {
      this.props.LogoutUser()
      this.props.history.push("")
    }
    if (isNoAvailableLockersForService) {
      this.setState({ showOutOfLockersModal: true })
      return
    }
    if (!isLoading && ordersRequestsList && ordersRequestsList.length === 0) {
      this.props.history.push("/LockerToLockerDeliveryScreen/SelectStation")
    }
  }

  openErrorModal() {
    this.setState({ showDoorOpenErrorModal: true })
  }

  onNewOrderClick() {
    const { isNoAvailableLockersForService } = this.props

    if (isNoAvailableLockersForService) {
      this.setState({ showOutOfLockersModal: true })
      return
    }
    //this.props.history.push("SelectStation")
    this.props.history.push("/LockerToLockerDeliveryScreen/SelectStation")
  }

  onContinueRequestClick(request) {
    this.props.setLockerToLokcerDeliveryRequest(request)
    this.props.history.push("/LockerToLockerDeliveryScreen/PayOrder")
  }

  closeOutOfLockersModal() {
    this.setState({ showOutOfLockersModal: false })
  }

  renderLoader() {
    const { isLoading } = this.props
    if (!isLoading) {
      return <></>
    }
    return <Loader />
  }

  render() {
    const {
      userActiveOrders,
      all_lockers_full_err_title,
      all_lockers_full_err_body,
      close_text,
      firstName,
      hello_title_text,
      user_menu_what_sub_title_text,
      finish_rent,
      new_rental,
      more_info,
      click_here,
      dirRtl,
      stationPaymentVersion,
      opening_locker_number,
      please_wait,
      err_while_opening_door,
      pls_contact_us_to_open_locker,
      new_locker_to_locker_delivery,
      ordersRequestsList,
      parcel,
      to,
      isLoading,
    } = this.props
    const {
      showOutOfLockersModal,
      showPayModal,
      currOrder,
      showDoorOpenErrorModal,
      openAttemptsNum,
    } = this.state

    return (
      <div className="user-menu locker-to-locker-delivery">
        <FixedHeader buttonsFlag="userMenuScreen" />
        <div
          className="ums-title"
          dir={dirRtl ? "rtl" : "ltr"}
        >{`${hello_title_text}, ${firstName}`}</div>
        <div className="ums-subtitle" dir={dirRtl ? "rtl" : "ltr"}>
          {user_menu_what_sub_title_text}
        </div>

        <div className="ums-wrapper">
          <MenuOrangeButton onClick={() => this.onNewOrderClick()}>
            {new_locker_to_locker_delivery}
          </MenuOrangeButton>

          <div className="requests-list">
            {this.renderLoader()}
            {!isLoading &&
              ordersRequestsList.map((request) => {
                return (
                  <MenuOrangeButton
                    dir={dirRtl ? "rtl" : "ltr"}
                    onClick={() => this.onContinueRequestClick(request)}
                    key={request.orderId}
                    textStyle="finish-rent-text"
                  >{`${parcel} ${request.orderNumber} ${to} ${request.firstName} ${request.lastName}`}</MenuOrangeButton>
                )
              })}
          </div>
        </div>

        <WarningInfoModal
          show={showOutOfLockersModal}
          title={all_lockers_full_err_title}
          text={all_lockers_full_err_body}
          onModalCloseClick={() => this.closeOutOfLockersModal()}
          btnText={close_text}
        />
      </div>
    )
  }
}

const mapStateToProps = ({
  userLogin,
  activeOrders,
  lockersCells,
  bgService,
  translate,
  stationServiceTypes,
  lockerToLockerDelivery,
}) => {
  const { stationId } = bgService
  const { userId, role, authenticated, firstName } = userLogin
  const { displayedOrder, userActiveOrders } = activeOrders
  const {
    nextAllAvailableCells,
    openedLockersArr,
    errorLockersArr,
    openingAttempsMap,
    recentClosedLockersArr,
  } = lockersCells
  const {
    langName,
    dirRtl,
    user_menu_what_sub_title_text,
    user_menu_new_order_btn_text,
    close_text,
    user_menu_watch_orders_btn_text,
    user_menu_item_collection_btn_text,
    user_menu_product_purchase_btn_text,
    user_menu_driver_interface_btn_text,
    all_lockers_full_err_title,
    all_lockers_full_err_body,
    hello_title_text,
    finish_rent,
    new_rental,
    more_info,
    click_here,
    see_you_again,
    opening_locker_number,
    please_wait,
    err_while_opening_door,
    pls_contact_us_to_open_locker,
    new_locker_to_locker_delivery,
    parcel,
    to,
  } = translate
  const { stationPaymentVersion } = stationServiceTypes

  const hideCollectItemsBtn = !displayedOrder
  const hideDriverInterfaceBtn = role === "Customer" || role == null
  const isOutOfAvailableLockers =
    !nextAllAvailableCells || nextAllAvailableCells.length < 1

  const { isNoAvailableLockersForService, isLoading, ordersRequestsList } =
    lockerToLockerDelivery

  return {
    userId,
    dirRtl,
    role,
    authenticated,
    displayedOrder,
    userActiveOrders,
    hideCollectItemsBtn,
    hideDriverInterfaceBtn,
    isOutOfAvailableLockers,
    stationId,
    firstName,

    isNoAvailableLockersForService,
    isLoading,
    ordersRequestsList,

    langName,
    create_new_order: user_menu_new_order_btn_text,
    watch_order: user_menu_watch_orders_btn_text,
    collect_order: user_menu_item_collection_btn_text,
    purchase_products: user_menu_product_purchase_btn_text,
    driver_interface: user_menu_driver_interface_btn_text,
    all_lockers_full_err_title,
    close_text,
    all_lockers_full_err_body,
    hello_title_text,
    user_menu_what_sub_title_text,
    finish_rent,
    new_rental,
    more_info,
    click_here,
    stationPaymentVersion,
    see_you_again,
    openedLockersArr,
    errorLockersArr,
    openingAttempsMap,
    recentClosedLockersArr,
    opening_locker_number,
    please_wait,
    err_while_opening_door,
    pls_contact_us_to_open_locker,
    new_locker_to_locker_delivery,
    parcel,
    to,
  }
}

export default connect(mapStateToProps, {
  setLockerToLokcerDeliveryRequest,
  fetchAllLockersStatuses,
  logScreenEnterance,
  fetchAllLockersLayout,
  getAllDynamicProducts,
  resetLockersErrorsAttemptsOpened,
  openAndListenToSpecificLockerLock,
  LogoutUser,
  setThankYouSubTitle,
  setRentalOrder,
  getOrdersRequests,
})(LockerToLockerDeliveryMenu)
