import React, { useState } from "react"

import "./style.css"

const LangSupportPanel = ({ onLangChange, langName }) => {
  return (
    <div className="lsp-wrapper">
      <div
        className={`lng-button ${langName === "he" ? "active-lng" : ""}`}
        onClick={() => {
          onLangChange("he")
        }}
      >
        <p>HEB</p>
      </div>
      <p className="divider">&nbsp;/&nbsp;</p>
      <div
        className={`lng-button ${langName === "en" ? "active-lng" : ""}`}
        onClick={() => {
          onLangChange("en")
        }}
      >
        <p>ENG</p>
      </div>
      <p className="divider">&nbsp;/&nbsp;</p>
      <div
        className={`lng-button ${langName === "ru" ? "active-lng" : ""}`}
        onClick={() => {
          onLangChange("ru")
        }}
      >
        <p>RUS</p>
      </div>
    </div>
  )
}

export default LangSupportPanel
