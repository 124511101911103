let validate = {};

//Login
validate.Mobile = (mobile)=>{

    if (!mobile || mobile.length === 0) {
        return false;
    }

    return mobile.length === 10 && !isNaN(mobile);
}

validate.Pwd = (value) =>{
    if (!value || value.length === 0) {
        return false;
    }
    return value.length > 3;
}


validate.PwdStrong = (value) =>{
    if (!value || value.length < 5) {
        return false;
    }
    
    const isPasswordStrongRegExp = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{5,}$/; 
    const result = isPasswordStrongRegExp.test(value);
    console.log('isPasswordStrong='+result);
    return result;
}

validate.PwdVerify = (value, valueVerify) =>{
    if (!valueVerify || valueVerify.length === 0) {
        return false;
    }
    return value === valueVerify;
}

validate.Name = (value) =>{
    if (!value || value.length < 2) {
        return false;
    }
    
    const isNameRegExp =  /^[A-Za-zא-תА-Яа-я-" ']{2,50}$/; 
    const result = isNameRegExp.test(value);
    console.log('isNameRegExp='+result);
    return result;
}

//Registration
validate.Mobile05 =(mobile) => {
    if (!mobile || mobile.length === 0) {
        return false;
    }
    return mobile.length === 10 && mobile[0] + mobile[1] === '05';
}



validate.Email = (email) => {
    if (!email || email.length <3 ) {
        return false;
    }

    const emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailReg.test(email);
}

validate.AddresseeDetails = (addresseeDetails) =>{
    if(!addresseeDetails || !addresseeDetails.phone || !addresseeDetails.firstName || !addresseeDetails.lastName){
        return false;
    }
    return validate.Mobile05(addresseeDetails.phone) && validate.Name(addresseeDetails.firstName) && validate.Name(addresseeDetails.lastName);

}
validate.BarcodeGuid = (barcode) => {
    
    if (!barcode || barcode.length <36 ) {
        return false;
    }
    const guidRegex = /^([0-z]{8})-([0-z]{4})-([0-z]{4})-([0-z]{4})-([0-z]{12}$)/;
    const res = guidRegex.test(barcode);
    return res;
}


export const validation = validate;