import axios from "axios"
import api from "../api"

import { axiosGetRetry, axiosPutRetry, axiosPostRetry } from "../utils"

import {
  FETCH_AVAILABLE_LOCKERS_STATUSES_SUCCESS,
  FETCH_AVAILABLE_LOCKERS_STATUSES_FAIL,
  SET_LOCKER_TO_LOCKER_DELIVERY_ORDER,
  FETCH_LOCKER_TO_LOCKER_DELIVERY_STATION_SUCCESS,
  FETCH_LOCKER_TO_LOCKER_DELIVERY_STATION_FAIL,
  ON_CREATE_LOCKER_TO_LOCKER_DELIVERY_ORDER_SUCCESS,
  ON_CREATE_LOCKER_TO_LOCKER_DELIVERY_ORDER_FAIL,
  ON_LOCKER_TO_LOCKER_DELIVERY_LOADING,
  ON_GET_ORDER_FOR_PAY_SUCCESS,
  ON_GET_ORDER_FOR_PAY_FAIL,
  ON_CONFIRM_CREATE_ORDER_SUCCESS,
  ON_CONFIRM_CREATE_ORDER_FAIL,
  SET_LOCKER_TO_LOCKER_DELIVERY_PAYED_ORDER,
  ON_GET_LOCKER_TO_LOCKER_DELIVERY_ORDERS_REQUESTS_SUCCESS,
  ON_GET_ORDERS_REQUESTS_SUCCESS,
  ON_GET_ORDERS_REQUESTS_FAIL,
  ON_CLEAR_ORDER_FOR_CONFIRM_CREATE,
} from "./types"

import { reportFBEvent } from "./FBEventsActions"

export const createLockerToLokcerDeliveryNewOrder = (data) => {
  return (dispatch) => {
    axiosPostRetry(api.createNewOrder, data)
      .then((res) => {
        dispatch({
          type: ON_CREATE_LOCKER_TO_LOCKER_DELIVERY_ORDER_SUCCESS,
          payload: res.data,
        })
        dispatch(
          reportFBEvent(
            "New_Order",
            "New Order lockerToLocker Has Been Created! Without location and not payed. "
          )
        )
      })
      .catch((err) => {
        dispatch({
          type: ON_CREATE_LOCKER_TO_LOCKER_DELIVERY_ORDER_FAIL,
          payload: err,
        })
        console.log(err)
      })
  }
}

export const setLockerToLokcerDeliveryRequest = (data) => {
  return (dispatch) => {
    dispatch({
      type: ON_CREATE_LOCKER_TO_LOCKER_DELIVERY_ORDER_SUCCESS,
      payload: data,
    })
    dispatch(
      reportFBEvent(
        "Continue_Order_Request",
        "Set LockerToLokcerDelivery request to continue order."
      )
    )
  }
}

export const fetchAvailableLockersStatus = (stationId) => {
  return (dispatch) => {
    axiosGetRetry(api.getAvailableLockersStatus(stationId))
      .then((res) => {
        dispatch({
          type: FETCH_AVAILABLE_LOCKERS_STATUSES_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: FETCH_AVAILABLE_LOCKERS_STATUSES_FAIL,
          payload: err,
        })
      })
  }
}

export const fetchStationsList = (lang) => {
  return (dispatch) => {
    axiosGetRetry(api.getStationsList(lang))
      .then((res) => {
        dispatch({
          type: FETCH_LOCKER_TO_LOCKER_DELIVERY_STATION_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: FETCH_LOCKER_TO_LOCKER_DELIVERY_STATION_FAIL,
          payload: err,
        })
      })
  }
}

export const setLockerToLockerDeliveryOrder = (order) => {
  return (dispatch) => {
    dispatch({
      type: SET_LOCKER_TO_LOCKER_DELIVERY_ORDER,
      payload: order,
    })
  }
}

export const setLockerToLockerDeliveryOrderLoading = () => {
  return (dispatch) => {
    dispatch({
      type: ON_LOCKER_TO_LOCKER_DELIVERY_LOADING,
    })
  }
}

export const setLockerToLockerDeliveryPayedOrder = (order) => {
  return (dispatch) => {
    dispatch({
      type: SET_LOCKER_TO_LOCKER_DELIVERY_PAYED_ORDER,
      payload: order,
    })
  }
}

export const getOrderForPay = (orderId) => {
  return (dispatch) => {
    axiosGetRetry(api.getOrderById(orderId))
      .then((res) => {
        dispatch({
          type: ON_GET_ORDER_FOR_PAY_SUCCESS,
          payload: res.data,
        })
        dispatch(
          reportFBEvent(
            "OrderForPay",
            "Getted LockerToLockerDelivery order for pay flow."
          )
        )
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_ORDER_FOR_PAY_FAIL,
          payload: err,
        })
        console.log(err)
      })
  }
}

export const confirmCreateOrder = (order) => {
  return (dispatch) => {
    axiosPutRetry(api.confirmCreateOrder(order.orderId), order)
      .then((res) => {
        dispatch({
          type: ON_CONFIRM_CREATE_ORDER_SUCCESS,
          payload: res.data,
        })
        dispatch(reportFBEvent("ConfirmCreateOrder", "Confirm create order."))
      })
      .catch((err) => {
        dispatch({
          type: ON_CONFIRM_CREATE_ORDER_FAIL,
          payload: err,
        })
        console.log(err)
      })
  }
}

export const getOrdersRequests = (stationId, userId, orderType) => {
  return (dispatch) => {
    dispatch({
      type: ON_LOCKER_TO_LOCKER_DELIVERY_LOADING,
    })
    axiosGetRetry(api.getOrdersRequests(stationId, userId, orderType))
      .then((res) => {
        if (orderType === 5) {
          dispatch({
            type: ON_GET_LOCKER_TO_LOCKER_DELIVERY_ORDERS_REQUESTS_SUCCESS,
            payload: res.data,
          })
        } else {
          dispatch({
            type: ON_GET_ORDERS_REQUESTS_SUCCESS,
            payload: res.data,
          })
        }

        dispatch(
          reportFBEvent(
            "getOrdersRequests",
            "Get  orders requests typeOrder:" + orderType + "."
          )
        )
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_ORDERS_REQUESTS_FAIL,
          payload: err,
        })
        console.log(err)
      })
  }
}

export const clearOrderForConfirmCreate = () => {
  return (dispatch) => {
    dispatch({
      type: ON_CLEAR_ORDER_FOR_CONFIRM_CREATE,
    })
  }
}
