import React, { Component } from "react"
import { connect } from "react-redux"
import { logScreenEnterance } from "../../../../actions"
import { OrangeButton } from "../../../common"
import { FixedFooter, FixedHeader } from "../../../reduxified"
import { OrderDetailsList } from "./OrderDetailsList"
import "./style.css"

class ReviewOrderList extends Component {
  componentDidMount() {
    this.props.logScreenEnterance("USER_REVIEW_ORDERS_LIST_SCREEN")
  }

  onCancelClick() {
    this.props.history.push("UserMenuScreen")
  }

  render() {
    const {
      orderItems,
      back_btn_text,
      order_details,
      order_item,
      order_item_count,
    } = this.props

    return (
      <div className="ls-wrapper">
        <FixedHeader subTitle={order_details} />
        <OrderDetailsList
          HeaderItems={[order_item, order_item_count]}
          Items={orderItems}
        />
        <div className="rol-frame-btns">
          <OrangeButton
            extraClass="rol-frame-single-btn"
            onClick={() => this.onCancelClick()}
          >
            {back_btn_text}
          </OrangeButton>
        </div>
        <FixedFooter history={this.props.history} />
      </div>
    )
  }
}

const mapStateToProps = ({ lockers, activeOrders, translate }) => {
  const { openedLocker, orderWasCollected } = lockers
  const { back_btn_text, order_details, order_item, order_item_count } =
    translate
  let orderItems = activeOrders.userActiveOrders || []

  return {
    openedLocker,
    orderWasCollected,
    orderItems,
    back_btn_text,
    order_details,
    order_item,
    order_item_count,
  }
}

export default connect(mapStateToProps, { logScreenEnterance })(ReviewOrderList)
