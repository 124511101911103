import React, { Component } from "react"
import { connect } from "react-redux"

import {
  fetchUserActiveOrders,
  fetchAllLockersStatuses,
  logScreenEnterance,
  fetchAllLockersLayout,
  getAllDynamicProducts,
  resetLockersErrorsAttemptsOpened,
  LogoutUser,
  openAndListenToSpecificLockerLock,
  onCloseOrder,
  setThankYouSubTitle,
  fetchStationServiceTypes,
  setRentalOrder,
} from "../../../../actions"

import { FixedHeader } from "../../../reduxified"
import { MenuOrangeButton } from "../../../common"

import "./style.css"

class SimpleServiceMenuScreen extends Component {
  async componentDidMount() {
    this.props.logScreenEnterance("SIMPLE_SERVICE_MENU_SCREEN")
    const { authenticated, stationId } = this.props
    await this.props.fetchStationServiceTypes(stationId)

    if (!authenticated) {
      this.props.LogoutUser()
      this.props.history.push("")
    }
    const {
      isLaundryActive,
      isLockerRentActive,
      isLockerToLockerDeliveryActive,
    } = this.props
    if (
      !isLaundryActive &&
      !isLockerRentActive &&
      !isLockerToLockerDeliveryActive
    ) {
      this.props.LogoutUser()
      this.props.history.push("")
    }
    if (
      isLaundryActive &&
      !isLockerRentActive &&
      !isLockerToLockerDeliveryActive
    ) {
      this.props.history.push("UserMenuScreen", "laundry")
    }
    if (
      !isLaundryActive &&
      isLockerRentActive &&
      !isLockerToLockerDeliveryActive
    ) {
      this.props.history.push("AboutRental", "rent")
    }
    if (
      !isLaundryActive &&
      !isLockerRentActive &&
      isLockerToLockerDeliveryActive
    ) {
      this.props.history.push(
        "LockerToLockerDeliveryScreen/info",
        "lockerDelivery"
      )
    }
  }

  render() {
    const {
      firstName,
      hello_title_text,
      user_menu_what_sub_title_text,
      dirRtl,
      locker_delivery,
      login_menu_landry,
      rent_a_locker,
      isLaundryActive,
      isLockerRentActive,
      isLockerToLockerDeliveryActive,
    } = this.props

    return (
      <div className="user-menu">
        <FixedHeader buttonsFlag="userMenuScreen" />
        <div
          className="ums-title"
          dir={dirRtl ? "rtl" : "ltr"}
        >{`${hello_title_text}, ${firstName}`}</div>
        <div className="ums-subtitle" dir={dirRtl ? "rtl" : "ltr"}>
          {user_menu_what_sub_title_text}
        </div>

        <div className="ums-wrapper">
          <MenuOrangeButton
            hide={!isLaundryActive}
            serviceType="laundry"
            onClick={() => this.props.history.push("UserMenuScreen", "laundry")}
          >
            {login_menu_landry}
          </MenuOrangeButton>
          <MenuOrangeButton
            hide={!isLockerRentActive}
            serviceType="locker-rent"
            onClick={() =>
              this.props.history.push("RentLockerMenuScreen", "rent")
            }
          >
            {rent_a_locker}
          </MenuOrangeButton>
          <MenuOrangeButton
            hide={!isLockerToLockerDeliveryActive}
            serviceType="locker-delivery"
            onClick={() =>
              this.props.history.push(
                "LockerToLockerDeliveryScreen/info",
                "lockerDelivery"
              )
            }
          >
            {locker_delivery}
          </MenuOrangeButton>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({
  userLogin,
  bgService,
  translate,
  stationServiceTypes,
}) => {
  const { stationId } = bgService
  const { userId, role, authenticated, firstName } = userLogin

  const {
    dirRtl,
    hello_title_text,
    locker_delivery,
    login_menu_landry,
    rent_a_locker,
  } = translate

  const {
    isDeliveryActive,
    isLaundryActive,
    isDeliverySimulationStation,
    isStationHaveBarcodeReader,
    isLockerRentActive,
    isLockerToLockerDeliveryActive,
  } = stationServiceTypes

  return {
    userId,
    dirRtl,
    firstName,
    stationId,
    rent_a_locker,
    login_menu_landry,
    role,
    locker_delivery,
    hello_title_text,
    authenticated,
    isDeliveryActive,
    isLaundryActive,
    isDeliverySimulationStation,
    isStationHaveBarcodeReader,
    isLockerRentActive,
    isLockerToLockerDeliveryActive,
  }
}

export default connect(mapStateToProps, {
  fetchUserActiveOrders,
  fetchAllLockersStatuses,
  logScreenEnterance,
  fetchAllLockersLayout,
  getAllDynamicProducts,
  resetLockersErrorsAttemptsOpened,
  openAndListenToSpecificLockerLock,
  LogoutUser,
  onCloseOrder,
  setThankYouSubTitle,
  setRentalOrder,
  fetchStationServiceTypes,
})(SimpleServiceMenuScreen)
