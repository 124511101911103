import React, { Component } from "react"

import AppQRCodeIcon from "../../../assets/svg/app_qr_code.svg"
import AppAppleStore from "../../../assets/svg/app_apple_store.svg"
import AppGooglePlay from "../../../assets/svg/app_google_play.svg"
import ManCellPhone from "../../../assets/svg/man_cell_phone.svg"
import "./style.css"

class AppQRCode extends Component {
  

  render() {
    const { show } = this.props
    
    if (show == undefined || !show) {
      return <noscript></noscript>
    }

    return (
      <>
       
        <div className="app-qr-code-wrapper"  dir="rtl" >
           <div className="white-part">
            <div className="card-text">
              <img
                className="qr-code-icon"
                src={AppQRCodeIcon}
                alt="card_img"
              />
              <img
                className="google-play-icon"
                src={AppGooglePlay}
                alt="google play"
              />
              <img
                className="apple-store-icon"
                src={AppAppleStore}
                alt="apple store"
              />
            </div>
          </div>
          <div className="orange-part">
            <img
              className="sit-with-cell-phone-man"
              src={ManCellPhone}
              alt="sit-with-cell-phone-man"
            />
          </div>
        </div>
      </>
     
    )
  }
}

export default AppQRCode
